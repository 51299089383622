.tabs {
  display: flex;
  gap: 10px;
}

.button {
  display: flex;
  align-items: center;
  gap: 5px;
}
