@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/base/colors.scss";

@import "shared/assets/styles/mixins/mainMixins.scss";

.table {
  width: 40%;
}

.tableHeader {
  border-bottom: 4px double $accent-light-red;
}

.sum {
  text-align: right;
  @include subtitle;
  line-height: 22px;
}

.title {
  @include caption;
  @include zero;
  color: $bw-gray6;
  background-color: $bw-white !important;
  border-bottom: 1px solid $bw-gray3;
  white-space: nowrap;
  padding: 12px 8px;
}

.tableBody {
  @include mainText;
  & > tr {
    vertical-align: middle;
    & > td {
      padding: 13px 8px;
    }
    & > td p {
      @include zero;
    }
  }
}

.yearCell {
  border: none;
}

.titleRight,
.moneyCell {
  text-align: right;
}

.rowWithIcon {
  display: flex;
  align-items: center;
  column-gap: 4px;
  & > img {
    width: 24px;
    height: 24px;
  }
}
