@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.container {
  display: flex;
  cursor: pointer;
  align-items: baseline;
}

.iconCollapse {
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.iconCollapseClosed {
  transform: rotate(180deg);
}

.iconCollapseOpened {
  transform: rotate(360deg);
}

.title {
  @include subtitle2;
  margin-left: 8px;
  padding-top: 5px;
}

.historyBlock {
  padding: 16px 5px;
  border-bottom: 1px solid $bw-gray3;
}

.date {
  display: flex;
  gap: 20px;
  @include caption;
  color: $bw-gray6;
}

.author {
  color: $bw-black;
}

.change {
  @include inputPrompt;
  color: $bw-black;
}
