.scrollToTop {
  cursor: pointer;
  opacity: 0;
  position: fixed;
  z-index: 6;
  right: 30px;
  bottom: 20px;
  width: 60px;
  height: 60px;
  border: none;
  background: none;
  transition: all 0.3s ease-in-out;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.25));
}
