@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";

.select {
  display: flex;
  align-items: center;
  gap: 12px;
  user-select: none;
}

.noMaster {
  @include zero;
  @include mainText;
}

.noBrigadier {
  @extend .noMaster;
  color: $bw-gray4;
}

.openListBtn {
  outline: none;
  transition: all 0.2s ease;
  transform: rotate(0deg);

  &:hover {
    cursor: pointer;
    & path {
      fill: $bw-gray6;
    }
  }

  & > path {
    fill: $bw-gray5;
    stroke: $bw-gray5;
  }
}

.openListBtnTransformed {
  transition: all 0.4s ease;
  transform: rotate(180deg);

  & > path {
    fill: $blue-lazure !important;
    stroke: $blue-lazure !important;
  }
}

.scrollboard {
  position: absolute;
  top: 70px;
  z-index: 1;

  & > :first-child {
    border-color: $blue-lazure;
    & > :last-child {
      & > :first-child {
        height: max-content;
        max-height: 180px !important;
      }
    }
  }
}
