@import "src/shared/assets/styles/main.scss";

.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.heading {
  @include subtitle;
  margin-bottom: 20px;
}

.columnContainer {
  width: 480px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.photo {
  width: 230px;
  height: 230px;
  border-radius: 12px;
  object-fit: cover;
  display: flex;
  background-color: $bw-gray1;

  &_withHover {
    cursor: pointer;

    &:hover {
      box-shadow: 0px 2px 8px $bw-gray4, 0px 4px 16px $bw-gray6;
    }
  }
}

.iconPhotoDefault {
  width: 80px;
  height: 80px;
  margin: auto;
}

.moreBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $bw-gray6;
  width: 80px;
  margin-top: 15px;
  cursor: pointer;
  &:hover {
    color: $blue-lazure;
    text-shadow: none;
    & svg {
      & > path {
        stroke: $blue-lazure;
        fill: $blue-lazure;
      }
    }
  }

  & > p {
    @include zero;
    font-size: 12px;
  }
  & svg {
    width: 24px;
    & > path {
      stroke: $bw-gray6;
      fill: $bw-gray6;
    }
  }
}

.window {
  width: 38.55vw;

  // для formWindow_body
  & > :first-child > :nth-child(2) {
    position: relative;
  }

  &_withText {
    top: 100px;
  }

  &__title {
    @include mainText;
    margin-bottom: 10px;
  }

  &__photo {
    & img {
      width: 100%;
      height: 100%;
    }
    padding-bottom: 20px;
  }

  &__prevBtn {
    opacity: 0;
    cursor: pointer;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 5;
    background-color: #6e6e6e00;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -10px;

    & svg {
      margin-top: -5px;
    }

    &:hover {
      opacity: 0.7;

      & > :first-child {
        border-radius: 50%;
        padding: 15px;
        &:hover {
          transition: all 0.5s ease;
          background-color: $blue-white-blue;

          & svg path {
            stroke: $blue-lazure;
          }
        }
      }

      & svg path {
        stroke: $blue-lazure;
        fill: none;
      }
    }
    &:disabled {
      display: none;
    }
  }

  &__nextBtn {
    @extend .window__prevBtn;
    margin-left: 0px;
    right: 5px;
  }
}

@media screen and (max-height: 800px) {
  .window {
    top: 100px;
  }
}
