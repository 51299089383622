@import "~/src/shared/assets/styles/main.scss";

.table {
  @include mainText;
  line-height: 22px;
  margin-top: 30px;
}

.tableHead {
  @include caption;
  white-space: nowrap;
  padding-left: 20px !important;
}

.arrowBtn {
  cursor: pointer;
}

.tableBody {
  border-top: none !important;
  cursor: pointer;
  & td {
    padding: 20px;
  }
}
.greyRow {
  background-color: $bw-gray1;
}

.redRow {
  background-color: $accent-pastel-red;
}

.orangeRow {
  background-color: $accent-beige;
}

.link {
  color: $blue-lazure !important;
  text-decoration: none;
}

.statusRow {
  display: flex;
  align-items: center;
  gap: 10px;
}

.statusCell {
  min-width: 165px;
}
