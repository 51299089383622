@import "/src/shared/assets/styles/base/colors.scss";

.blueRow {
  background-color: $blue-white-blue;
  position: sticky;
  top: 37px;
  z-index: 1;
  height: 73px;
}

.datePickerForAllEdit {
  position: relative;
}

.datePickerCustom {
  height: 32px;
  padding: 6px 15px 7px 5px;
  width: 130px;
}

.iconMassEdit {
  position: absolute;
  top: 4px;
  right: 1px;
}

.hasValue {
  & svg path {
    fill: $blue-lazure;
  }
}
