@import "~/src/shared/assets/styles/main.scss";

.relative {
  position: relative;
}

.list {
  position: absolute;
  top: calc(100% + 2px);
  right: 0;
  width: 70%;
  @include list;
  padding: 10px;
  background: $bw-white;
  border: 1px solid $blue-azure;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);
  z-index: 2;
  transition: all 0.4s ease-in-out;

  & li {
    width: 100%;
    transition: all 0.4s ease;

    & p {
      @include zero;
      padding: 5px 10px;
      min-height: 24.5px;
      border-radius: 18px;
      @include listMainText;
      @include clippedText;

      &:hover {
        background: $blue-white-blue;
        cursor: pointer;
        transition: all 0.4s ease;
      }
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}

.currentSource {
  background: $blue-white-blue;
}

.loading {
  @include zero;
  @include listMainText;
  color: $bw-black;
}

.input {
  @include mainText;
  @include clippedText;
  width: 100%;
  height: 72px;
  border: 1px solid $bw-gray3;
  border-radius: 6px;
  padding: 34px 45px 19px 16px;
  caret-color: $blue-lazure;
  &::placeholder {
    @include inputPrompt;
  }
  &:focus {
    box-shadow: none;
    border: 1px solid $blue-lazure;
    & + .label {
      padding-top: 20.5px;
      @include mainText;
      color: $bw-gray6;
      opacity: 1;
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      top: 0;
      &::before {
        left: 18px;
        top: 25px;
      }
    }
  }

  &:hover {
    background-color: color-level("blue-white-blue", 2);
    border-color: color-level("blue-lazure", 2);
  }
}

.label {
  @include mainText;
  color: $bw-gray6;
  top: -4px;

  border: 1px solid #0000;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 1rem 0.75rem;
  pointer-events: none;
  position: absolute;
  text-align: start;
  text-overflow: ellipsis;
  top: 0;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  white-space: nowrap;
  width: 100%;
}

.labelNoData {
  @include mainText($bw-gray6);
  padding-top: 26px;
  padding-left: 16px;
}

.labelWithData {
  padding-top: 21px;
  padding-left: 16px;
  @include mainText;
  color: $bw-gray6;
  opacity: 1;
  top: 0px;
  transition: none;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.inputDisabled {
  background: $bw-gray2;
  border-color: $bw-gray2;
  cursor: not-allowed;
}
