@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";

.container {
  min-width: 400px;
  width: max-content;
  max-width: 70vw;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tooltipRight {
  cursor: pointer;
  position: relative;
  @include tooltip("right", $blue-lazure, $bw-white, $blue-lazure, 300px);
}

.addBtn {
  &:hover,
  &:active {
    background-color: transparent;
    transition: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    font-weight: 400;
  }
}

.addBtnIconDisabled {
  & rect {
    fill: $bw-gray4;
  }
}

.deleteBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: -25px;
}

.deleteBtnText {
  color: $accent-orange;
}

.deleteBtnIcon {
  & path {
    fill: $accent-orange;
  }
}
