@import "src/shared/assets/styles/base/colors.scss";

.select {
  & > :first-child {
    & > :first-child > :first-child {
      height: 32px;
      min-width: 75px;
      padding: 7px 0;
      background-color: $bw-white;
      border-radius: 6px;
      & > :first-child {
        height: 32px;
        top: -7px;
        padding: 7px 4px;
      }
    }
    & > div:nth-child(2) {
      top: 32px;
      left: 0 !important;
      min-width: 310px;
      max-width: 400px;
    }
  }

  &_required {
    & p {
      padding-left: 16px !important;
    }
    & p::before {
      left: 6px !important;
      top: 12px !important;
    }
  }
}
