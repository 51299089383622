@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "../../staffTablePlaceholders.scss";

.link {
  text-decoration: none !important;
}

.rowspan {
  border-right: 2px solid $bw-gray3;
  border-left: 2px solid $bw-gray3;
}

.workshiftState {
  position: relative;
  padding-right: 20px;
}

.iconQuestion {
  @include tooltip("top-start");

  position: absolute;
  top: -9px;
  right: -6px;
  cursor: pointer;

  & svg {
    & > circle {
      stroke: $bw-gray5;
    }
    & > rect,
    path {
      fill: $bw-gray5;
    }
  }
  &:hover {
    & svg {
      & > rect,
      path {
        fill: $blue-lazure;
      }
      & > circle {
        stroke: $blue-lazure;
      }
    }
  }
}

.highlightedPresave {
  @extend %highlightedPresave;
}
