@import "/src/shared/assets/styles/mixins/mainMixins.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

.itemBlock {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 16px;
  background: $bw-gray2;
  transition: all 0.4s ease;
  padding: 8px 0 8px 16px;
  column-gap: 12px;
  cursor: pointer;

  & img {
    display: block;
  }

  &:hover {
    background: $blue-white-blue;

    .itemZero {
      @include zero;
    }

    & svg,
    & svg:hover {
      padding: 0;
      & path {
        fill: $blue-lazure;
      }
    }
  }
}

.item {
  position: sticky;
  top: 0;
  cursor: pointer;
  @include zero;
  @include listMainText;

  &:hover {
    transition: all 0.4s ease;
    background: $blue-white-blue;
    border-radius: 16px;
  }
}

.minHeight {
  padding: 10px 30px 11px 16px;
  min-height: 33px;
}

.itemZero {
  @include zero;
}

.grayBorder {
  border-bottom: 1px solid $bw-gray2;
  padding: 4px 0;
  margin-bottom: 4px;
}

.blueBorder {
  border-bottom: 1px solid $blue-azure;
  padding-bottom: 4px;
  margin-bottom: 4px;
}

.pressed {
  background-color: $blue-light-blue;
  border-radius: 16px;
  position: relative;

  &:hover {
    transition: all 0.4s ease;
    background-color: $blue-light-blue;
    border-radius: 16px;
  }

  &:focus,
  &:active {
    background-color: $blue-white-blue;
    .closeIconWrapper {
      background-color: $bw-gray3;
      transition: all 0.5s ease;
    }
  }
}

.closeIconWrapper {
  position: absolute;
  right: 10px;
  top: 8px;
  padding: 7px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.closeIcon {
  width: 10px;
  height: 8px;
  & path {
    fill: $bw-gray7;
  }
}
