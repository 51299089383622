@import "src/shared/assets/styles/main.scss";

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;

  &__value {
    border-radius: 2px;

    @each $name, $color in $colors-list {
      &_#{$name} {
        padding: 2px;
        background-color: $color;
        @if ($name == "accent-coral") {
          color: $bw-white;
        }
      }
    }
  }

  &__icons {
    display: flex;
  }
}

.day {
  padding: 0 !important;
  border: 1px solid $bw-gray3 !important;
  min-width: 44px !important;
  position: relative;
  min-height: 40px;
  height: max-content;
  text-align: center;

  & > :first-child {
    height: 100%;
    padding: 5px 2px;
  }
  @-moz-document url-prefix() {
    border: 2px solid $bw-gray3 !important;
  }

  &_withHover:hover,
  &_active {
    cursor: pointer;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
    background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.05) 0%,
        rgba(0, 0, 0, 0.05) 100%
      ),
      $bw-white;

    &::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      border: 1px solid $bw-gray7;
    }
  }

  @each $name, $color in $colors_list {
    &_#{$name} {
      background-color: tabel-color($name) !important;

      @if ($name == "blue-grey") {
        .cell__value {
          color: $bw-white;
        }
      }

      &_active {
        @extend .day_active;
      }

      &:hover,
      &_active {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.05) 0%,
            rgba(0, 0, 0, 0.05) 100%
          ),
          tabel-color($name);
      }
    }
  }

  &_withTooltip {
    @include tooltip("top-end");

    &:hover::before {
      max-width: 220px;
    }
  }
}
