@import "shared/assets/styles/main.scss";

.field {
  width: 50%;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.errors {
  margin-top: 40px;
  margin-bottom: -20px;
  width: 42%;

  & div {
    @include listMainText;
    font-weight: 500;
  }
}

.buttons {
  margin: 40px 0 10px 0;
}
