@import "src/shared/assets/styles/main.scss";

.title {
  display: table-cell;
  vertical-align: middle;
  @include caption;
  cursor: pointer;
}

.tableBody {
  border-top: none !important;
}

tbody.tableBody tr {
  cursor: pointer;
}

tbody.tableBody tr:hover {
  background: $blue-light-blue;
  border-top: 1px solid $blue-light-blue;
  border-bottom: 1px solid $blue-light-blue;
}

tbody.tableBody td {
  display: table-cell;
  vertical-align: middle;
}

tbody.tableBody p {
  @include mainText;
  margin: 15px 0;
}

.lastvalue {
  width: 250px;
}

.btnGroup {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: flex-end;
  width: 100%;
}
