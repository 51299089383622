@import "src/shared/assets/styles/main.scss";

.datePickerCustom {
  height: 32px;
  width: 230px;
  background-color: $bw-white;
  border-radius: 6px;
  margin-right: 20px;
}

.datePickerCol {
  display: flex;
  flex-direction: column;
  & > span {
    @include caption;
    margin: 0 0 4px 2px;
  }
}

.hide {
  display: none;
}
