@import "shared/assets/styles/main.scss";

.iconSend {
  position: absolute;
  right: 12px;
  cursor: pointer;

  & svg path {
    fill: $blue-lazure;
  }

  &:focus,
  &:active,
  &:hover {
    & svg path {
      fill: $blue-dark-blue;
    }
  }

  &_column {
    transform: rotate(270deg);
  }
}
