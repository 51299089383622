@import "shared/assets/styles/main.scss";

%stickyHeaderCell {
  position: sticky;
  top: 30px;
  z-index: 1;
  background-color: $bw-white;
}

%cell {
  @include listMainText;
  min-width: 220px;
  max-width: 320px;
  display: flex;
  align-items: center;
  border: 1px solid $bw-gray3;
  border-top: none;
  height: 50px;
  word-wrap: break-word;
  padding: 0 8px;

  & input {
    caret-color: $blue-lazure;
  }
}

%columnTitle {
  @extend %stickyHeaderCell;
  @extend %cell;
  font-weight: 500;
  height: auto;
  padding: 23.5px 8px;
  border-top: 1px solid $bw-gray3;
  border-left: none;
}

%massfillPadding {
  & div > input {
    padding-right: 45px !important;
  }
}

%focusedIconSend {
  div[data-type="iconSend"] {
    & svg path {
      fill: $blue-lazure;
    }

    &:hover {
      & svg path {
        fill: $blue-dark-blue;
      }
    }
  }
}

.forms {
  position: relative;
  display: flex;
}

.titles {
  user-select: none;
  @include listMainText;
  & > :first-child {
    @extend %stickyHeaderCell;
    z-index: 3;
  }
  &:hover {
    background-color: $blue-white-blue !important;
    .titles__title {
      background-color: $blue-white-blue !important;
    }
  }

  &_shadow {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  }

  &__oneTitle {
    @extend %cell;
    position: relative;
    @include caption;
    &_required {
      padding-left: 24px !important;
      &::before {
        content: "";
        position: absolute;
        left: 12px;
        top: 21px;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: $accent-light-orange;
      }
    }

    &_custom {
      &::before {
        background-color: $blue-lazure;
      }
    }

    &_size {
      height: 250px;
      &::before {
        top: 48%;
      }

      &_big {
        height: 300px !important;
        &::before {
          top: 49%;
        }
      }
    }

    &_kc {
      height: 100px;
      &::before {
        top: 48%;
      }
    }
  }
}

.original {
  &:hover {
    background-color: $blue-white-blue !important;
    .original__title,
    .original__value,
    .original__value_double {
      background-color: $blue-white-blue !important;
    }
  }

  &__title {
    @extend %columnTitle;
    background-color: $accent-yellow;
    padding-top: 23px;
    padding-bottom: 23px;
  }

  &__value {
    @extend %cell;
    border-left: none;
    background-color: $accent-yellow;

    &_manualHours {
      @include listMainText;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
      align-items: center;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid $bw-gray3;
      background-color: $accent-yellow;

      &__title {
        background-color: $accent-light-green;
        color: $bw-gray5;
        width: 135px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 8px;
        border-right: 1px solid $bw-gray3;
      }

      &__text {
        height: 49px;
        border-right: 1px solid $bw-gray3;
        display: flex;
        align-items: center;
      }
    }

    &_kc {
      height: 100px;
    }
  }
}

.link {
  display: flex;
  gap: 5px;
  & div {
    color: $bw-gray5;
  }

  & a {
    text-decoration: none;
    color: $blue-lazure;
    word-wrap: break-word;
  }
}

.size {
  height: 250px;
  padding: 0;

  &_big {
    height: 300px;

    & > div > :first-child > :first-child {
      border-top: 1px solid $bw-gray3;
    }

    & > div > div > :first-child {
      width: 135px;
    }
  }

  &_bigInputs {
    height: 300px;

    & > div > :first-child > :first-child {
      border-top: 1px solid $bw-gray3;
      margin: 0;
    }

    & > div > div > :first-child {
      width: 170px;
    }
  }
}

.iconDash path {
  fill: $accent-orange;
}

.massfill {
  cursor: pointer;
  width: 310px;
  &:hover {
    background-color: $blue-white-blue !important;
    .massfill__title {
      background-color: $blue-white-blue !important;
    }
  }

  &__title {
    @extend %columnTitle;
    padding-top: 23px;
    padding-bottom: 23px;
  }

  &_highlighted {
    background-color: $blue-white-blue !important;
    .massFill__title {
      background-color: $blue-white-blue !important;
    }
  }
}

.field {
  @extend %cell;
  border-left: none;
  background-color: rgba(255, 255, 255, 0);
  padding: 0 !important;

  // контейнер поля
  & > :first-child {
    width: 100%;

    // стили ошибки поля
    & > :first-child > :nth-child(3) {
      position: absolute;
      bottom: 2px;
    }
  }

  & input {
    @extend %cell;
    @include clippedText;
    height: 49px !important;
    background-color: rgba(255, 255, 255, 0);
    border: none;
    border-radius: 0px;
    padding: 15.5px 8px !important;
    &:disabled {
      color: $bw-gray5;
    }
    &:hover {
      box-shadow: inset 0 0 0 1px $blue-azure;
    }
  }

  &_manualHours {
    @extend .original__value_manualHours;
    @extend .field;

    gap: 0;

    &__title {
      @extend .original__value_manualHours__title;
      width: 165px;
      gap: 12px;
    }

    &__input {
      & input {
        min-width: 145px;
      }
    }

    &_massfill {
      @extend .field_manualHours;
      display: grid;
      grid-template-columns: 160px 1fr;
      gap: 0;

      &__title {
        @extend .field_manualHours__title;
        gap: 8px;
      }

      &__input {
        @extend .field_manualHours__input;
        @extend %massfillPadding;
        position: relative;
        margin-top: -1px;

        & input {
          &:disabled {
            border: none !important;

            &:hover {
              border: none !important;
            }

            .size_column__prop {
              border: none !important;
            }
          }
        }

        // стиль для иконки iconSend
        & > :last-child {
          top: 13px;
        }
      }
    }
  }

  &_kc {
    @extend .field;
    height: 100px;
    &__fieldContainer {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      // стили для поля даты
      & > :first-child {
        & input {
          height: 50px !important;
          border-bottom: 1px solid $bw-gray3;
        }
      }

      &__switch {
        cursor: pointer;
        height: 48px;
        margin-left: 8px;
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    &_massfill {
      &__fieldContainer {
        width: 80% !important;
        padding-left: 8px;

        &__switch {
          @extend .field_kc__fieldContainer__switch;
          border-top: 1px solid $bw-gray3;
        }
      }

      & input {
        min-width: 100px;
      }
    }
  }

  &_select {
    @extend %cell;
    padding: 0 !important;
    border-left: 0;

    // контейнер поля-селекта
    & > :first-child {
      width: 100% !important;
    }

    // селект-поле
    & > :first-child > :first-child > :first-child {
      @include listMainText;

      padding-left: 8px;
      border: none;
      border-radius: 0px;
      height: 49px !important;

      &:hover {
        box-shadow: inset 0 0 0 1px $blue-azure;
      }

      & > div > svg path {
        fill: $blue-lazure;
        stroke: $blue-lazure;
      }
    }

    // текст option в селект-поле
    & p {
      padding-left: 0px;
      &::before {
        display: none;
      }
    }

    // селект-поле, отступ для отображения справа кнопки iconSend
    &_massfill {
      @extend .field_select;
      & div[data-select="true"] {
        padding-right: 45px !important;
      }
    }
  }

  &_disabled {
    width: 100%;
    background-color: $bw-gray2;
    height: 50px;
    border-right: 1px solid $bw-gray3;
    border-bottom: 1px solid $bw-gray3;
    cursor: not-allowed;

    &:hover {
      background-color: $bw-gray2 !important;
    }
    &_size {
      height: 250px;
      &_big {
        height: 300px;
      }
    }
  }

  &_massfill {
    @extend .field;
    @extend %massfillPadding;
  }
}

.column {
  &_gray {
    background-color: $bw-gray1;

    .column__title,
    .column__value {
      background-color: $bw-gray1;
    }
  }

  &__title {
    @extend %stickyHeaderCell;
    @extend %cell;
    font-weight: 500;
    height: auto;
    padding: 20px 8px;
    border-top: 1px solid $bw-gray3;
    border-left: none;
    background-color: $bw-white;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__iconClose {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.shadow {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
}

.stickyCols {
  display: flex;
  position: sticky !important;
  left: -0.5px;
  z-index: 2;
  background-color: $bw-white;

  &_shadow {
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
  }
}

.highlighted {
  background-color: $blue-white-blue !important;

  & input {
    background-color: $blue-white-blue !important;

    &:disabled {
      background-color: $bw-gray2 !important;

      &:hover {
        background-color: $bw-gray2 !important;
      }
    }
  }

  & input[data-type="search"] {
    background-color: $bw-white !important;
  }
}

.focused {
  @extend %focusedIconSend;

  & input {
    border: 1px solid $blue-lazure !important;
  }

  &_kc {
    @extend %focusedIconSend;
    border: 1px solid $blue-lazure !important;
  }

  &_select {
    @extend %focusedIconSend;
    div[data-select="true"] {
      border: 1px solid $blue-lazure !important;
    }
  }
}

.massDateCell {
  position: relative;
  & > div:nth-child(2) {
    top: 14px;
  }
}

.datePickerCustom,
.datePickerCustomMass {
  height: 49px;
  border-radius: 0px;
  margin: 0;
  padding-right: 16px;

  // стили для ошибки поля
  & > :nth-child(3) {
    inset: calc(100% + -15px) 0 0 8px !important;
  }
}

.datePickerCustomInput {
  @include listMainText;
  background-color: rgba(255, 255, 255, 0);
  margin-bottom: 4px;
}

.datePickerCustomMass {
  padding-right: 40px;
}
