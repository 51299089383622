@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";

.table {
  cursor: auto;
  border-top: 1px solid $bw-gray3;
  border-collapse: collapse;
}

.tableBody {
  border-top: none !important;
  @include mainText;
}

.greyRow > td {
  background-color: $bw-gray1;
}

.whiteRow > td {
  background-color: $bw-white;
}

.body {
  & td {
    outline: 1px solid $bw-gray3 !important;
  }
}

.firstStickyCol,
.secondStickyCol,
.thirdStickyCol,
.fourthStickyCol {
  position: sticky;
}

.thirdStickyCol,
.fourthStickyCol {
  min-width: 260px;
  width: 260px;
}

.firstStickyCol {
  min-width: 60px;
  width: 60px;
  left: 0;
}

.secondStickyCol {
  min-width: 290px;
  width: 290px;
  left: 60px;
}

.thirdStickyCol {
  left: 350px;
}

.fourthStickyCol {
  left: 610px;
  &::after {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
    color: $bw-gray5;
  }
}

.linkToBuilding,
.linkToStaff {
  text-decoration: none;
  color: $blue-lazure;
  &:hover {
    color: $blue-lazure;
  }
}

.marginTop {
  margin-top: 8px;
}

.dateText {
  @include caption;
  color: $bw-gray6;
}

.statusIcon {
  margin: 0 4px 0 8px;
}

.stickyCellShadow::after {
  box-shadow: 13px 0 13px -15px inset;
  right: -15px;
}

.certStatus {
  display: flex;
  white-space: nowrap;
  align-items: baseline;
  margin: 6px 0;
}

.certCell {
  padding: 0 !important;
  height: 128px;
}

.cert {
  padding: 12px 8px;
  height: 100%;

  @each $name, $color in $colors-list {
    &_#{$name} {
      background-color: color-level($name, 3);
    }
  }
}

.alert {
  padding-top: 20px;
}
