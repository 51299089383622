@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";

.form {
  padding: 12px 20px;
  border-bottom: 1px solid $bw-gray3;
  margin: -30px;
}

.fields {
  min-width: 500px;
  width: fit-content;
  gap: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.iconSearch {
  & path,
  & circle {
    stroke: $bw-gray4;
    transition: stroke 0.2s ease;
  }
}

.iconSearchActive {
  & path,
  & circle {
    stroke: $blue-lazure;
    transition: stroke 0.2s ease;
  }
}

.showBtnText {
  color: $bw-gray4;
}

.showBtnTextActive {
  color: $blue-lazure;
}

.text {
  @include inputPrompt;
  font-weight: 400px;
  font-size: 30px;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.alert {
  margin-top: 60px;
}
