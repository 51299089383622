@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";

.loading {
  width: 100%;
  height: calc(100vh - 228px);
  & > div[data-loading="true"] {
    position: relative;
    width: 100%;
    height: 100%;
    left: 0;
  }
}

.table {
  @include mainText;

  .__body {
    border-top: none !important;
  }

  &__row {
    & p {
      @include zero;
    }

    &_secondary {
      background-color: $bw-gray1;
    }
    &_grey {
      background-color: $bw-gray4;
    }
    &_black {
      background-color: $bw-gray5;
      color: $bw-white;
    }
  }
}

.header {
  position: sticky;
  top: -2px;
  z-index: 2;

  &_shadow {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    transform: box-shadow 0.4s ease-in-out;
  }

  &__title {
    @include caption;
    background-color: $bw-white !important;
    white-space: nowrap;

    & div {
      height: 20px;
    }
  }
}

.link {
  display: flex;
  align-items: center;
  color: $blue-lazure;
  margin-right: 5px;
  cursor: pointer;
  text-decoration: none;
}

.firstCol {
  padding-left: 20px !important;
  padding-bottom: 5px !important;

  &__loading {
    @include listMainText;
  }
}
