@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 40px;
  padding: 13px 16px;
  border-radius: 12px;
  margin-bottom: 20px;
  position: sticky !important;
  top: 0;
  z-index: 2;

  @include subtitle;
  color: $bw-black;
  margin: 0;

  @each $name, $color in $colors-list {
    &_#{$name} {
      background-color: $color;
    }
  }
}

.sticky {
  position: sticky;
  transition-duration: 0.2s;
  border-radius: 0px;
  margin-left: -30px !important;
  width: calc(100% + 60px) !important;
}

.normal {
  transition-duration: 0.1s;
  border-radius: 12px;
}

.state {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 4px;
  @include zero;
  @include listMainText;

  @each $name, $color in $colors-list {
    &_#{$name} {
      color: $color;
    }
  }
}
