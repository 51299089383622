@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

button,
input,
div {
  &:focus,
  &:active,
  &:active:focus,
  &:focus:active,
  &:visited {
    outline: 0;
  }
}

@mixin zero {
  margin: 0;
  padding: 0;
  border: 0;
}

// Обнуление стилей для списков
@mixin list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

// Обрезать текст
@mixin clippedText {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

// Стили для списка каждого раздела меню
@mixin menu-list {
  @include list;

  & > li:not(:last-child) {
    margin-bottom: 5px;
  }

  & > li > a {
    text-decoration: none;
  }
}

// Стили заголовка раздела меню
@mixin menu-title {
  margin: 0;
  padding: 0 0 10px 10px;

  font-size: 10px;
  font-weight: 100;
  color: #5a5858;
}

// МИКСИН для стилизации таблиц раздела Модули (возможно расширить для стилизации всех таблиц)

// АРГУМЕНТЫ: class, padding для th, padding для input, padding для td (optional), text-align (optional)
@mixin table-style(
  $className,
  $th-paddings,
  $input-paddings,
  // Optional, подставится значение по умолчанию, если не будет явно указан аргумент при вызове миксина
  $td-paddings: (5px 0),
  $text-align: center
) {
  .#{$className} {
    border-left: none;
    border-right: none;

    &__header {
      border-top: 1px solid $bw-gray3;

      & th {
        padding: #{$th-paddings};

        @include subtitle;
        color: $bw-gray4;

        &:last-child {
          text-align: #{$text-align};
        }
      }
    }

    &__body {
      @include mainText;

      border-top: 1px solid $bw-gray3 !important;

      & tr {
        border-top: 1px solid $bw-gray3;
        border-bottom: 1px solid $bw-gray3;
      }

      & tr:hover {
        // background: $blue-white-blue;
        border-top: 1px solid $blue-light-blue;
        border-bottom: 1px solid $blue-light-blue;
      }

      & td {
        padding: #{$td-paddings};

        vertical-align: middle;
      }

      & input {
        padding: #{$input-paddings};
        margin: 0 auto;

        @include mainText;
        text-align: #{$text-align};

        cursor: pointer;
      }

      & textarea {
        @extend input;
      }
    }
  }
}

// Стили подменю на страницах, применяется к ol и li. В качестве переменной передаем название класса выбранного пункта меню и background-color
@mixin innerMenu($className, $color) {
  @include list;
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  & li {
    @include listMainText;
    color: $bw-gray5;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    padding: 6px 14px;
    border-top-left-radius: 15.5px;
    border-bottom-left-radius: 14.5px;
    cursor: pointer;

    & p {
      @include zero;
      @include mainText;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: 7px;
    }

    & button {
      padding: -2px 14px -3px;
    }
  }

  // Стили при наведении и при выборе пункта меню
  & li:hover,
  .#{$className,
  $color } {
    position: relative;
    background: $color;
    z-index: 1;

    &::after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      right: -10px;
      top: 0;
      border-top: 15.5px solid transparent;
      border-left: 10px solid $color;
      border-bottom: 15.5px solid transparent;
      z-index: 2;
    }
  }
}

// стрелка пунктиром для размера 30px через ::after
@mixin dashedArrow($color) {
  &::after {
    content: "";
    position: absolute;
    background-color: transparent;
    border-bottom: 1px dashed $color;
    border-right: 1px dashed $color;
    height: 20px;
    width: 20px;
    transform: matrix(0.7071, -0.7071, 0.7071, 0.7071, -2, 0);
    z-index: 2;
    right: -14px;
    top: 4px;
  }
}

// обычная стрелка для размера 30px через ::after
@mixin arrow($color) {
  &::after {
    content: "";
    position: absolute;
    background-color: $color;
    border-bottom: 1px solid $color;
    border-right: 1px solid $color;
    height: 21px;
    width: 21px;
    transform: matrix(0.7071, -0.7071, 0.7071, 0.7071, -2, 0);
    z-index: 2;
    right: -13px;
    top: 4.4px;
  }
}

// Миксин для затемнения заднего фона при открытии модального окна
@mixin backgroundModal {
  position: fixed;
  overflow: auto;
  z-index: 1050;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
}

// Миксин для switch-полей
@mixin switch {
  display: flex;
  align-items: center;
  @include caption;

  & > div {
    margin-right: 10px;
    margin-left: 5px;
  }

  color: $bw-black;
}

// Для обязательных полей - точка перед названием поля, по умолчанию оранжевого цвета
@mixin before-dot($left: 0, $top: 0, $position: relative, $color: $accent-light-orange) {
  position: $position;

  &::before {
    content: "";
    position: absolute;
    left: $left;
    top: $top;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: $color;
  }
}

@mixin tooltip(
  $placement: "top-start",
  $color: $bw-gray7,
  $background-color: $bw-white,
  $border-color: $bw-gray5,
  $width: max-content
) {
  &:hover::before {
    content: attr(data-tooltip);
    cursor: pointer;

    position: absolute;
    z-index: 9999;

    display: flex;
    align-items: center;

    width: max-content;
    max-width: $width;
    height: max-content;
    word-wrap: break-word;

    @include caption;
    padding: 16px;
    border-radius: 12px;

    color: $color;
    background-color: $background-color;
    border: 1px solid $border-color;

    @if ($placement == "top-start") {
      bottom: calc(100% + 10px);
      left: 0;
    } @else if ($placement == "top-end") {
      bottom: calc(100% + 10px);
      right: 0;
    } @else if ($placement == "bottom-end") {
      top: calc(100% + 10px);
      right: 0;
    } @else if ($placement == "bottom-start") {
      top: calc(100% + 10px);
      left: 0;
    } @else if ($placement == "bottom") {
      top: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
    } @else if ($placement == "top") {
      bottom: calc(100% + 10px);
      left: 50%;
      transform: translateX(-50%);
    } @else if ($placement == "right") {
      left: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
    } @else if ($placement == "left") {
      right: calc(100% + 10px);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

@mixin squareChip($color: $bw-gray7) {
  @include subtitle2;
  color: $bw-white;
  white-space: nowrap;
  width: max-content;
  padding: 4px 6px;
  border-radius: 8px;
  background-color: $color;
}

@mixin companyChip($name: "primary") {
  @include squareChip();
  background-color: company-color($name);
}

// Подсвечивание элементов при наведении (checkbox и ratiobutton)
@mixin beforeElemStyle {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  left: -12px;
  bottom: -12px;
  border-radius: 50%;
  transform: translateZ(-10px);
}

//  Стиль для активной кнопки фильтров, табов и пр.

@mixin selectedButton {
  color: $bw-white;
  background-color: $bw-gray7;

  &:hover,
  &:focus {
    background-color: $bw-gray7;
    color: $bw-white;
  }
}
