@import "shared/assets/styles/main.scss";

.cards {
  margin-top: 20px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.status {
  width: max-content;

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }

  &__building {
    @include zero;
    @include subtitle;
    text-decoration: none;
    cursor: pointer;
    color: $blue-lazure;
    margin-top: 4px;
    width: max-content;

    &:hover {
      filter: brightness(70%);
      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
  }
}
