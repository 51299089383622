@import "~/src/shared/assets/styles/main.scss";

.container {
  position: relative;
  min-width: 100vw;
  width: fit-content;
}

.topRowWrapper {
  display: flex;
  justify-content: space-between;
}

.buildingDateContainer {
  position: sticky;
  left: 0;
  display: flex;
  gap: 40px;
  max-width: 100%;
  align-items: center;
  padding: 0 0 12px 20px;
  border-bottom: 1px solid $bw-gray2;
  cursor: pointer;
  z-index: 9;
}

.filter {
  position: sticky;
  right: 30px;
}

.masters {
  z-index: 8;
  width: 100vw;
  position: sticky;
  left: 0;
}

.notification {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    font-weight: 400px;
    font-size: 30px;
    line-height: 35px;
    color: $bw-gray5;
  }
}

.filterCaption {
  @include listMainText;
  color: $bw-gray6;
}

.datePickerCustom {
  border: none;
  justify-content: flex-start;
  gap: 10px;
  &:hover {
    background-color: transparent;
    border-color: transparent;
  }
}
