@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";

.thead {
  position: sticky;
  z-index: 1;
  top: -2px;
  border-top: 1px solid $bw-gray2;
  border-bottom: 1px solid $bw-gray2;
  background-color: $bw-white;

  & > tr > :first-child {
    padding-left: 20px;
  }
}

.title {
  @include caption;
  background-color: white !important;
  white-space: nowrap;
  cursor: pointer;
}

.sum {
  @include listMainText;
  color: $bw-gray5;
  position: absolute;
  top: -25px;
}
