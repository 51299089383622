@import "src/shared/assets/styles/main.scss";

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 198px;
}

.errorCode {
  @include mainTextFont;
  font-weight: 700;
  font-size: 190px;
  line-height: 76px;
  color: white;
  -webkit-text-stroke: 0.02em $blue-lazure;
  margin-bottom: 70px;
}

.errorHeader {
  @include mainTextFont;
  font-weight: 700;
  font-size: 64px;
  line-height: 76px;
  color: $bw-black;
  margin-bottom: 20px;
}

.errorText {
  @include mainTextFont;
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  color: $bw-black;
}

.btnGroup {
  margin-top: 150px;
  display: flex;
  gap: 20px;
}

.arrowImg {
  transform: rotate(270deg) scale(2);
}
