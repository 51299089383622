@import "src/shared/assets/styles/main.scss";

.input {
  width: max-content;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  position: relative;

  padding: 5px !important;
  height: 35px !important;
  max-width: max-content;
  min-width: 75px;

  background: $bw-white !important;
  border: 1px solid $bw-gray3 !important;
  border-radius: 4px;
  cursor: pointer !important;

  &:hover,
  &_active {
    border: 1px solid $blue-lazure !important;
  }

  &__icon {
    cursor: pointer;
    fill: $bw-gray5;
    &:hover {
      fill: $bw-black;
    }

    &_active {
      fill: $blue-lazure;
      &:hover {
        & > path {
          fill: $blue-lazure;
        }
      }
    }
  }
}

.container {
  width: 38.55vw;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;

  // InputNumber
  & > :nth-child(1) {
    width: 100%;

    & > :first-child {
      width: 100%;
    }
    & input {
      border: 1px solid $bw-gray3 !important;
    }
    & > :nth-child(2) {
      display: none;
    }
  }
}

.history__title {
  @include subtitle;
  margin: 10px 0 0 2px;
}

.storyData {
  &__item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    color: $bw-gray5;

    &_withMargin {
      margin-bottom: 11px;
    }
  }

  &__date {
    @include caption;

    &_withMargin {
      margin-right: 5px;
    }
  }

  &__name {
    @include caption;
    color: $bw-black;
  }

  &__inputBlock {
    display: flex;
    width: 100%;
    column-gap: 8px;
  }

  &__input {
    width: 100%;

    & > :first-child {
      & input {
        border: 1px solid $bw-gray3 !important;
      }
      & > :first-child {
        width: 100%;
      }
      & > :nth-child(2) {
        display: none;
      }
    }
  }
}
