@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";

.container {
  width: 750px;
}

.fullSize {
  width: 100%;
  height: 100%;
}

.grayBackgroundContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  background-color: $bw-gray1;
}

.imageNameBox {
  width: 100%;
  border-bottom: 1px solid $bw-gray2;
}

.imageName {
  color: $bw-gray5;
}
