@import "src/shared/assets/styles/main.scss";

.list {
  position: absolute;
  z-index: 5;
  right: 0;
  top: 105%;
  padding: 10px 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);
  border-radius: 6px;
  background: $bw-white;
  border: 1px solid $blue-azure !important;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    @include zero;
    @include listMainText;
    text-align: center;
    padding: 6px 10px;
    width: max-content;

    border-radius: 16px;

    &:hover {
      background: $blue-white-blue;
    }
  }

  &__divider {
    margin: 6px 0 4px 0 !important;
    padding: 0px !important;
    width: 100% !important;
    height: 1px;
    background-color: $bw-gray4;

    &:hover {
      background-color: $bw-gray4;
      cursor: default;
    }
  }
}
