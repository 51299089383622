@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

.container {
  margin: 20px 0;
}

.title {
  @include zero;
  @include caption;
  color: $bw-gray6;
  margin-bottom: 6px;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
}

.button {
  border-radius: 6px;
  padding: 11px 18px;
  margin-bottom: 5px;
}

.tooltip {
  position: relative;
  cursor: not-allowed !important;
  @include tooltip("right", $bw-gray7, $bw-white, $bw-gray5, 250px);
}
