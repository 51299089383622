@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/base/sizes.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";

.awardDetails {
  @include subtitle;
  padding: 12px 0;
  border-bottom: 1px solid $bw-gray3;
  margin-bottom: 20px;
}

.title {
  @include caption;
  margin-bottom: 8px;
}

.awardDetailsWithoutBorder {
  @extend .awardDetails;
  border-bottom: none;
}

.group {
  padding: 9px 0;
}
