@import "src/shared/assets/styles/main.scss";

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;
  padding: 20px;
}

.errorText {
  @include mainText;
  font-size: 22px;
  line-height: 25px;
}

.img {
  width: 24px;
  height: 24px;
}
