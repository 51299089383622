@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.manageBox {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  border-bottom: 1px solid $bw-gray2;
}

.rightCol,
.leftCol {
  display: flex;
  flex-direction: column;
}

.rightCol {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
}

.switch {
  @include mainText;
  line-height: 16.52px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 2px;

  & > div {
    margin-right: 10px;
    margin-left: 5px;
  }

  color: $bw-black;
}

.photoInfo {
  margin-bottom: 10px;
}

.photoName {
  color: $bw-gray5;
}

.manageBoxBtns {
  background-color: transparent;
  border: none;
  color: $bw-gray5;
  display: flex;
  gap: 8px;

  &:hover {
    & svg path {
      fill: $blue-lazure;

      & mask path {
        fill: inherit;
      }
    }

    color: $blue-lazure;
    cursor: pointer;
  }
}
