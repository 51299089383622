@import "src/shared/assets/styles/base/colors.scss";

@mixin afterElemStyle {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  top: -19px;
  border-radius: 50%;
  transform: translateZ(-10px);
}

.switch {
  position: relative;
  display: inline-block;
  width: 23px;
  height: 5px;
  cursor: pointer;

  & .input {
    display: none;
  }
}

.tumbler {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 3px;

  &:before {
    content: "";
    position: absolute;
    height: 14px;
    width: 14px;
    left: -4px;
    bottom: -4px;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    border-radius: 50%;
  }
}

.input {
  &[value="1"] + .tumbler,
  &[value="null"]:active + .tumbler {
    background: $blue-light-blue;
    &:before {
      background: $blue-lazure;
      -webkit-transform: translateX(19px);
      -ms-transform: translateX(19px);
      transform: translateX(19px);
    }
  }

  &[value="1"]:active + .tumbler,
  &[value="null"]:active + .tumbler {
    &:after {
      @include afterElemStyle;
      right: -19px;
      background: rgba(206, 233, 255, 0.35);
    }
    &:before {
      background: $blue-lazure;
    }
  }

  &[value="0"] + .tumbler,
  &[value="null"]:active + .tumbler {
    background: $bw-gray5;

    &:before {
      background: $bw-gray2;
    }
  }

  &[value="0"]:active + .tumbler,
  &[value="null"]:active + .tumbler {
    &:after {
      @include afterElemStyle;
      left: -19px;
      background: rgba(197, 198, 198, 0.15);
    }
  }

  &:not(:disabled):hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
    & + .tumbler {
      background: $bw-gray3;

      &:before {
        background: $bw-gray4;
      }
    }

    &:active::after {
      content: none;
    }
  }
}
