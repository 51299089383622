@import "src/shared/assets/styles/main.scss";

.table {
  @include subtitle2;
  line-height: 19px;
  font-weight: 400;
  margin-top: 21px;
  & td {
    border: 1px solid $bw-gray3;
    vertical-align: middle;
    text-align: right;
    padding-right: 8px;
  }
}

.firstCol {
  @include caption;
  color: $bw-gray6;
  max-width: 130px;
  line-height: 17px;
}

.monthsCol {
  text-align: center !important;
  background-color: $accent-beige !important;
  padding: 4px !important;
}

.lastCol {
  background-color: $accent-lime !important;
  font-weight: 500;
}

.tableBody {
  & td {
    padding: 14px 8px !important;
  }
}

.moneyCell {
  color: $blue-dark-blue;
}

.boldFontMoney {
  font-weight: 500;
}
