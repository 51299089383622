@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.container {
  width: 770px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.viewForm {
  background-color: transparent;
  cursor: pointer;
  &:hover {
    background-color: $blue-white-blue;
  }
}

.oneField {
  column-gap: none;
  .datePickerStartDate {
    width: 100% !important;
    &::after {
      content: none !important;
    }
  }
}

.dateContainer {
  display: flex;
  gap: 40px;
  z-index: 11;
  .datePickerStartDate,
  .datePickerEndDate {
    width: 35vw;
  }

  .datePickerStartDate {
    &::after {
      content: "";
      position: absolute;
      right: -40px;
      top: 50%;
      width: 40px;
      height: 1px;
      background: $bw-gray2;
      z-index: 1;
      transition: all 0.4s ease-in-out;
    }
  }
}

.fileContainer,
.editFileInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $bw-gray2;
  border-radius: 6px;
  height: 72px;
  width: 100%;
  cursor: default;
  padding: 20px 16px 19px;
  white-space: pre-line;
}

.attachment,
.attachmentEdit {
  display: flex;
  stroke: $bw-gray6;
  margin-right: 9px;
}

.attachmentEdit:hover {
  stroke: $blue-lazure;
  cursor: pointer;
  transition: all 0.4s ease;
}

.displayFileName {
  & a {
    text-decoration: none;
    @include listMainText;
    color: $bw-gray7;
    &:hover {
      color: $blue-lazure;
    }
  }
  & > div {
    color: $bw-gray5;
  }
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.icons:hover {
  & svg path {
    fill: $blue-lazure;
  }
  cursor: pointer;
}

.backIcon {
  fill: none !important;
  stroke: $blue-lazure;
}

.tooltip {
  position: relative;
  @include tooltip("top", $blue-lazure, $bw-white, $blue-lazure);
}

.tooltipLeft {
  position: relative;
  @include tooltip("left", $blue-lazure, $bw-white, $blue-lazure);
}

.tooltipRed {
  cursor: pointer;
  position: relative;
  @include tooltip("left", $accent-coral, $bw-white, $accent-coral);
}

.clearBtn {
  & rect {
    fill: $bw-gray5;
  }

  &:hover {
    & rect {
      fill: $bw-gray7;
    }
  }
}

.displayFlex {
  display: flex;
  align-items: center;
}

.iconBasket {
  &:hover {
    cursor: pointer;
    & path {
      fill: $accent-coral;
    }
  }
}
