@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";

.container {
  width: 38.55vw;
}

.selectedColsBlock {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-bottom: 20px;
  border-bottom: 1px solid $bw-gray3;
}

.caption {
  @include caption;
  color: $bw-gray6;
}

.cols {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.optionList {
  margin-top: 20px;

  & > div {
    border: none;
    padding: 0;
    border-radius: 0;
    box-shadow: none;

    & > div:first-child > div:first-child {
      max-width: 692px;

      & input {
        width: 692px;
      }
    }
  }
}
