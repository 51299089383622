@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 38.55vw;
  gap: 20px;
}

.title {
  @include subtitle;
  margin: 10px 0 0 2px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  color: $bw-gray5;
}

.itemWithMargin {
  @extend .item;
  margin-bottom: 11px;
}

.date {
  @include caption;
}

.dateTitle {
  @include caption;
  margin-right: 5px;
}

.name {
  @include caption;
  color: $bw-black;
}

.inputsContainer {
  display: flex;
  width: 100%;
  column-gap: 8px;
}

.input {
  width: 100%;
}

.inputMini {
  width: 93%;
}

.basketBtn {
  padding: 24px 3px;
  height: 100%;
  &:hover .basketBtnImg {
    fill: $blue-lazure;
  }
}

.basketBtnImg {
  fill: $bw-gray5;
}

.background {
  @include backgroundModal;
}

.modalWindow {
  width: 750px;
}
