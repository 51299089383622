@import "/src/shared/assets/styles/base/colors.scss";

.clearBtnHovered {
  &:hover {
    background-color: $bw-white;
  }
}

.clearBtnImg {
  & rect {
    fill: $bw-gray5;
  }

  &:hover {
    & rect {
      fill: $accent-coral;
    }
  }
}

.clearBtnDisabled {
  & rect {
    fill: $bw-white;
  }
  &:hover {
    & rect {
      fill: $bw-white;
    }
  }
}
