@import "src/shared/assets/styles/main.scss";

.filterInfoBlock {
  margin-left: -30px;
  width: calc(100% + 61px);
  display: flex;
  column-gap: 20px;
  height: 38px;
  background-color: $blue-white-blue;
  border-top: 1px solid $bw-gray3;
  margin-top: 8px;
  padding: 8px 0 8px 20px;
  transform: translate3d(0, 0, 0);
  will-change: margin;

  & p {
    margin: 0;
  }
}

.filterInfoElement {
  @include caption;
  &:first-child > span {
    @include subtitle;
    margin-left: 5px;
  }
  &:nth-child(n + 2) > span {
    @include mainText;
    margin-left: 5px;
  }
}

.resetFilter {
  @include mainText;
  color: $accent-red;
  cursor: pointer;
}
