@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";
@import "../cellsMixins.scss";

%textInput {
  border: none;
  height: 28px !important;
  padding: 0 2px !important;
  caret-color: $blue-lazure;
  background-color: transparent;

  &:focus,
  &:active {
    padding: 15px 2px !important;
    background-color: transparent;
  }
}

.tableInfo {
  & > td {
    padding: 12px 8px;
    outline: 1px solid $bw-gray3;
    min-width: 40px;

    &:not([data-no-hover]):hover {
      background-color: $blue-white-blue !important;
    }

    &:last-child {
      border-right: none;
    }

    &:first-child {
      @extend %firstChild;
      text-align: center;
    }

    &:nth-child(2) {
      @extend %secondChild;
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      @extend %tableChild;
      z-index: 6;
    }

    &:nth-child(3) {
      left: left(3);
      min-width: $thirdCell;
    }

    &:nth-child(4) {
      left: left(4);
      min-width: $fourthCell;
    }

    &:nth-child(5) {
      left: left(5);
      min-width: $fifthCell;
    }

    &:nth-child(6) {
      left: left(6);
      min-width: $sixthCell;
    }

    & > p,
    & > div > p {
      @include zero;
    }

    & input:focus,
    textarea:focus {
      border: none !important;
      outline: none !important;
    }

    &:has(input:focus, textarea:focus) {
      outline: 1px solid $blue-lazure;
      outline-offset: -2px;
    }
  }
}

.patronymic {
  & input {
    width: 170px !important;
  }

  &::after {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
    color: $bw-gray5;
  }

  &__shadow::after {
    box-shadow: 13px 0 13px -15px inset;
    right: -15px;
  }
}

.modifiedСell {
  background-color: $accent-peach !important;
}

.errorCell {
  background-color: $accent-pastel-red !important;
}

.greyRow {
  background-color: $bw-gray1;

  & > td {
    &:first-child,
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      background-color: $bw-gray1;
    }
  }
}

.greyCell {
  background-color: $bw-gray1 !important;
}

.whiteCell {
  background-color: $bw-white !important;
}

.selectCell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectMultiPopper {
  z-index: 4;
}

.datePickerCustom {
  min-width: 130px;
  margin-top: -6px;
  margin-bottom: 0;
  height: 32px;
  column-gap: 6px;
  padding: 0 5px 0 5px;
  border: none;
}

.datePickerInput {
  width: 80px;
  background-color: transparent;
}

.datePickerPopper {
  z-index: 4;
}

.textareaBlock {
  & > div:first-child > div:nth-child(2) {
    display: none !important;
  }

  & textarea {
    width: 150px;
    @extend %textInput;

    &::placeholder {
      white-space: pre-wrap;
    }
  }
}

.inputBlock {
  & input {
    width: 120px;
    @extend %textInput;
  }

  & > div:first-child > div > div {
    display: none !important;
  }
}

.phoneBlockInput {
  background-color: transparent;
  border: none;
  width: 235px;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  height: 28px;
}

.phoneBlockCode {
  top: 5px;
}

.phoneBlockTop {
  top: 2px;
}

.phoneBlockError {
  display: none;
}

.phoneBlockSelect {
  top: 29px;
}
