@import "src/shared/assets/styles/main.scss";

.tab {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 12px 12px;
  gap: 8px;
  background: $accent-light-green;
  border-radius: 8px;
}

.tabHeader {
  @include caption;
}

.tabFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabData {
  @include subtitle;
  display: flex;
  align-items: flex-end;
}
