@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";

.blockBottom {
  margin-bottom: -28px;
}

.widgetHeader {
  z-index: 3;
  top: 285px;
}

.widgetHeaderMiniTop {
  @extend .widgetHeader;
  top: 120px;
}

.title {
  @include title;
}

.withoutDashedLine {
  &:before {
    display: none;
  }
}

.fieldData {
  & svg {
    position: absolute;
    width: 110%;
    height: 120%;
    top: 0;
    left: 32%;
  }
}

@media print {
  .blockForPrint {
    margin-bottom: 10px;
    display: block;
  }

  .workerPrint {
    break-inside: avoid;
  }
}
