@import "src/shared/assets/styles/main.scss";

.commentDateAndName {
  @include caption;
  display: flex;
  margin: 5px 0 10px 0;

  .date {
    margin-right: 10px;
  }

  .name {
    color: $bw-black;
  }
}

.commentText {
  @include mainText;
  word-break: break-word;
  margin-bottom: 12px;
}

.commentNoText {
  height: 20px;
}

.file {
  margin-bottom: 12px;
}

.uploadedFile {
  @include listMainText;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  color: $bw-gray7;
  text-decoration: none;

  .iconClip {
    cursor: pointer;
    margin-right: 10px;
  }
}
