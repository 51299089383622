@import "shared/assets/styles/main.scss";

.field {
  margin-bottom: 20px;
  break-inside: avoid-column;

  &__values {
    display: flex;
    justify-content: space-between;
    & svg {
      fill: $bw-gray5;
      cursor: pointer;

      &:hover {
        fill: $accent-coral;
      }
    }

    &__link {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.marginBottom {
  margin-bottom: 20px;
}

.doublefield {
  display: flex;
  width: 100%;
  gap: 40px;
  justify-content: space-between;

  & > div:first-child,
  & > div:nth-child(2) {
    width: 100%;
    position: relative;
  }

  & > div:first-child {
    &::after {
      content: "";
      position: absolute;
      right: -40px;
      top: 50%;
      width: 40px;
      height: 1px;
      background: $bw-gray2;
      z-index: 0;
      transition: all 0.4s ease-in-out;
    }
  }
}

.autocomplete {
  position: relative;
  & input {
    @include clippedText;
    padding-right: 40px;
  }
  & span {
    position: absolute;
    right: 15px;
    top: 30%;
    &:hover svg > path {
      stroke: $bw-black;
    }
  }
}

.uneditable {
  @include mainText;
  padding: 20px 16px 14px;
  border: 1px solid $bw-gray2;
  border-radius: 6px;
  background-color: $bw-gray2;
  width: 100%;
  position: relative;

  & p {
    @include zero;
  }

  & > :first-child {
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem) !important;
    @include mainText;
    color: $bw-gray5;
    opacity: 1 !important;
    position: absolute;
    top: 1rem !important;
    left: 0.75rem;
  }

  & a {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $blue-lazure;
    padding-top: 0.75rem;
  }

  & svg {
    background-color: $bw-white;
    border-radius: 50%;
  }

  &_btnInInput {
    background-color: $bw-white;
    cursor: default;
    & a {
      display: flex;
      justify-content: space-between;
      cursor: default;
      padding-top: 1.1rem;
      & > p,
      & > svg {
        cursor: pointer;
      }
      & > svg {
        background-color: rgba(255, 255, 255, 0);
        border-radius: 0;
        fill: $bw-gray4;
        margin-top: -4%;
        width: 24px;
        height: 24px;
        & > path {
          fill: $bw-gray6;

          &:hover {
            fill: $bw-black;
          }
        }
      }
    }
  }

  &_withoutValue {
    height: 72px !important;
    padding: 34px 16px 19px !important;
    & > :first-child {
      transform: none !important;
      top: 1.7rem !important;
      @include inputPrompt;
    }
  }
}

.cancelled {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  align-items: center;

  & > div {
    background-color: $bw-white;
  }

  & > div > svg {
    margin-top: 0.75rem;
    & > path {
      fill: $accent-orange;
    }
  }
}

.cancelledBtn {
  & svg {
    width: 14px;
    height: 14px;
    margin-top: 4px;
  }
}

.deleteIcon {
  & > path {
    fill: $accent-coral;
  }
}

.typecomment {
  @include listMainText;
  width: 100%;
  min-height: 70px;
  padding: 16px;
  margin-top: 20px;
  background-color: $accent-beige;
  color: $bw-gray7;
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  gap: 10px;
}

.switch {
  display: flex;
  align-items: center;
  @include caption;
  & > div {
    margin-right: 10px;
    margin-left: 5px;
  }
  color: $bw-black;
}

.type {
  @each $name, $color in $colors-list {
    &_#{$name} {
      @include squareChip($color);
    }
  }
}

.link {
  display: flex;
  align-items: flex-end;
  gap: 5px;
}
