@import "src/shared/assets/styles/main.scss";

.headWrapper {
  position: fixed;
  top: 50px;
  left: $openedAsideMenu;
  right: 30px;
  background-color: $bw-white;
  z-index: 2;
  padding-right: 30px;
  padding-left: 30px;
  width: calc(100% - #{$openedAsideMenu});
  &__collapsed {
    left: $closedAsideMenu;
    width: calc(100% - #{$closedAsideMenu});
  }
}

.search {
  margin-bottom: 20px;
}

.tabsBlock {
  position: relative;
}

.linksBlock {
  display: flex;
  align-items: center;
  column-gap: 20px;
  position: absolute;
  right: 0;
  bottom: 10px;
}

.tabs {
  margin: 20px 0;
}
