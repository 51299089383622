@import "shared/assets/styles/base/colors.scss";

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.disabledField {
  & textarea,
  input {
    background-color: $bw-white !important;
    cursor: auto !important;
  }
}
