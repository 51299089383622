@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/base/sizes.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.menu {
  position: fixed;
  z-index: 8;
  top: 50px;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: $openedAsideMenu;
  overflow-x: hidden;
  padding: 33px 1px 0 10px;
  background-color: $bw-gray2;

  &__collapsed {
    width: $closedAsideMenu;
  }
}

.buttonBlock {
  display: flex;
  align-items: center;
  column-gap: 5px;
  margin-bottom: 20px;

  & p {
    margin: 0;
    @include caption;
  }
}

.button {
  max-width: fit-content;
  padding-left: 20px;
  cursor: pointer;
  height: 24px;
}

.menuSections {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-right: 10px;
}
