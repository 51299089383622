@import "/src/shared/assets/styles/base/colors.scss";

%displayFlexCenter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  border-bottom: 1px solid $bw-gray2;
  @extend %displayFlexCenter;
  flex-direction: column;
  position: relative;
}

.status {
  @extend %displayFlexCenter;
  padding-right: 4px;
  width: 100%;
}

.buttons {
  display: flex;
  column-gap: 12px;
}

.img {
  filter: brightness(0) saturate(100%) invert(34%) sepia(67%) saturate(2411%)
    hue-rotate(191deg) brightness(104%) contrast(105%);
}

.errors {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 0 12px 8px;
}
