@import "src/shared/assets/styles/main.scss";

.row {
  display: flex;
  border-bottom: 1px solid $bw-gray3;
  column-gap: 20px;

  & > div:first-child {
    padding-left: 4px;
  }

  &:hover {
    background-color: $bw-gray1;
  }
}

.photo_alt {
  & div {
    display: flex;
    gap: 12px;

    & img {
      width: 76px;
      height: 76px;
      border-radius: 20px;
      object-fit: cover;
    }
  }
}

.photoCount {
  @include caption;
  @include zero;
}

.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 0;

  &__border {
    border-bottom: 1px solid $bw-gray2;
    padding-left: 4px;
    &__ppe {
      width: 40%;
    }
    &:hover {
      background-color: $bw-gray1;
    }
  }
}

.fieldTitle {
  @include zero;
  @include caption;
  height: 14px;
  white-space: nowrap;
  color: $bw-gray6;
}

.fieldValue {
  @include zero;
  @include mainText;
  word-break: break-word;
  padding-bottom: 2.5px;

  &__link {
    color: $blue-lazure;
  }

  &__phone {
    white-space: nowrap;
  }
}

.birthdayField {
  @extend .fieldValue;
  display: flex;
}

.birthday {
  color: $bw-gray6;
  margin: 0 0 0 10px;
}

.emailTooltip {
  display: flex;
  align-items: center;
  gap: 4px;
  @include zero;
}

.photoBlock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 12px;
  padding: 12px 0 0 0;

  &__first {
    padding-top: 0;
  }
}

.textBlock {
  display: flex;
  column-gap: 20px;

  & img {
    width: 7vw;
    height: 7vw;
  }

  &_text {
    @include title;
    @include zero;
    padding-bottom: 10px;
  }

  &_top {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 15px;
    margin-top: 10px;
  }
}

.TNBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  height: fit-content;
  border: 1px solid $bw-gray2;
  border-radius: 4px;
  padding: 4px 0;
  width: 7vw;
}

.TNBlockTitle {
  @include title;
  font-weight: 400;
  @include zero;
  color: $bw-gray6;
}

.TNBlockValue {
  @include title;
  @include zero;
}

.btnBlock {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  width: 100%;
  @include listMainText;

  &__red,
  &__print {
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 6px;
  }

  &__red {
    & a {
      @include zero;
      text-decoration: none;

      &:hover {
        border-bottom: 1px solid $accent-coral;
        margin-top: 1px;
      }
    }
  }
}

.printBtn {
  color: $bw-gray6;
  @include zero;

  &:hover {
    border-bottom: 1px solid $bw-gray6;
    margin-top: 1px;
  }
}

.icon {
  fill: $bw-gray6;
}

.photo {
  width: 160px;
  height: 160px;
  border-radius: 20px;
  object-fit: cover;
}

@media print {
  .field,
  .photoBlock {
    break-inside: avoid;
  }
}

.phone {
  width: max-content;
}

.phoneText {
  @include mainText;
  width: max-content;
  margin: 0;
}

.dataComment {
  word-wrap: break-word;
  padding-left: 23px;
  color: $bw-gray6;
  font-size: 14px;
}

.statusListBtn {
  @include squareChip($bw-gray7);
  padding: 4px 12px;
  margin-top: 3px;

  &_gray {
    @extend .statusListBtn;
    background-color: $bw-gray6;
  }
}

.hasNoSnils {
  display: flex;
  align-items: center;
  gap: 3px;

  & svg path {
    fill: $accent-coral;
  }
}

.tooltipBlue {
  cursor: pointer;
  position: relative;
  @include tooltip("top-start", $blue-lazure, $bw-white, $blue-lazure);
}

.tooltipSuccess {
  cursor: pointer;
  position: relative;
  @include tooltip(
    "top-start",
    $accent-green,
    $accent-light-green,
    $accent-green
  );
}

.tooltipError {
  cursor: pointer;
  position: relative;
  @include tooltip("top-start", $accent-orange, $accent-yellow, $accent-orange);
}
