@import "src/shared/assets/styles/main.scss";

.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.sectionsWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $bw-gray3;
  padding: 12px 4px;
  &:first-child {
    border-top: 1px solid $bw-gray3;
  }
}

.leftSection {
  @include listMainText;
}

.rightSection {
  @include subtitle2;
  line-height: 19px;
  &_withBtn {
    display: flex;
    align-items: center;
    column-gap: 12px;
    & p {
      @include zero;
    }
  }
}

.icon {
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 7px;
  left: 7px;
}

// Раскомментировать в случае подключения печати страницы

// @media print {
//   .wrapper {
//     display: block;
//     break-inside: avoid;
//   }
// }
