@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";

.window {
  position: absolute;
  left: 850px;
  top: 30px;
  z-index: 4;
}

.wrapper {
  width: 600px;
  padding: 20px 16px 60px;
  margin-left: 40px;
  border: 1px solid $blue-lazure;
  background-color: $bw-white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);
  border-radius: 8px;
}

.title {
  @include subtitle;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.button {
  height: 36px;
  width: 36px;
}

.table {
  & > thead > tr {
    @include caption;
    & th {
      padding: 8px 0 8px 4px;
    }
  }

  & > tbody > tr {
    vertical-align: middle;
  }
}

.buttonsGroup {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
