@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";

.container {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.fieldContainer {
  max-width: none !important;
  position: relative !important;
}

.comments {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 16px;
}
