@import "shared/assets/styles/mixins/mainMixins.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/base/colors.scss";

%button {
  @include zero;
  @include listMainText;

  border: none;
  outline: none;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 16px;
  width: max-content;
  background: $blue-light-blue;
  padding: 8px 16px;
  gap: 12px;
}

%hoverAnimation {
  transition: all 0.2s ease;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.option {
  @extend %button;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    @extend %hoverAnimation;
    background-color: $accent-pastel-red;

    .icon {
      & path {
        fill: $accent-coral;
        transition: all 0.2s ease;
      }
    }
  }
}

.required {
  @extend %button;
  cursor: default;

  &:hover {
    @extend %hoverAnimation;
    background: $accent-yellow;
  }
}

.icon {
  display: block;
  & path {
    fill: $bw-gray7;
  }
}
