@import "src/shared/assets/styles/main.scss";

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.colWorkshift,
.colComments {
  display: flex;
  flex-direction: column;
  width: 48%;
}
