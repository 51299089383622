@import "shared/assets/styles/base/colors.scss";

.field {
  padding: 0 !important;
  &:hover {
    outline: 1px solid $blue-lazure !important;
  }
  & > :first-child > :first-child > :first-child > :first-child {
    border: none !important;
  }
}

.selected {
  outline: 1px solid $blue-lazure !important;
}
