@import "src/shared/assets/styles/main.scss";

.status {
  display: flex;
  align-items: center;
  gap: 4px;

  &__icon {
    width: 24px;
    height: 24px;
    margin-top: 2px;
  }

  &__title {
    @include mainText;
  }

  &__link {
    text-decoration: none;
    color: $blue-lazure;
    cursor: pointer;
  }
}
