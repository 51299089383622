@import "src/shared/assets/styles/main.scss";

%columnFlex {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

%label {
  @include listMainText;
  color: $bw-gray6;
}

.filtersContainer {
  position: sticky;
  top: 40px;
  width: 100%;
  display: flex;
  gap: 25px;
  background-color: $bw-white;
  margin-left: -30px !important;
  width: calc(100% + 60px) !important;
  padding: 15px 30px;
}

.itrFilters {
  @extend %columnFlex;

  &__label {
    @extend %label;
  }
}

.searchInput {
  @extend %columnFlex;
  width: 100%;

  &__label {
    @extend %label;
  }

  & > :nth-child(2) {
    max-width: 100%;
    width: 100%;
  }
}

.positionFilter {
  @extend %columnFlex;
  width: 100%;

  &__label {
    @extend %label;
  }

  & > :nth-child(2) > :first-child {
    height: 40px;
    padding: 9px 16px;
  }
}