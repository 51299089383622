@import "src/shared/assets/styles/main.scss";

%colTemplate {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.main {
  position: relative;
  height: 100%;

  &_withOpenedMenu {
    width: calc(#{$maxWidth} - #{$openedAsideMenu} - 60px);
  }

  &_withClosedMenu {
    width: calc(#{$maxWidth} - #{$closedAsideMenu} - 60px);
  }
}

.cols {
  @extend %colTemplate;
  width: 400px;
  min-width: 240px;
}

.columsParamsBlock {
  @extend %colTemplate;
  width: 50%;
}

.formRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
}

.input {
  position: relative;

  & input {
    text-align: start;
    cursor: pointer !important;
    background-color: white !important;

    &:hover {
      background-color: $blue-white-blue !important;
    }
  }

  & svg {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    fill: $bw-gray4;
  }

  &:hover {
    & svg {
      fill: black;
    }
  }

  &_active {
    & svg {
      fill: black;
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }
}

.unit {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
}

.title {
  @include subtitle;
  @include zero;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.tooltip {
  position: relative;
  @include tooltip("top", $blue-lazure, $bw-white, $blue-lazure, 300px);
}

.dateRangeContainer {
  display: flex;
  align-items: center;
  position: relative;

  & > div {
    width: 400px;
  }
}

.switch {
  position: absolute;
  @include switch;
  right: -160px;
}

.iconQuestion {
  margin-top: 2px;
  cursor: pointer;

  & > rect,
  path {
    fill: $blue-lazure;
  }

  & > circle {
    stroke: $blue-lazure;
  }
}

.miniInput {
  position: relative;
  width: 50%;
}

.container {
  min-width: 400px;
  width: max-content;
  max-width: 70vw;
}

.btnsGroup {
  margin-top: 30px;

  & button {
    width: 240px;
    padding: 10.5px 30px;
    height: 40px;
  }

  &_lastBlueBtn {
    & button:last-child {
      color: $blue-lazure;

      &:disabled {
        color: $bw-gray4;
      }
    }
  }
}
