@import "src/shared/assets/styles/mixins/mainMixins.scss";

.typeWithIcon {
  display: flex;
  align-items: flex-start;
  & > :last-child {
    margin: 0 10px;
    margin-top: -3px;
    width: 20px;
    height: 16px;
  }
}

.tooltip {
  & > div {
    & > :last-child {
      left: 98%;
      top: -5px;
    }
  }
  &_forIcon {
    width: max-content;
    & > div {
      & > :last-child {
        left: 75%;
        top: 0px;
      }
    }
  }
  &_forTypeWithIcon {
    & > div {
      & > :last-child {
        left: 135%;
        top: 0px;
      }
    }
  }
}

.tooltipDanger {
  position: relative;
  @include tooltip("top", $accent-red, $accent-pastel-red, $accent-red);
}

.tooltipWarning {
  position: relative;
  @include tooltip("top", $accent-orange, $accent-yellow, $accent-orange);
}
