@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.contribution {
  width: 40px;
  border-radius: 4px;
  border: 1px solid $bw-gray3;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

.inputError {
  border: 1px solid $accent-coral;
}

.error {
  @include caption;
  color: $accent-coral;
}
