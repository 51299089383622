@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.addPlaceBorder {
  display: flex;
  align-items: center;
  text-align: center;
  width: 750px;
  height: 650px;
  border-radius: 20px;
  border: 1px dashed $bw-gray3;
}

.dropzone {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iconAddFotoDefault {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
    & path:nth-of-type(2),
    & path:nth-of-type(3) {
      fill: $bw-gray4;
    }
    & path:first-of-type {
      fill: $bw-gray3;
    }
    & path:last-of-type {
      stroke: $bw-gray4;
    }
}

.addPlaceText {
  @include mainText;
  line-height: 28px;
  color: $bw-black;
  font-size: 22px;
  width: 600px;
  text-align: center;
  margin: 0 0 30px;
}

.flexColumn {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  color: $bw-gray6;
  font-size: 14px;
}

.errorContainer {
  max-width: 750px;
  padding: 5px 5px 0;
}

.errorMessage {
  color: $accent-red;
  margin: 0;
}
