@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";

.loading {
  width: 100%;
  height: calc(100vh - 228px);
  & div[data-loading="true"] {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.tableContainer {
  position: relative;
  display: flex;
  width: fit-content;
  height: fit-content;
  z-index: 7;
}

.notification {
  @include listMainText;
  color: $bw-gray4;
  width: fit-content;
  position: sticky;
  left: 0;
  padding-left: 10px;
}

.table {
  position: relative;
  @include listMainText;
  border-collapse: separate;
  border-spacing: 0;

  & > thead > tr > th,
  & > tbody > tr > td,
  & > tfoot > tr > td {
    @include listMainText;
    vertical-align: middle;
    border: 1px solid $bw-gray2;
    padding: 4px 8px;
  }

  & > thead {
    position: sticky;
    top: 0;
    z-index: 3;

    & > tr > th:nth-child(2) {
      position: sticky;
      top: 0;
      left: 37px;
      background-color: $bw-white;
      border: 1px solid $bw-gray2;
      z-index: 3;
    }
  }

  & > tbody {
    &:hover td[rowspan],
    tr:hover td {
      background-color: $accent-light-green;
      cursor: pointer;
    }
  }

  & > tfoot {
    position: sticky;
    bottom: 0;
    background-color: $blue-white-blue;
    text-align: center;
    z-index: 3;

    & > tr > td:nth-child(2) {
      position: sticky;
      bottom: 0;
      left: 37px;
      background-color: $bw-white;
      z-index: 2;
    }
  }
}

.rowSelected td[rowspan] {
  background-color: $accent-peach;
}

.draggingRow {
  & > td {
    border-bottom: 1px solid $blue-lazure !important;
  }
}

.footerShadow {
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.08);
}

.openedStaffList {
  position: absolute;
  left: 0;
  bottom: 60px;
  width: 100%;
  height: fit-content;
  padding: 0;
  background-color: $bw-white;
  border: 1px solid $blue-lazure;
}

.row {
  position: sticky;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 40vw;
  height: fit-content;
}

.addRowBtns {
  display: flex;
  justify-content: space-between;
  width: 70px;
  margin-right: 10px;
  button {
    background-color: transparent;
    padding: 0;
  }
}

.selectMulti {
  width: 100%;
  & > div {
    width: 100%;
    height: fit-content;
    border: none;
    border-right: 1px solid $blue-lazure;
    border-radius: 0;
    & > div {
      min-height: 40px;
      padding: 8px;
      border: none;
      text-align: left;
      &:hover {
        background-color: transparent;
      }
    }
  }
}

.number {
  position: sticky;
  left: 0;
  z-index: 2;
  vertical-align: middle;
  cursor: grab !important;
  background-color: $accent-yellow;

  &__empty {
    background-color: $bw-white;
    z-index: 3;
    cursor: pointer !important;
  }
  img {
    display: none;
  }
  &:hover {
    img {
      display: block;
    }
  }
}

.addLineBtnCell {
  height: 60px;
  cursor: pointer;
}

.addLineBtnCell::after {
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  width: 15px;
  color: $bw-gray5;
}

.addLineBtnCell {
  height: 40px;
  &__shadow::after {
    box-shadow: 13px 0 13px -15px inset;
    right: -15px;
  }
}

.addIcon {
  border: none;
  background-color: transparent;
}

.hiddenColumn {
  display: none;
}

.total {
  position: sticky;
  right: 0;
  min-width: 60px;
  text-align: center;
  background-color: $accent-yellow;
  z-index: 2;

  &__white {
    background-color: $bw-white;
  }

  &__shadow::before {
    box-shadow: -13px 0 13px -15px inset;
    left: -15px;
  }
}

.massHours {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 330px;
  height: 322px;
  margin-left: 30px;

  &__header {
    @include subtitle;
    font-size: 16px;
    line-height: 21px;
    padding-bottom: 20px;
    border-bottom: 1px solid $bw-gray3;
  }

  &__yellowBackground {
    display: flex;
    align-items: center;
    padding: 12px;
    gap: 20px;
    width: 329px;
    height: 43px;
    background-color: $accent-peach;
    border-radius: 12px;
  }

  &__switchTitle {
    @include caption;
    color: $bw-black;
  }

  &__workHoursCount {
    @include subtitle;
    color: $blue-dark-blue;
  }
}

.switch {
  @include switch;
}
