@import "~/src/shared/assets/styles/main.scss";

.formContainer {
  display: flex;
  gap: 4%;
  margin-bottom: 20px;
  & > div {
    width: 48%;
  }
}

.field {
  margin-bottom: 20px;
}

.subFields {
  border-top: 1px solid $blue-azure;
  padding-top: 20px;
  &__heading {
    @include subtitle;
  }
  &__fields {
    width: 48%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.sizes {
  @include mainText;
  margin-bottom: 20px;
  position: relative;
  margin-left: 10px;
  padding-left: 5px;
  &::before {
    content: "";
    position: absolute;
    left: -7px;
    top: 6px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: $accent-light-orange;
  }
}

.switch {
  @include switch;
  margin-bottom: 20px;
  padding-top: 2px;
}

.mountField {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > :first-child {
    width: 65%;
  }
}

.buttonSecondary {
  width: 32%;
}

.iconArrow {
  transform: rotate(180deg);
  margin: -20px;
  width: 60px;
  height: 60px;
}
