@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "../cellsMixins.scss";

.tableTitle {
  & > td {
    padding: 8px;
    outline: 1px solid $bw-gray3;
    &:last-child {
      border-right: none;
    }

    &:first-child {
      @extend %firstChild;
      text-align: center;
    }

    &:nth-child(2) {
      @extend %secondChild;
    }

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      @extend %tableChild;
      z-index: 5;
    }

    &:nth-child(3) {
      left: left(3);
      min-width: $thirdCell;
    }

    &:nth-child(4) {
      left: left(4);
      min-width: $fourthCell;
    }

    &:nth-child(5) {
      left: left(5);
      min-width: $fifthCell;
    }

    &:nth-child(6) {
      left: left(6);
      min-width: $sixthCell;
    }

    &:nth-child(7),
    &:nth-child(9) {
      border: none;
      outline: none;
    }

    & > p,
    & > div > p {
      @include zero;
      @include caption;
      color: $bw-gray6;
    }
  }
}

.patronymic {
  &::after {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
    color: $bw-gray5;
  }
  &__shadow::after {
    box-shadow: 13px 0 13px -15px inset;
    right: -15px;
  }
}

.requiredField {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    margin-top: 3px;
    margin-left: 10px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: $accent-light-orange;
  }

  &_invalid {
    &::before {
      content: "";
      background: $accent-coral;
    }
  }
}

.selectCell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 12px;
  min-width: 70px;
}

.cellWithInput {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.selectMultiPopper {
  z-index: 4;
}

.datePickerCellWrapper {
  background-color: $bw-white;
  border-radius: 6px;
  position: relative;
}

.datePickerCustom {
  height: 32px;
  column-gap: 4px;
  padding: 0 18px 0 5px;
  margin-bottom: 0;
  min-width: 140px;
}

.datePickerInput {
  width: 80px;
  background-color: transparent;
}

.datePickerPopper {
  z-index: 4;
}

.iconWrapper {
  position: absolute;
  top: 6px;
  right: -3px;
}

.iconSend {
  cursor: pointer;
  &:hover {
    & path {
      fill: $blue-lazure;
    }
  }
  &_disabled {
    cursor: not-allowed;
    & path {
      fill: $bw-gray4;
    }
  }
}

.invisible *:not(td > p) {
  visibility: hidden;
}
