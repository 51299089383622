.container {
  display: flex;
  padding-top: 20px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.errorBlock {
  min-width: 30vw;
  width: fit-content;
  padding: 20px 0;
}
