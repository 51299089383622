@import "src/shared/assets/styles/mixins/infoCardMixins.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";

.field {
  position: relative;
  padding: 12px 0 12px 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title {
  @include zero;
  @include caption;
}

.value {
  display: flex;
  align-items: center;
  @include inputPrompt;
  color: black;

  & > img {
    padding-right: 4px;
  }
}

.link {
  & a {
    @include inputPrompt;
    color: $blue-lazure;
    cursor: pointer;
    text-decoration: none;
  }
}
