@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";

.inputBlock {
  position: relative;
  min-width: 220px;
  width: 100%;

  &:hover {
    & input {
      border: 1px solid $blue-azure !important;
      cursor: pointer;
    }

    & > svg path {
      fill: $blue-lazure;
      stroke: $blue-lazure;
    }
  }
  & input {
    border-radius: 6px;
    border: none;
    border: 1px solid $bw-gray3;
    min-width: 70px;
    width: 100%;
    height: 31.99px !important;
    padding: 2px 8px 4px 4px !important;
    caret-color: $blue-lazure;
    box-sizing: border-box !important;
    outline: none;

    &:focus {
      border: 1px solid $blue-lazure !important;
    }
  }

  & > svg {
    position: absolute;
    right: 6px;
    top: 4px;
    cursor: pointer;

    transition: all 0.2s ease;
    transform: rotate(0deg);

    & path {
      fill: $bw-gray5;
      stroke: $bw-gray5;
    }
  }
  &_focused {
    & input {
      border: 1px solid $blue-lazure !important;
    }
  }
}

.transformed {
  transition: all 0.2s ease !important;
  transform: rotate(180deg) !important;

  & path {
    fill: $blue-lazure !important;
    stroke: $blue-lazure !important;
  }
}

.list {
  position: absolute;
  top: calc(100% + 4px);
  min-width: 100px;
  @include list;
  padding: 10px;
  background: $bw-white;
  border: 1px solid $blue-azure;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);
  z-index: 2;
  transition: all 0.4s ease-in-out;

  & li {
    width: 100%;
    transition: all 0.4s ease;
    @include zero;
    padding: 5px 10px;
    min-height: 24.5px;
    border-radius: 18px;
    @include listMainText;
    @include clippedText;

    &:hover {
      background: $blue-white-blue;
      cursor: pointer;
      transition: all 0.4s ease;
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }
}
