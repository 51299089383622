@import "src/shared/assets/styles/main.scss";

.main {
  display: flex;
  flex-direction: row;
  column-gap: 80px;
  padding-top: 30px;
}

.menu {
  width: 253px;
  & > :first-child {
    position: sticky;
    top: 20px;
  }
}
