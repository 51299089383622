@import "shared/assets/styles/mixins/mainMixins.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss"; // используется в миксине switch
@import "shared/assets/styles/base/colors.scss"; // используется в миксине switch

.switch {
  @include switch;
  gap: 12px;
}

.errorField {
  & input,
  & input:hover,
  & input:focus {
    border-color: $accent-coral;
  }
}

.error {
  padding-left: 8px;
  padding-top: 4px;
  width: max-content;
  margin: 0;
  @include caption;
  color: $accent-coral;
  line-height: 14px;
  z-index: 1000;
}
