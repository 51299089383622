@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";

.tabs {
  display: flex;
  gap: 10px;
}

.button {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 0;
  border-radius: 6px;

  &:hover,
  &:focus {
    background: $bw-gray7;
    color: $bw-white;
  }
}

.selectedButton {
  background: $bw-gray7;
  color: $bw-white;
}
