@import "src/shared/assets/styles/main.scss";

.commentInputField {
  width: 100%;
  min-height: 180px;
  border-radius: 8px;
  border: 1px solid $bw-gray2;

  &:hover {
    border: 1px solid $blue-azure;
    box-shadow: 0px 2px 8px rgba(0, 140, 255, 0.1);
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  height: 60px;
  border-bottom: 1px solid $bw-gray2;
}

.datePickerCustom {
  border: none;
  flex-direction: row-reverse;
  column-gap: 20px;
  padding: 0;
  &:hover {
    background-color: transparent;
  }
}

.datePickerInput {
  background-color: transparent;
}

.sendButton {
  stroke: $blue-lazure;
  background-color: transparent;
  border: none;
  padding-left: 30px;
  width: 64px;
  height: 60px;
  cursor: pointer;

  &:hover {
    stroke: $blue-lazure;
  }

  &:disabled {
    stroke: $bw-gray3;
  }
}

.errors {
  width: max-content;
  padding: 4px 8px;
  color: $accent-coral;
  font-size: 12px;
  line-height: 14px;
  z-index: 1000;
}
