@import "~/src/shared/assets/styles/main.scss";

.tooltip {
  width: max-content;
  max-width: 300px;
  padding: 16px;
  z-index: 1100;
  @include caption;
  cursor: pointer;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
}

.color {
  @each $name, $color in $colors-list {
    &_#{$name} {
      color: $color;
    }
  }
}

.background {
  @each $name, $color in $colors-list {
    &_#{$name} {
      background-color: $color;
    }
  }
}

.border {
  @each $name, $color in $colors-list {
    &_#{$name} {
      border: 1px solid $color;
    }
  }
}
