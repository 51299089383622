@import "src/shared/assets/styles/main.scss";

$space40: 40px;
$space20: 20px;

.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(#{$maxHeight} - 200px);
}

.tableContainer {
  height: calc(#{$maxHeight} - 300px);
  margin-top: 33px;
  overflow-y: auto;
}

.table {
  width: 100%;

  & th {
    position: sticky;
    top: 0;
    padding: 1px 0 23px 0;

    background-color: $bw-white;
    border-bottom: none;
    z-index: 3;
  }

  & .tableBody {
    border-top: none;

    & tr:hover {
      cursor: pointer;

      & td:not(:first-child) {
        &::after {
          background: $blue-azure;
          transition: all 0.4s ease-in-out;
        }
        & input {
          border: 1px solid $blue-azure;
          transition: all 0.4s ease-in-out;
        }
      }
      & div[data-select] {
        border: 1px solid $blue-azure;
        transition: all 0.4s ease-in-out;
      }
    }

    & td {
      position: relative;
      padding: 0;
      border-bottom: none;

      &:not(:first-child) {
        padding-bottom: $space20;
        padding-right: $space40;
      }

      &:not(:last-child) {
        &::after {
          content: "";
          position: absolute;
          right: -1px;
          top: 31px;
          width: calc(#{$space40} + 2px);
          height: 1px;
          background: $bw-gray2;
          z-index: 0;
          transition: all 0.4s ease-in-out;
        }
      }

      &:nth-last-child(2)::after,
      &:first-child::after {
        content: none;
      }

      &:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:nth-child(2) {
        width: calc(99px - #{$space40});
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        width: calc(540px - #{$space40});
      }
      &:nth-last-child(2) {
        padding-right: $space20;
      }
    }
  }

  & th:first-child,
  & td:first-child {
    text-align: center;
    width: 104px;
  }

  & th,
  & td {
    &:nth-child(2) {
      width: 99px;
    }
  }
}

.selected {
  & td:not(:first-child) {
    &::after {
      background: $blue-azure;
      transition: all 0.4s ease-in-out;
    }
    & input {
      border: 1px solid $blue-azure;
      transition: all 0.4s ease-in-out;
    }
  }

  & div[data-select] {
    border: 1px solid $blue-azure;
  }
}

.btns {
  display: flex;
  & button {
    padding: 19px 3px;
  }
}

.loading {
  @include listMainText;
}
