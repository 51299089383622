@import "src/shared/assets/styles/main.scss";

.block {
  display: flex;
  justify-content: center;
  column-gap: 12px;
  padding: 12px 23px;
  border: none;
  border-radius: 20px;
  background-color: $bw-gray1;

  @each $name, $color in $colors-list {
    position: relative;

    &_#{$name} {
      background-color: color-level($name, 3);
    }
  }
}

.pointer {
  cursor: pointer;
}

.item {
  width: max-content;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;

  &_center {
    align-items: center;
  }
}

.borderRight {
  border-right: 1px solid $bw-gray4;
  padding-right: 12px;
}

.title {
  @include caption;
  color: $bw-gray6;
}

.value {
  @include subtitle;
  color: $bw-black;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.iconQuestion {
  & circle {
    stroke: $bw-gray6;
  }

  & rect,
  & path {
    fill: $bw-gray6;
  }

  &:hover {
    & circle {
      stroke: $bw-gray7;
    }

    & rect,
    & path {
      fill: $bw-gray7;
    }
  }
}
