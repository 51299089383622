@import "src/shared/assets/styles/main.scss";

.allCompanyTable {
  display: grid;
  grid-auto-columns: repeat(1fr);
  grid-auto-flow: column;
  border-bottom: 1px solid $bw-gray3;
  position: relative;
}

.oneCompanyBlock {
  @include mainText;
  min-width: 28vw;
  border-bottom: none;
  height: max-content;
  &_title {
    position: sticky;
    z-index: 1;
    top: 0;
    background-color: $bw-white;
    padding: 13px;
    border: 1px solid $bw-gray3;
    border-right: none;
  }
}

.shadow {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  transform: box-shadow 0.4s ease-in-out;
}

.indexBorder {
  position: absolute;
  width: 40px;
  height: 100%;
  border-right: 1px solid $bw-gray3;
  border-left: 1px solid $bw-gray3;
}

.iconExpand {
  margin-right: 16px;
  margin-top: -5px;
  cursor: pointer;
  transform: rotate(180deg);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  & > circle {
    fill: none;
    stroke: $accent-coral;
  }
  & > path {
    stroke: $accent-coral;
  }

  &_closed {
    transform: rotate(360deg);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;

    & > path,
    & > circle {
      stroke: $blue-lazure;
    }
  }
}

.emptyAlert {
  margin-left: 30px;
}
