@import "src/shared/assets/styles/main.scss";

.commentContainer {
  margin-left: 60px;
  width: 43%;
  min-height: 50vh;
}

.commentHeader {
  @include subtitle;
  margin-bottom: 30px;
}
