@import "/src/shared/assets/styles/mixins/mainMixins.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

%border {
  border: 1px solid $bw-gray3;
  &:first-child {
    border-left: none;
  }
  &:last-child {
    border-right: none;
  }
}

.title {
  @include caption;
  background-color: $bw-white !important;
  white-space: nowrap;
  padding: 12px 8px !important;
  @extend %border;
}

.tableBody {
  @include mainText;
  & > tr {
    vertical-align: middle;

    & > td {
      padding: 12px 8px;
      @extend %border;
    }
    & > td > p {
      margin: 0;
    }
  }
}

.hoverRow {
  &:hover {
    & td:not([data-field="notHover"]) {
      cursor: pointer;
      background: $blue-white-blue;
    }
  }
}

.greyRow {
  background-color: $bw-gray1;
}

.building {
  min-width: 30vw;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.buildingTitle {
  @include zero;
  @include subtitle;
  text-decoration: none;
  cursor: pointer;
  color: $blue-lazure;
}

.uidContainer {
  min-width: 8vw;
}

.uidCell {
  display: flex;
  align-items: center;
  gap: 4px;
  text-decoration: none;

  & p {
    margin: 0;
    color: $blue-lazure !important;
  }
}

.uidIcon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $bw-gray2;
}

.statusCell {
  display: flex;
  align-items: center;
  column-gap: 4px;
  & p {
    margin: 0;
  }
}

.sumPremiumItem {
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    border-bottom: 1px solid $bw-gray3;
    padding: 5px 0;
    margin-bottom: 5px;
  }
}

.tooltip {
  position: relative;
  @include tooltip();
}
