@import "~/src/shared/assets/styles/main.scss";

%required {
  content: "";
  position: absolute;
  left: 15px;
  top: 32px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: $accent-light-orange;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 17px 0 14px;
  height: 72px;
  border: 1px solid $bw-gray3;
  border-radius: 6px;
  cursor: pointer;

  & input {
    width: 100%;
    border: none;
    padding-left: 0;
    cursor: pointer;
    @include mainText;
    background-color: transparent;
    caret-color: $blue-lazure;
    &::placeholder {
      @include inputPrompt;
      color: $bw-gray6;
    }

    &:focus,
    &:active,
    &:active:focus,
    &:focus:active {
      outline: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 35px white inset !important;
      box-shadow: 0 0 0 35px white inset !important;
    }
  }

  &:hover {
    background-color: $blue-white-blue;
    border-color: $blue-azure;

    & input {
      background-color: $blue-white-blue;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 35px $blue-white-blue inset !important;
        box-shadow: 0 0 0 35px $blue-white-blue inset !important;
      }
    }
  }
}

.requiredBlock {
  padding-left: 27px;
}

.disabled {
  background-color: $bw-gray2;
  border: none;
  cursor: not-allowed;
  & input {
    background-color: $bw-gray2;
    cursor: not-allowed;
  }

  &:hover {
    background-color: $bw-gray2;
    border: none;
    & input {
      background-color: $bw-gray2;
    }
  }
}

.active {
  border-color: $blue-lazure;
}

.buttonMode {
  border: none;
  padding: 0;
  display: block;
  width: 100%;
  height: 100%;

  & > :first-child {
    padding: 0;
  }

  & label {
    display: none;
  }
}

.valueContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  display: none;
}

.titleWithValue {
  display: block;
  @include zero;
  @include caption;
  color: $bw-gray6;
  margin-bottom: 6px;
}

.requiredWithoutValue {
  &::before {
    @extend %required;
  }
}

.required {
  &::before {
    @extend %required;
    top: 17px;
  }
}

.error {
  position: absolute;
  inset: calc(100% + 5px) 0 0 8px;
  @include caption;
  color: $accent-coral;
}

.invalid {
  border-color: $accent-coral;
  margin-bottom: 18px;

  & .required::before {
    content: "";
    background-color: $accent-coral;
  }
}

.iconBtns {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.clearBtn {
  & rect {
    fill: $bw-gray5;
  }

  &:hover {
    & rect {
      fill: $bw-gray7;
    }
  }
}

.clearBtnDisabled {
  & rect {
    fill: $bw-white;
  }
  &:hover {
    & rect {
      fill: $bw-white;
    }
  }
}

.iconCalendar_active {
  & svg > :first-child,
  & svg > :last-child {
    stroke: $blue-lazure !important;
  }

  & svg > :last-child,
  & rect {
    fill: $blue-lazure !important;
  }
}

.day {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputButton {
  border: none;
  width: 100%;
  height: 100%;
  background-color: $bw-gray2;
  border-radius: 6px;
  padding: 10px 16px;
  @include subtitle2;

  &:hover {
    background-color: $bw-gray3;
  }
}
