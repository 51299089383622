@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";


.emptyAlert {
  @media screen and (min-width: 1900px) {
    max-width: 30vw;
  }
}

.table {
  margin: 0;
  padding: 0;
}

.thead {
  background-color: $bw-white;
  border-bottom: 1px solid $bw-gray2;
}

.title {
  @include caption;
  color: $bw-gray6;
}

.tr {
  border-bottom: 1px solid $bw-gray2;
  & > :first-child {
    width: 14vw;
  }
  & > :nth-child(2) {
    width: 6vw;
  }
  & > :nth-child(3),
  & > :nth-child(4) {
    text-align: center;
    font-size: 12px;
  }
}

.warning {
  background-color: $accent-yellow;
  & > td {
    & > div {
      & > :first-child {
        color: $accent-orange;
      }
    }
  }
}

.red {
  background-color: $accent-pastel-red;
  & > td {
    & > div {
      color: $accent-red;
      & > :first-child {
        color: $accent-red;
      }
    }
  }
}

.td {
  @include listMainText;
  color: $bw-black;
  word-wrap: break-word;
  padding: 8px;
  text-align: left !important;
}
