@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";

.countBar {
  @include caption;
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 8px;
  background-color: $accent-light-green;
  height: 40px;
  padding: 13px 12px;
  position: sticky;
  z-index: 1;

  & span {
    color: $bw-gray7;
    margin-left: 8px;
  }

  &_sticky {
    transition-duration: 0.2s;
    padding: 10px 20px;
    border-radius: 0px;
    margin-left: -30px !important;
    width: calc(100% + 60px) !important;
  }

  &_normal {
    transition-duration: 0.1s;
    border-radius: 12px;
    padding: 12px;
  }
}
