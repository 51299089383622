@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

.errors {
  background-color: $bw-white;

  @each $name, $color in $colors_list {
    &_#{$name} {
      border-radius: 6px;
      @if ($name == "danger") {
        background-color: $accent-pastel-red;
      } @else if $name == "success" {
        background-color: $accent-light-green;
      } @else if $name == "warning" {
        background-color: $accent-beige;
      } @else if $name == "empty" {
        background-color: $bw-gray1;
      } @else if $name == "info" {
        background-color: $blue-light-blue;
      } @else {
        background-color: rgba($color, 0.1);
      }
    }
  }
}

.fitContent {
  width: fit-content;
  margin-top: 20px;
}

.errorsIcon {
  @each $name, $color in $colors_list {
    &_#{$name} {
      & path:first-of-type {
        stroke: $color;
      }
      & path:last-of-type {
        fill: $color;
      }
    }
  }
}

.errorsBody {
  width: 100%;
  padding: 0 17px;
}

.errorsBodyWithoutHead {
  display: flex;
  justify-content: space-between;
  align-items:flex-start;
}

.errorsBodyIcon {
  margin-top: 20px;
}

.errorsHeading {
  @include subtitle;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  position: relative;
  padding: 17px;
}

.errorsHeadingHover {
  &:hover {
    cursor: pointer;
  }
}

.errorsHeadingCenter {
  display: flex;
  gap: 10px;
  align-items: center;
}

.bodyExpandIcon {
  cursor: pointer;
  stroke: $bw-black;
  transition-duration: 0.2s;
  transform: rotate(-90deg);
}

.bodyExpandIconOpened {
  transform: rotate(90deg);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}

.error {
  padding: 15px 0px;
  width: 100%;
}

.errorItem {
  @include listMainText;
  margin: 8px 0;
}

.errorItemLink {
  display: flex;
  align-items: center;
  color: $blue-lazure;
  margin-right: 10px;
  cursor: pointer;
  text-decoration: none;
}

.errorWithBorder {
  border-top: 1px solid $bw-gray4;
}

.errorHeading {
  @include subtitle2;
  line-height: 19px;
}

.errorList {
  padding-left: 17px;
  margin-bottom: 0;
}

.itemList {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.errorsHeadingText {
  width: 100%;
  a {
    color: $blue-lazure;
    text-decoration: none;
  }
}
