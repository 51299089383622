@import "shared/assets/styles/main.scss";

%inputStyles {
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-radius: 0px;
  background-color: rgba(255, 255, 255, 0);
}

.table {
  @include listMainText;
  border-top: 1px solid $bw-gray3;
  border-right: 1px solid $bw-gray3;
  border-left: 1px solid $bw-gray3;
  position: relative;
  z-index: 1;

  & > thead {
    position: sticky;
    z-index: 1;
    top: 29px;
    background-color: $bw-white;
  }

  & > tbody td {
    padding: 0;
  }

  & > tbody tr {
    cursor: pointer;
    &:hover {
      background-color: $blue-white-blue !important;

      & > td {
        background-color: $blue-white-blue !important;
        & > :first-child {
          background: $blue-white-blue !important;
        }
      }

      & > :last-child {
        background-color: $bw-white !important;
      }

      & svg[data-svg="true"],
      & svg[data-svg-edit="true"],
      & div[data-svg="true"] {
        background-color: $blue-white-blue !important;
      }

      & svg[data-svg-edit="true"] path {
        fill: $blue-white-blue !important;
      }
    }
  }
}

.empty {
  border-top: 1px solid $bw-white;
  border-bottom: 1px solid $bw-white;
  border-right: none;
  border-left: 1px solid $bw-gray3;
  background-color: $bw-white !important;
  width: 20px;
  height: 100%;
}

.titles {
  border-top: 1px solid $bw-gray3;

  &__oneTitle {
    display: flex;
    width: max-content;
    position: relative;
    color: $bw-gray5;
    padding-left: 8px;
    @include caption;
    &_required {
      &::before {
        content: "";
        position: absolute;
        left: -2px;
        top: 3px;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: $accent-light-orange;
      }
    }

    &_custom {
      margin: -0.5rem;
      padding: 0.5rem 0.5rem 0.5rem 15px;
      &::before {
        background-color: $blue-lazure;
        top: calc(0.5rem + 3px);
        left: 6px;
      }
    }

    &_kc {
      &::before {
        top: 48%;
      }
    }
  }

  & > tr > :first-child {
    border-right: 1px solid $bw-gray3;
  }
}

.shadow {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  transform: box-shadow 0.4s ease-in-out;
}

.original {
  background-color: $accent-yellow;

  & > :first-child {
    border-left: 1px solid $bw-gray3;
    padding: 0 !important;
    height: 60px;
    background-color: $bw-white;
    font-weight: 500;
  }

  &__cell {
    height: 40px;
    padding: 0.5rem !important;

    &__value {
      display: flex;
      height: 100%;
      align-items: center;
      gap: 2px;
      width: max-content;
    }

    &_manualHours {
      @extend .original__cell;
      padding: 0 !important;

      &__value {
        display: grid;
        grid-template-rows: 27px 32px;
        &__title {
          background-color: $accent-light-green;
          color: $bw-gray5;
          text-align: center;
          width: 160px !important;
          padding: 5.5px;
        }

        &__text {
          display: flex;
          align-items: center;
          margin: 0 auto;
        }
      }
    }
  }

  &:hover {
    @extend .highlighted;
  }
}

.link {
  & > :first-child {
    display: flex;
    gap: 5px;
    color: $bw-gray5;
  }

  & a {
    text-decoration: none;
    color: $blue-lazure;
    word-wrap: break-word;
  }
}

.copy {
  border-bottom: 1px solid $bw-gray3;
  height: 75px;
  background-color: $bw-white;

  &_gray {
    background-color: $bw-gray1;
    border-bottom: 1px solid $bw-gray3;
    height: 75px;

    .row__title,
    .row__value {
      background-color: $bw-gray1;
    }
  }

  &__title {
    height: 75px;

    &_yellow {
      & > :first-child {
        background-color: $accent-yellow !important;
      }
    }

    &__text {
      padding: 0.5rem !important;
      background-color: $accent-beige;
      font-weight: 500;
      border-right: 1px solid $bw-gray3;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
    }

    & svg {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.firstCol {
  position: sticky !important;
  left: 0;
  z-index: 5;
  box-shadow: 4px 0px 20px rgba(0, 0, 0, 0.08) !important;

  &__original {
    & > :first-child {
      margin-top: -31px;
      padding-top: 30px;
    }
    & > :last-child {
      height: 90px;
    }
  }

  &__firstCell {
    padding: 0 !important;
    width: max-content;

    & > div {
      background-color: $bw-white;
      border-right: 1px solid $bw-gray3;
      padding: 0;
      width: calc(100% + 1px);
      height: 30px;
    }
  }

  &_shadow {
    & > div {
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
    }
  }
}

.field {
  height: 75px;
  padding: 0.5rem !important;
  vertical-align: middle;
  width: max-content;

  &__fieldContainer {
    margin-right: 12px;

    border-left: none;
    background-color: rgba(255, 255, 255, 0);
    padding: 0 !important;

    & > div input {
      @include listMainText;
      @extend %inputStyles;
      height: 30px !important;

      padding: 15.5px 8px !important;
      padding-left: 0 !important;
      &:disabled {
        color: $bw-gray5;
      }

      &:focus,
      &:active {
        border: none !important;
        border-bottom: 1px solid $blue-lazure !important;
        caret-color: $blue-lazure;
      }
    }

    &_project {
      color: $bw-gray5;
    }
  }

  &_size {
    height: 75px;
    padding: 0.5rem !important;
    vertical-align: middle;

    &__fieldContainer {
      & > :first-child {
        margin-right: 12px;

        border-left: none;
        background-color: rgba(255, 255, 255, 0);
        padding: 0 !important;

        & > :first-child > :nth-child(2),
        & > :nth-child(n + 2):nth-child(-n + 6) {
          & input {
            @include listMainText;
            @extend %inputStyles;
            height: 30px !important;

            padding: 15.5px 8px !important;
            padding-left: 0 !important;
            &:disabled {
              color: $bw-gray5;
            }

            &:focus,
            &:active {
              border: none !important;
              border-bottom: 1px solid $blue-lazure !important;
            }
          }
        }
      }
    }
  }

  &_manualHours {
    @extend .field;
    padding: 0 !important;

    &__fieldContainer {
      @extend .field__fieldContainer;
      display: grid;
      grid-template-rows: 27px 32px;
      width: max-content;
      margin: 0;

      &__title {
        background-color: $accent-light-green;
        color: $bw-gray5;
        text-align: center;
        width: 160px !important;
        padding: 5.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: -12px;
      }

      &__input {
        display: flex;
        align-items: center;

        & input {
          box-sizing: border-box;
          text-align: center;
        }
      }
    }

    &_massfill {
      position: relative;
      width: 160px !important;

      &__fieldContainer {
        display: flex;
        align-items: center;

        & input {
          text-align: center;
        }
      }
    }
  }

  &_kc {
    @extend .field;
    padding-top: 5px !important;

    &__fieldContainer {
      @extend .field__fieldContainer;
      height: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      & label {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }

    &_massfill {
      @extend .field;
      padding-top: 0px !important;
      position: relative;

      &__fieldContainer {
        @extend .field__fieldContainer;
        display: flex;
        align-items: center;
        & div > input {
          padding-right: 24px !important;
        }
        &__title {
          width: 80%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          gap: 8px;

          & label {
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
          }
        }
      }
    }
  }

  &_select {
    vertical-align: middle;
    &__fieldContainer {
      width: 100%;
      margin-right: 12px;
      padding: 0 !important;
      border-left: 0;

      // контейнер поля-селекта
      & > :first-child {
        width: 100% !important;
      }

      // селект-поле
      & > :first-child > :first-child > :first-child {
        @include listMainText;
        @extend %inputStyles;

        padding: 0;
        margin-left: 8px;
        margin-bottom: -2px;
        height: 30px !important;

        & > div > svg path {
          fill: $blue-lazure;
          stroke: $blue-lazure;
        }
      }

      // текст option в селект-поле
      & p {
        padding-left: 0px;
        &::before {
          display: none;
        }
      }
    }

    &_massfill {
      @extend .field_select;
      position: relative;
      padding-right: 32px !important;
      &__fieldContainer {
        @extend .field_select__fieldContainer;
        display: flex;
        align-items: center;
        width: calc(100% + 20px);

        // селект-поле, отступ для отображения справа кнопки iconSend
        & > :first-child > :first-child > div {
          margin-right: 18px;
        }
      }
    }
  }

  &_massfill {
    @extend .field;
    position: relative;
    &__fieldContainer {
      @extend .field__fieldContainer;
      display: flex;
      align-items: center;
      & div > input {
        padding-right: 24px !important;
      }
    }
  }
}

.massDateCell {
  position: relative;
  & > div:nth-child(2) {
    top: 4px;
    right: -5px;
  }
}

.datePickerCustom,
.datePickerCustomMass {
  height: 32px;
  padding: 6px 5px 7px 5px;
  @extend %inputStyles;
  justify-content: flex-start;
}

.datePickerCustomInput {
  @include listMainText;
  background-color: rgba(255, 255, 255, 0);
  margin-bottom: -8px;
  caret-color: $blue-lazure !important;
}

.datePickerCustomMass {
  padding-right: 20px;
}

.massfill {
  height: 70px;

  &__title {
    padding: 0.5rem !important;
    background-color: $accent-light-green;
    font-weight: 500;
    border-right: 1px solid $bw-gray3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
}

.highlighted {
  background-color: $blue-white-blue !important;

  & > :first-child {
    background-color: $blue-white-blue !important;
  }

  & input,
  & td,
  & span[data-type="tooltip"],
  & span[data-type="tooltip"] div {
    background-color: $blue-white-blue !important;
  }

  & span[data-type="tooltip"] svg[data-svg="true"] path {
    fill: $blue-white-blue !important;
  }
}

.focused {
  & .field__fieldContainer,
  & .field_date__fieldContainer,
  & .field_select__fieldContainer {
    border-radius: 0px;
    border-bottom: 1px solid $blue-lazure;
  }

  & div[data-select="true"],
  & .field_kc_massfill__fieldContainer {
    border: none !important;
  }

  & input:focus,
  & input:active {
    margin-bottom: -1px;
    border: none !important;
  }

  & div[data-type="iconSend"] {
    & path {
      fill: $blue-lazure;
    }

    &:hover {
      & path {
        fill: $blue-dark-blue;
      }
    }
  }
}

.iconDash path {
  fill: $accent-coral;
}
