.react-datepicker {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;

  &-popper {
    width: 243px;
    height: fit-content;
    padding: 14px 7px 20px 7px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
      0px 2px 8px rgba(0, 140, 255, 0.1);
    background: #ffffff;
    border-radius: 8px;
    z-index: 1100;

    & > div,
    & > div > div {
      height: 100%;
    }
  }

  &__month-container {
    width: 100%;
  }

  &__day-names {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #000000;
  }

  &__day-name {
    padding: 6px 8px;
    border-bottom: 1px solid #f3f3f3;
  }

  &__week {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &__day {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #000000;
    cursor: pointer;
    transition: all 0.4s ease;
    margin: 0 !important;

    &:hover {
      background: #cee9ff;
      border-radius: 2px;
      transition: all 0.4s ease;
    }

    &--disabled,
    &--outside-month {
      color: #c5c6c6 !important;
      background: #fafafa;
    }

    &--selected {
      color: #ffffff;
      border-radius: 2px;
      background: #3a3a39;
      transition: all 0.4s ease;

      &:hover {
        color: #ffffff;
        border-radius: 2px;
        background: #3a3a39;
        transition: all 0.4s ease;
      }
    }
  }

  &__year-wrapper,
  &__month-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    column-gap: 20px;
    row-gap: 10px;
  }

  &__year-wrapper {
    column-gap: 3px;
  }

  &__year-text,
  &__month-text {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 13px;
    border-radius: 2px;

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    cursor: pointer;

    &:hover {
      background: #cee9ff;
      transition: all 0.4s ease;
    }

    &--selected {
      color: #ffffff;
      border-radius: 2px;
      background: #3a3a39;
      transition: all 0.4s ease;

      &:hover {
        color: #ffffff;
        border-radius: 2px;
        background: #3a3a39;
        transition: all 0.4s ease;
      }
    }

    &--disabled {
      color: #c5c6c6 !important;
      background: #fafafa;
      cursor: default;
      &:hover {
        background: #fafafa;
      }
    }
  }

  &__year-text--selected {
    border-radius: 4px;
  }
}
