@import "shared/assets/styles/main.scss";

.table {
  @include mainText;
  & > :first-child {
    border-top: 1px solid $bw-gray2;
    border-bottom: 1px solid $bw-gray2;
  }
  & > thead {
    position: sticky;
    top: -2px;
    z-index: 1;
    & tr {
      & th {
        padding: 0.4rem 0.4rem 0.3rem;
        @include caption;
        background-color: white !important;
        white-space: nowrap;
      }

      & div {
        display: flex;
        align-items: center;
        height: 20px;

        & p {
          @include zero;
        }
      }
    }
  }
}

.shadow {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  transform: box-shadow 0.4s ease-in-out;
}

.tableBody {
  border-top: none !important;

  & td > div,
  & td > a,
  & td > table {
    display: flex;
    align-items: flex-start;
    min-height: 20px;
    margin: 10px 0;
  }

  & p {
    @include zero;
  }
}

.tableRow {
  &:hover {
    & td:not([data-field="uid"]) {
      cursor: pointer;

      background: $blue-white-blue;
    }
  }

  &_grey {
    background-color: $bw-gray1;
    & > :first-child {
      background-color: white;
    }
  }
}

.uid {
  border-right: 1px solid $bw-gray3;

  & > div {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 7fr;
    align-items: center;
    gap: 8px;
  }

  &__iconAdd {
    cursor: pointer;
    & > circle {
      fill: $bw-gray2;
    }
  }

  &__iconExpand {
    cursor: pointer;
    transform: rotate(360deg);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    & > circle {
      fill: $bw-gray2;
    }
    & > path {
      stroke: $blue-lazure;
    }

    &_opened {
      transform: rotate(180deg);
      -webkit-transition-duration: 0.4s;
      transition-duration: 0.4s;
      & > path {
        stroke: $accent-coral;
      }
    }

    &_blocked {
      transform: none;
      cursor: not-allowed;
      & > circle {
        fill: $bw-gray2;
      }
      & > path {
        stroke: $bw-gray5;
      }
    }
  }

  &__link {
    color: $blue-lazure;
    margin-right: 10px;
    cursor: pointer;
    text-decoration: none;

    & > p {
      @include zero;
    }
  }
}

.typeBlock {
  gap: 20px;
}

.type {
  @each $name, $color in $colors-list {
    &_#{$name} {
      color: $color !important;
      @if ($name == "default") {
        color: $bw-black !important;
      }
    }
  }
}

.connect {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $bw-gray2;
  position: relative;
  bottom: 2px;
}

.loading {
  position: relative;
  height: 50px;
  & > div {
    background-color: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
