@import "src/shared/assets/styles/main.scss";

// Есть задача не рефактор и все переделаю, ппо этому классы не менял.
.container {
  width: 38.55vw;
}

.window {

  // для formWindow_body
  & > :first-child > :nth-child(2) {
    position: relative;
  }

  &_withText {
    top: 100px;
  }

  &__title {
    @include mainText;
    margin-bottom: 10px;
  }

  &__photo {
    & img {
      width: 100%;
      height: 100%;
    }
    padding-bottom: 20px;
  }

  &__prevBtn {
    opacity: 0;
    cursor: pointer;
    width: 60px;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 5;
    background-color: #6e6e6e00;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -10px;

    & svg {
      margin-top: -5px;
    }

    &:hover {
      opacity: 0.7;

      & > :first-child {
        border-radius: 50%;
        padding: 15px;
        &:hover {
          transition: all 0.5s ease;
          background-color: $blue-white-blue;

          & svg path {
            stroke: $blue-lazure;
          }
        }
      }

      & svg path {
        stroke: $blue-lazure;
        fill: none;
      }
    }
    &:disabled {
      display: none;
    }
  }

  &__nextBtn {
    @extend .window__prevBtn;
    margin-left: 0px;
    right: 5px;
  }
}
