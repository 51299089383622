@import "/src/shared/assets/styles/base/colors.scss";

.list {
  right: 0;
  margin-top: 3px;
  display: block;
  min-width: 300px;
  max-width: 400px;
  z-index: 99999;

  // TODO: вынести стили в класс для ItemsScrollBoard
  & > :first-child {
    border-color: $blue-lazure;
    & > :last-child {
      & > :first-child {
        height: max-content;
        max-height: 300px !important;
      }
    }
  }
}
