@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";

.value {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  justify-content: flex-start;
}

.icon {
  position: relative;
}

.iconPoints {
  cursor: pointer;
}

.list {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 25px;
  padding: 12px 16px 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);
  border-radius: 6px;
  border: 1px solid $blue-azure;
  width: 430px;
  display: flex;
  flex-direction: column;
  background-color: white;

  & > :nth-child(2) {
    width: 90%;
  }
}

.icon {
  position: absolute;
  cursor: pointer;
  transition: all 0.4s ease;
  align-self: flex-end;
  background-color: $bw-white !important;
  border-radius: none;
  padding: 0;
  &:hover {
    & svg path {
      fill: $bw-gray7;
    }
    transition: all 0.4s ease;
  }
}

.pair {
  @include listMainText;
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-radius: 8px;
  cursor: pointer;
  padding: 8px 16px;
  &:hover {
    background-color: $blue-white-blue;
    transition: all 0.4s ease;
  }
}
