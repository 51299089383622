@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";

.thead {
  width: 100%;
  position: relative;
}

.monthCellWithButton {
  min-width: 200px !important;
  padding-left: 120px !important;
}

.row {
  background-color: $bw-white;
  min-height: 50px !important;
  max-height: 50px !important;
  height: 50px !important;
  width: 100% !important;

  th:not(:first-child) {
    border: 1px solid $bw-gray3;
    vertical-align: middle;
    padding: 8px;
  }

  .reverseIconCell {
    position: sticky;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: $bw-white !important;
    border-left: 1px solid $bw-gray3;
    border-top: 1px solid $bw-gray3;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    width: 48px !important;
    padding: 8px 7px 8px 8px !important;
  }
}

.reverseIcon {
  transform: rotate(90deg);
}

.monthTitle {
  @include zero;
  @include listMainText;
}
