@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/base/colors.scss";

.title {
  @include subtitle;
}

.historyContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0 0;
  box-sizing: border-box;
}

.header {
  width: 100%;
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.iconCollapse {
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}

.iconCollapseClosed {
  transform: rotate(180deg);
}

.iconCollapseOpened {
  transform: rotate(360deg);
}

.content {
  padding-top: 20px;
  box-sizing: border-box;
  width: 100%;
}
