@import "shared/assets/styles/mixins/mainMixins.scss";
@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/base/sizes";

.background {
  @include backgroundModal;
}

.window {
  position: relative;
  background-color: $bw-white;
  max-height: $maxHeight;
  min-width: 500px;
  margin: auto;
  border: 1px solid $blue-azure;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);

  & > form {
    height: max-content;
  }

  & div[data-error="true"] {
    margin: 15px 0 0 0;
  }

  & div[data-loading="true"] {
    position: absolute;
    display: block;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    left: 0;
    border-radius: 8px;
    margin: 1px;

    & > div {
      width: 100%;
      height: 100%;
    }
  }
}

.paddingBox {
  padding: 20px 0;
  box-sizing: border-box;
}

.body {
  padding: 0 15px;
  box-sizing: border-box;

  // для firefox нужно спрятать вертикальный скролл
  & > :first-child {
    @-moz-document url-prefix() {
      margin-right: -31px !important;
      padding-right: 17px;
    }
  }
}

.errors {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.buttons {
  padding: 20px 15px 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;

  &.scrolled {
    border-top: 1px solid #00000014;
    box-shadow: 0px -4px 20px 0px #00000014;
  }

  & > div {
    & button {
      min-width: 180px;
    }
  }
}
