@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/base/colors.scss";

.title {
  @include caption;
  background-color: white !important;
  white-space: nowrap;
  cursor: pointer;
}

.thead {
  position: sticky;
  top: -2px;
  z-index: 1;
  border-top: 1px solid $bw-gray3;
  border-bottom: 1px solid $bw-gray3;
}

.tableBody {
  border-top: none !important;
  @include mainText;
  & > tr {
    cursor: pointer;
    vertical-align: middle;
    &:hover {
      background: $blue-white-blue;
    }
    & > td {
      padding: 13px 6px;
    }
    & > td > p {
      line-height: 22px;
      margin: 0;
    }
  }
}

.greyRow {
  background-color: $bw-gray1;
}

.statusCell {
  display: flex;
  align-items: center;
  column-gap: 8px;
  & p {
    margin: 0;
  }
}

.link {
  text-decoration: none;
  color: $blue-lazure;

  &:hover {
    color: $blue-lazure;
  }
}

.withoutPayment {
  margin-left: 26px;
  padding-bottom: 15px;
  @include listMainText;
}
