@import "shared/assets/styles/base/screen.scss";
// миксин для удаления лишней границы между ячейками
@mixin deleteExtraBorder($color, $hoveredColor, $isImportant: false) {
  & td,
  & th {
    position: relative;

    &::after {
      content: "";
      display: block;
      background-color: $color;
      width: 2px;
      height: calc(100% - 0.5px);
      position: absolute;
      top: 0;
      right: -1px;
      z-index: 1;

      @if ($isImportant) {
        background-color: $color !important;
      }
    }
  }

  &:hover {
    background: $hoveredColor !important;
    border-top: 1px solid $hoveredColor !important;
    border-bottom: 1px solid $hoveredColor !important;
    & td::after {
      background-color: $hoveredColor !important;
    }
  }
}
