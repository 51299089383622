@import "~/src/shared/assets/styles/main.scss";

.number {
  position: sticky;
  left: 0;
  z-index: 2;
  min-width: 60px;
  vertical-align: middle;
  cursor: grab !important;
  background-color: $accent-yellow;

  &__empty {
    background-color: $bw-white;
    z-index: 3;
    cursor: pointer !important;
  }
  img {
    display: none;
  }
  &:hover {
    img {
      display: block;
    }
  }
}

.cell::after {
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  width: 15px;
  color: $bw-gray5;
}

.cell {
  height: 40px;
  &__shadow::after {
    box-shadow: 13px 0 13px -15px inset;
    right: -15px;
  }
}
