@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "../stickyMixin.scss";

.container {
  @extend %sticky;
  top: 95px;
  margin: -30px -30px 0 -30px;
  padding: 30px 30px 0 30px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.addButton {
  & button {
    @include inputPrompt;
    color: $bw-white;
    padding: 0;
  }
}

.openAllFieldsButton {
  width: fit-content;
  padding: 12px;
  margin-bottom: 20px;
  cursor: pointer;
  background-color: $bw-white;
  @include listMainText;
  position: sticky !important;
  top: 176px;
  z-index: 2;
  color: $bw-gray6;
  &:hover {
    text-shadow: 0 0 0.1px $bw-gray6, 0 0 0.1px $bw-gray6;
  }
}

.openAllFieldsButtonActive {
  color: $blue-lazure;
  &:hover {
    text-shadow: 0 0 0.1px $blue-lazure, 0 0 0.1px $blue-lazure;
  }
}
