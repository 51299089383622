@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";

.whiteRow > th {
  background-color: $bw-white;
}

.header > th {
  outline: 1px solid $bw-gray3 !important;
}

.titleCell {
  @include caption;
  background-color: $bw-white !important;
  white-space: nowrap;
  background-clip: padding-box;
}

.title {
  margin-bottom: 6px;
}

.foundedNumbers {
  @include subtitle;
  line-height: 22px;
}

.firstStickyCol,
.secondStickyCol,
.thirdStickyCol,
.fourthStickyCol {
  position: sticky;
}

.thirdStickyCol,
.fourthStickyCol {
  min-width: 260px;
  width: 260px;
}

.firstStickyCol {
  min-width: 60px;
  width: 60px;
  left: 0;
}

.secondStickyCol {
  min-width: 290px;
  width: 290px;
  left: 60px;
}

.thirdStickyCol {
  left: 350px;
}

.fourthStickyCol {
  left: 610px;
  &::after {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
    color: $bw-gray5;
  }
}

.stickyCellShadow::after {
  box-shadow: 13px 0 13px -15px inset;
  right: -15px;
}

.withBorderBottom {
  @extend .titleCell;
  padding: 0 !important;
  & > div {
    min-height: 35px;
    padding: 8px;
    &:not(:last-child) {
      border-bottom: 1px solid $bw-gray3;
    }
  }
}

.sortIcon {
  cursor: pointer;
  & path {
    fill: $bw-gray4;
    transition: stroke 0.2s ease;
  }
}

.sortIconActive {
  cursor: pointer;
  & path {
    fill: $blue-lazure;
    transition: stroke 0.2s ease;
  }
}

.copyIcon {
  position: absolute;
  right: 8px;
  top: 6px;
  &:hover {
    & path {
      stroke: $blue-lazure;
      transition: stroke 0.2s ease;
    }
  
  }
}

.certTitle {
  @extend .titleCell;
  @include mainText;
}
