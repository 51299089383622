@import "~/src/shared/assets/styles/main.scss";

@mixin circle($name, $level: 0, $fromField: false) {
  @if ($fromField) {
    background-color: color-level($name, $level);
  } @else {
    border-radius: 50%;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
  }

  & > div {
    @if not($fromField) {
      background-color: color-level($name, $level);
      border-radius: 50%;
      width: 24px;
      height: 24px;
      margin: 0 auto;
    }
  }

  &:hover {
    @if not($fromField) {
      cursor: pointer;
      border: 1px solid $bw-gray3;
    }
  }

  &_selected {
    border: 1px solid $blue-blue;

    &:hover {
      border: 1px solid $blue-blue;
    }
  }
}

.selectContainer {
  position: absolute;
  left: -200px;
  top: 101%;
  padding: 40px 30px 23px 30px;
  background: $bw-white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);
  border-radius: 16px;
  z-index: 2;
}

.title {
  margin-bottom: 20px;
  @include caption;
}

.iconClose {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.iconExpandRotated {
  transform: rotate(180deg);
}

.btnSave {
  width: 100%;
}

.field {
  display: flex;
  justify-content: space-between;
  width: 146px;
  height: 58px;
  border: 1px solid $bw-gray2;
  border-radius: 6px;
  cursor: pointer;

  @each $name, $color in $colors_list {
    &_#{$name} {
      background-color: color-level($name, 0);
    }
  }

  &_selected {
    border: 1px solid $blue-azure;
  }

  &_selectedColor {
    padding: 5px 16px 10px 16px;
  }

  &_noColor {
    padding: 0 16px;
  }
}

.label {
  margin: 0;
  @include caption;
  font-size: 11px;
}

.colorParams {
  width: 70px !important;
  height: 20px !important;

  &_noColorText {
    @include inputPrompt;
  }

  &_bright {
    @each $name, $color in $colors_list {
      &_#{$name} {
        @include circle($name, 0, true);
      }
    }
  }

  &_normal {
    @each $name, $color in $colors_list {
      &_#{$name} {
        @include circle($name, 1, true);
      }
    }
  }

  &_light {
    @each $name, $color in $colors_list {
      &_#{$name} {
        @include circle($name, 2, true);
      }
    }
  }

  &_extra-light {
    @each $name, $color in $colors_list {
      &_#{$name} {
        @include circle($name, 3, true);
      }
    }
  }
}

.colors {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.picker {
  width: 420px;
  display: flex;
  gap: 1px;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.circle {
  &_bright {
    @each $name, $color in $colors_list {
      &_#{$name} {
        @include circle($name);
      }
    }
  }

  &_normal {
    @each $name, $color in $colors_list {
      &_#{$name} {
        @include circle($name, 1);
      }
    }
  }

  &_light {
    @each $name, $color in $colors_list {
      &_#{$name} {
        @include circle($name, 2);
      }
    }
  }

  &_extra-light {
    @each $name, $color in $colors_list {
      &_#{$name} {
        @include circle($name, 3);
      }
    }
  }
}
