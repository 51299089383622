@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.background {
  @include backgroundModal;
}

.modalWindow {
  width: 772px;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  row-gap: 9px;
}

.oneField {
  column-gap: none;
  & > div:first-child {
    width: 100%;
    &::after {
      content: none !important;
    }
  }
}

.doubleDateField {
  display: flex;
  column-gap: 40px;
  & > div:first-child,
  & > div:nth-child(2) {
    width: 25vw;
  }

  & > div:first-child {
    &::after {
      content: "";
      position: absolute;
      right: -40px;
      top: 50%;
      width: 40px;
      height: 1px;
      background: $bw-gray3;
      z-index: 0;
      transition: all 0.4s ease-in-out;
    }
  }
}

.editFileInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $bw-gray3;
  border-radius: 6px;
  height: 72px;
  width: 100%;
  padding: 8px 16px 16px;
  white-space: pre-line;
}

.title {
  @include caption;
  color: $bw-gray6;
  margin-bottom: 8px;
}

.fileInfo {
  display: flex;
  align-items: center;
}

.attachment {
  display: flex;
  align-items: center;
  column-gap: 6px;
  cursor: pointer;

  & > svg {
    stroke: $bw-gray6;

    &:hover {
      stroke: $blue-lazure;
    }
  }
  & > p {
    @include zero;
    @include caption;
    color: $blue-lazure;
  }
}

.file {
  @include listMainText;
  margin-left: 6px;
  & a {
    text-decoration: none;
    @include listMainText;
  }
}

.btnsForFile {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.downloadIcon:hover {
  cursor: pointer;
  & path {
    fill: $blue-lazure;
  }
}

.clearBtn {
  & rect {
    fill: $bw-gray5;
  }

  &:hover {
    & rect {
      fill: $bw-gray7;
    }
  }
}

.tooltipRed {
  cursor: pointer;
  position: relative;
  @include tooltip("left", $accent-coral, $bw-white, $accent-coral);
}

.tooltipBlue {
  cursor: pointer;
  position: relative;
  @include tooltip("left", $blue-lazure, $bw-white, $blue-lazure);
}

.tooltipBlueTop {
  cursor: pointer;
  position: relative;
  @include tooltip("top-start", $blue-lazure, $bw-white, $blue-lazure);

  &:hover {
    & a {
      color: $blue-lazure;
    }
  }
}
