@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";

.addIcon {
  border: none;
  background-color: transparent;
}

.headerShadow {
  box-shadow: 1px 3px 3px 0 rgb(231, 231, 231);
}

.addPresavesIcon {
  @extend .addIcon;
  padding: 20px !important;
  &__disabled {
    cursor: not-allowed !important;
    & rect {
      fill: $bw-gray3 !important;
    }
  }
}

.addPresavesIconCell {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 4;
  background-color: $bw-white;

  &:hover {
    border: 1px solid $blue-lazure;
  }

  &__disabled {
    cursor: not-allowed;
    &:hover {
      border: none;
    }
  }
}

.tN::after {
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  width: 15px;
  color: $bw-gray5;
}

.tN {
  height: 40px;
  &__shadow::after {
    box-shadow: 13px 0 13px -15px inset;
    right: -15px;
  }
}

.inputNumber {
  margin-right: 10px;
  & input {
    width: 80px !important;
    height: 60px !important;
    border: none;
  }
}

.presaveCell {
  position: relative;
  cursor: pointer;
  background-color: $blue-white-blue;
  border: 1px solid $bw-gray2;
  padding: 4px;
  min-width: 340px;

  @each $name, $color in $colors-list {
    &__#{$name} {
      background-color: $color;
    }
  }

  &__white {
    background-color: $bw-white;
  }
  &__active:hover {
    box-shadow: inset 0px 0px 0px 1px $blue-lazure;
  }
}

.hiddenColumn {
  display: none;
}

.manageBtns {
  display: flex;
  column-gap: 4px;
  & > div {
    width: 100%;
  }
}

.manageBtn {
  width: 100%;
  height: 32px;
  border: none;
  border-radius: 8px;
  background-color: $bw-white;
  &:active {
    background-color: $bw-gray1;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
  }
}

.iconBasket__red {
  fill: $accent-red;
}

.datePickerCustom {
  border: none;
  height: 40px;
  flex-direction: row-reverse;
  justify-content: flex-end;
  column-gap: 10px;
  padding: 0;
}

.iconStatus {
  margin-bottom: 5px;
}

.internalNum {
  color: $blue-lazure;
  margin-left: 5px;

  &:hover {
    text-decoration: underline;
  }
}

.iconClose {
  @extend .addIcon;

  &:hover {
    & svg path {
      fill: $blue-lazure;
    }
  }
  &:disabled {
    & svg path {
      fill: $bw-gray3;
    }
  }
}

.select {
  width: 100%;
  & > :first-child > :first-child {
    @include listMainText;
    & > div {
      border: none;
      padding: 0 4px;
      height: 30px;
      color: $bw-black;
      &:hover {
        background-color: $accent-light-green;
      }
    }
  }
  &__disabled {
    cursor: not-allowed;
    & div {
      background-color: transparent;
    }
  }
}

.temporaryNum {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > input {
    @include listMainText;
    border: none;
    background-color: transparent;
    width: 100%;
  }
}

.total {
  position: sticky;
  right: 0;
  min-width: 60px;
  text-align: center;
  background-color: $accent-yellow;
  z-index: 2;

  &__white {
    background-color: $bw-white;
  }

  &__shadow::before {
    box-shadow: -13px 0 13px -15px inset;
    left: -15px;
  }
}

.iconQuestion {
  cursor: pointer;
  & > rect,
  path {
    fill: $blue-lazure;
  }
  & > circle {
    stroke: $blue-lazure;
  }
}
