@import "src/shared/assets/styles/main.scss";

.tabsContainer {
  width: 40vw;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.tabs {
  width: 80%;
  margin-right: -100px;
}

.historyBtn {
  display: flex;
  padding: 0;
  gap: 5px;
  align-items: center;
  & > p {
    @include mainText;
    margin: 0;
    color: $blue-lazure;
  }
}

.link {
  text-decoration: none;
}
