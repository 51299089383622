@import "src/shared/assets/styles/main.scss";

%inputStyles {
  background-color: $bw-white !important;
  border-color: $bw-gray3 !important;

  &:hover {
    border-color: $blue-azure;
  }
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.contentBlue {
  content: "";
  background: $blue-azure;
  transition: all 0.2s ease-in-out;
}

.contentGray {
  content: "";
  position: absolute;
  right: -40px;
  top: 50%;
  width: 40px;
  height: 1px;
  background: $bw-gray3;
  z-index: 0;
  transition: all 0.2s ease-in-out;
}

.form {
  @extend .flexColumn;
  padding-right: 30px;

  @media screen and (min-width: 1200px) {
    width: 900px !important;
  }
}

.disabled {
  pointer-events: none;
}

.subtitle {
  @include subtitle;
}

.containerForm {
  @extend .flexColumn;
  margin-top: 20px;
}

.confirmLabel {
  @include caption;
  margin-bottom: 12px;
  color: $bw-gray5;
  line-height: 14.16px;
}

.buttonAddNumber {
  display: flex;
  max-width: 168px;
  width: 100%;
  margin-left: -15px;
  padding: 10.5px 0 10.5px 16px;
  border: none;
  background-color: transparent;
  border-radius: 20px;
  transition: all 0.4s ease-in-out;

  &__text {
    @include inputPrompt;
    margin: 0;
    padding: 0;
    color: $blue-lazure;
    transition: all 0.4s ease-in-out;
  }

  &__icon {
    width: 24px;
  }

  p {
    transition: all 0.4s ease-in-out;

    &:hover,
    &:focus {
      text-shadow: 0 0 0.65px $blue-lazure, 0 0 0.65px $blue-lazure;
    }
  }

  &:active {
    background-color: $blue-white-blue;
  }
}

.confirmRowPhone {
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  list-style-type: none;

  & li {
    position: relative;
    transition: all 0.2s ease-in-out;

    &:nth-child(1) {
      @extend .flexColumn;
      align-items: center;
      max-width: 70px;
      width: 100%;
      height: 100%;
      padding: 12px 16px;
      border: 1px solid $bw-gray3;
      border-radius: 6px;
      margin-right: 30px;
    }

    &:nth-child(2) {
      @extend .flexColumn;
      max-width: 70px;
      width: 100%;
      height: inherit;
      border-radius: 6px;
      margin-right: 30px;

      & input {
        @extend %inputStyles;
      }
    }

    &:nth-child(3) {
      width: 100%;
      max-width: 300px;
      height: inherit;
      margin-right: 30px;
    }

    &:nth-child(4) {
      width: 100%;
      height: inherit;

      & input {
        @extend %inputStyles;
      }
    }

    &:nth-child(n + 5) {
      width: 100%;
      max-width: max-content;
      height: 100%;

      & > button {
        margin: 0 0 0 8px;
        height: 100%;
      }
    }
  }

  & li::after {
    @extend .contentGray;
  }

  & li:nth-child(n + 4)::after {
    content: none;
  }

  &:hover {
    cursor: pointer;

    .numBorder {
      border-color: $blue-azure;
    }

    & li input,
    & li input:disabled {
      border-color: $blue-azure !important;
    }

    & li:nth-child(1) {
      border: 1px solid $blue-azure;

      & input {
        border-color: $bw-gray3 !important;
      }
    }

    & li::after {
      @extend .contentBlue;
    }

    & li:nth-child(n + 4)::after {
      content: none;
    }
  }

  &_selected {
    .numBorder {
      border-color: $blue-lazure;
    }

    & li input,
    & li input:disabled,
    & li:nth-child(4) input {
      border-color: $blue-lazure !important;
    }

    & li:nth-child(1) {
      border: 1px solid $blue-lazure;

      & input {
        border-color: $bw-gray3 !important;
      }
    }

    & li::after {
      @extend .contentBlue;
      background: $blue-lazure;
    }
  }
}

.confirmRowAddPhone {
  display: flex;
  align-items: center;
  height: 72px;
  width: 100%;
  padding: 0;
  list-style-type: none;

  & li {
    position: relative;
    margin-right: 30px;
    margin-bottom: 5px;
    transition: all 0.2s ease-in-out;

    &:nth-child(1) {
      max-width: 100px;
      width: 100%;
      height: inherit;

      & > div > div > input {
        border-color: $blue-azure;
        background-color: white;
      }
    }

    &:nth-child(2) {
      max-width: 240px;
      width: 100%;
      height: inherit;

      & > div > div > input {
        border-color: $blue-azure;
        background-color: white;
      }
    }

    &:nth-child(3) {
      max-width: 743px;
      width: 100%;
      height: inherit;
      margin-right: 0;

      & > div > div > input {
        border-color: $blue-azure;
        background-color: white;
      }
    }

    &:nth-child(n + 4) {
      width: 100%;
      max-width: max-content;
      height: 100%;
      margin-right: 0;

      & > button {
        margin: 0 0 0 8px;
        height: 100%;
      }
    }
  }

  & li::after {
    @extend .contentGray;
  }

  &:hover {
    cursor: pointer;

    & input {
      border: 1px solid $blue-azure;
    }

    & li::after {
      @extend .contentBlue;
    }
  }

  &_selected {
    & input {
      border: 1px solid $blue-azure;
    }

    & li::after {
      @extend .contentBlue;
    }

    & li:nth-child(n + 3)::after {
      content: none;
    }
  }
}

.loading {
  @include listMainText;
}
