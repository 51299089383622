@import "src/shared/assets/styles/main.scss";

.row {
  @include cardRow;
}

.field {
  @include cardField;
}

.link {
  text-decoration: none;
  color: $blue-lazure;
  word-wrap: break-word;
}
