@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/base/colors.scss";

.tableTitle {
  @include subtitle;
  margin-bottom: 20px;
}

.container {
  min-width: 990px;
}
