@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";


.container {
  display: flex;
  position: relative;
  margin-left: -30px;
}

.stickyContainer {
  display: flex;
  align-items: center;
  column-gap: 20px;
  margin-bottom: 18px;
  padding-left: 30px;
  position: sticky;
  left: 0;
  z-index: 12;
}

.errorsBlock {
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 12px 20px;
  cursor: pointer;

  @each $name, $color in $colors_list {
    &_#{$name} {
      border-radius: 6px;
      @if ($name == "danger") {
        background-color: $accent-pastel-red;
      } @else if $name == "success" {
        background-color: $accent-light-green;
      } @else if $name == "warning" {
        background-color: $accent-beige;
      }
    }
  }
}

.errorText {
  @include caption;
  color: $bw-black;
}

.icon {
  &__danger {
    & path:first-of-type {
      stroke: $danger;
    }
    & path:last-of-type {
      fill: $danger;
    }
  }
  &__expand {
    transition-duration: 0.2s;
    transform: rotate(270deg);
    &_opened {
      transform: rotate(90deg);
      -webkit-transition-duration: 0.2s;
      transition-duration: 0.2s;
    }
  }
}

.expandIcon {
  @each $name, $color in $colors_list {
    &_#{$name} path {
      stroke: $color;
    }
  }
}

.duplicateBlock {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
