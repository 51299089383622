.savedFilters {
  width: 100%;
  display: flex;
  gap: 60px;
  margin-top: 20px;
  margin-bottom: -30px;

  &__group {
    display: flex;
    gap: 20px;
    margin: 20px 0;
  }
}
