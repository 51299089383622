@import "src/shared/assets/styles/main.scss";

.noCommentsContainer {
  margin-bottom: 20px;
}

.emptyAlert {
  margin-bottom: 20px;
}

.link {
  text-decoration: none;
}

@media print {
  .leaveCommentBtn {
    display: none;
  }
}
