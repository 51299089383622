@import "src/shared/assets/styles/main.scss";

.form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin-bottom: 80px;
  width: 40%;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  row-gap: 9px;
}

.switchAddMore {
  margin-top: 20px;
  @include switch;
}

.oneField {
  column-gap: none;
  & > div:first-child {
    width: 100%;
    &::after {
      content: none !important;
    }
  }
}

.doubleDateField {
  display: flex;
  column-gap: 40px;
  & > div:first-child,
  & > div:nth-child(2) {
    width: 25vw;
  }

  & > div:first-child {
    &::after {
      content: "";
      position: absolute;
      right: -40px;
      top: 50%;
      width: 40px;
      height: 1px;
      background: $bw-gray3;
      z-index: 0;
      transition: all 0.4s ease-in-out;
    }
  }
}
