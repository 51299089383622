@import "~/src/shared/assets/styles/main.scss";

.mainBlock {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  column-gap: 30px;

  @media screen and (max-width: 1100px) {
    column-gap: 8px;
  }
}

.columnBlock {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  & > p {
    margin: 0;
    @include caption;
  }
}

.searchBlock {
  & input {
    width: 800px;

    @media screen and (max-width: 1800px) {
      width: 400px;
    }

    @media screen and (max-width: 1300px) {
      width: 300px;
    }
  }
}

.select {
  & > div > div {
    height: 40px;
    width: 500px;

    @media screen and (max-width: 1800px) {
      width: 350px;
    }

    @media screen and (max-width: 1300px) {
      width: 250px;
    }
  }
}
