@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "./cellsMixins.scss";

.isLoading {
  pointer-events: none;
}

.table {
  margin-left: -30px;
}

.header {
  & th {
    padding: 12px 8px;
    outline: 1px solid $bw-gray3;
    @include subtitle;
    text-align: right;
    &:last-child {
      border-right: none;
    }

    &:first-child {
      @extend %firstChild;
    }

    &:nth-child(2) {
      @extend %secondChild;
    }

    &:nth-child(3) {
      @extend %tableChild;
      left: left(3);
      z-index: 5;
      min-width: $thirdCell + $fourthCell + $fifthCell + $sixthCell;
    }
  }
}

.colspan {
  &::after {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
    color: $bw-gray5;
  }
  &__shadow::after {
    box-shadow: 13px 0 13px -15px inset;
    right: -15px;
  }
}
