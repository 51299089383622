@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.employeeSelect {
  min-width: 30vw;
  width: fit-content;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.subtitle {
  @include subtitle;
}

.subtitle:first-child {
  margin: 0 0 20px;
}

.subtitle:last-child {
  margin: 40px 0 0;
}
