@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

.inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.sumField {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  & p {
    margin: 0;
    @include caption;
    color: $bw-gray6;
  }
}
