@import "shared/assets/styles/main.scss";

.size {
  &_input {
    padding-left: 0 !important;
    margin-top: -22px !important;
    margin-right: 0 !important;
    gap: 2px !important;
  }

  &_row {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: baseline !important;
    gap: 2px;
    margin: -0.5rem 0;

    & > :first-child {
      height: 100%;
    }

    &__prop {
      display: grid;
      grid-template-rows: 27px 32px;

      & > :first-child {
        background-color: $accent-light-green;
        color: $bw-gray5;
        text-align: center;
        width: 68px;
        padding: 5.5px 0;
      }

      & > :last-child {
        display: flex;
        align-items: center;
        margin: 0 auto;
      }

      &_input {
        width: 68px;
        margin-top: -23px;

        & input {
          padding: 15.5px 0px !important;
          box-sizing: border-box;
          text-align: center;
          caret-color: $blue-lazure !important;
          &:disabled {
            background-color: $bw-gray2 !important;
            &:focus {
              border: none !important;
            }
            border: none !important;
          }
        }
        & > :last-child {
          display: block;
          padding-bottom: 5px;

          // отображение ошибки инпута
          & > :first-child > :first-child > :nth-child(3) {
            display: block;
            position: absolute;
            bottom: -15px;
            left: -50px;
          }
        }
      }
    }

    &_big {
      & > :first-child {
        & > :first-child {
          width: 160px;
          padding: 5.5px;
        }
      }
    }
  }

  &_column {
    height: 250px;
    display: flex;
    flex-direction: column;
    padding: 0;
    margin-top: 0 !important;
    gap: 0 !important;

    &__prop {
      display: grid;
      grid-template-columns: 1fr 4fr;
      gap: 8px;
      align-items: center;
      width: 100%;
      height: 50px;
      border-bottom: 1px solid $bw-gray3;

      & > :first-child {
        background-color: $accent-light-green;
        color: $bw-gray5;
        width: 80px;
        height: 50px;
        padding: 17px 9px;
        border-right: 1px solid $bw-gray3;
        border-bottom: 1px solid $bw-gray3;
      }

      &_input {
        & > :last-child {
          margin-left: -8px;
          margin-top: -1px;

          & > :first-child > :first-child > :nth-child(3) {
            position: absolute;
            bottom: 2px;
            z-index: 1;
          }

          & input {
            min-width: 145px;
            caret-color: $blue-lazure !important;
            &:disabled {
              border: none !important;

              &:hover {
                border: none !important;
              }

              .size_column__prop {
                border: none !important;
              }
            }
          }
        }

        .switch {
          cursor: pointer;
          user-select: none;
          gap: 8px;
          justify-content: flex-start;
        }
      }
    }

    & > :last-child {
      border-bottom: none;
      & > :first-child {
        border-bottom: none;
      }
    }

    &_big {
      height: 300px;

      .size_column__prop {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &__formula {
    background-color: $bw-gray2;
  }
}

.iconDash path {
  fill: $accent-orange;
}

.switch {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
