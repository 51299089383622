@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";

.container {
  width: 770px;
  @include mainText;
}

.cols {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.helpingInfoText {
  @include listMainText;
  color: $bw-gray6;
  margin: 0;
}

.optionsListBlockTitle {
  @include subtitle;
  font-size: 14px;
  margin-bottom: 12px;
}

.titleBlock {
  margin-bottom: 20px;
  & > p {
    font-size: 14px;
    margin: 0 0 12px 0;
  }
}

.titleParam {
  margin-bottom: 8px;
}

.paramsBlock {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
