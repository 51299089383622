@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.container {
  position: relative;
  height: max-content;

  &:before {
    content: "";
    border-left: 2px dashed $bw-gray3;
    position: absolute;
    top: 50px;
    left: 19px;
    height: calc(100% - 50px);
  }
}

.header {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  background-color: $bw-white;
  transform: translate(0, 0px);
  transition: all 0.4s ease;
}

.stickyHeader {
  @extend .header;
  position: -webkit-sticky;
  position: sticky;
}

.fieldButton {
  display: flex;
  column-gap: 8px;
  align-items: center;
  padding-bottom: 20px;
  cursor: pointer;
  min-width: 26vw;
  padding: 12px 8px;
}

.fieldButtonClosed {
  padding-bottom: 0;
  & svg {
    transform: rotate(180deg);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
  }
  .gradientLine {
    border-image-source: linear-gradient(to right, $bw-gray6, $bw-white);
  }
}

.fieldButtonOpened {
  & svg {
    transform: rotate(360deg);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
  }
}

.titleText {
  @include zero;
}

.titleTextSize {
  @include subtitle;
  font-size: 20px;
}

.gradientLine {
  margin-top: 3px;
  flex-grow: 1;
  border-top: solid;
  border-image-slice: 1;
  border-width: 2px;
  border-top-left-radius: 10px;
  border-image-source: linear-gradient(to right, $blue-lazure, $bw-white);
}

.children {
  padding-left: 19px;
  margin-left: 20px;
  padding-top: 5px;
  position: relative;
  height: 100%;
  & div[data-loading="true"] {
    position: absolute;
    display: block;
    left: 0;
  }
}

.shadowCover {
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  height: 20px;
  background-color: $bw-white;
  margin: 0 -60px;
  padding: 0 60px;
}

.shadow {
  position: sticky;
  top: 260px;
  left: 0;
  right: 0;
  height: 1px;
  margin: 0 -30px;
  padding: 0 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
}
