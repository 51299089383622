@import "src/shared/assets/styles/main.scss";

.page {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.title,
.infoTitle,
.widgetTitle {
  @include zero;
  @include subtitle;
}

.infoTitle,
.widgetTitle {
  padding-bottom: 16px;
}

.infoTitle {
  border-bottom: 1px solid $bw-gray2;
}

.main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.colInfo,
.colWidgets {
  display: flex;
  flex-direction: column;
}

.colInfo {
  width: 60%;
}

.colWidgets {
  row-gap: 40px;
}
