@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";
@import "shared/assets/styles/base/colors.scss";

.tabsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 4px;
  margin-bottom: 20px;
}

.tabBlock {
  border-radius: 6px;
  padding: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  width: 16%;
  text-align: center;
  cursor: pointer;
  & p {
    margin: 0;
    @include listMainText;
    color: $bw-gray7;
    letter-spacing: 1px;
  }

  &__count {
    display: flex;
    justify-content: center;
    border-radius: 20px;
    padding: 3px;
    background: rgba(255, 255, 255, 0.7);

    & p {
      @include zero;
      @include subtitle2;
      color: $bw-gray7;
    }
  }

  &_empty {
    @extend .tabBlock;
    opacity: 0.4;
    cursor: not-allowed;
  }

  &_selected {
    border: 2px solid $bw-gray7;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  }
}

.color {
  @each $name, $color in $colors-list {
    &_#{$name} {
      background-color: $color;

      $event_color_level: (0, 1, 2, 3);
      @each $level, $color_level in $event_color_level {
        &_#{$level} {
          background-color: color-level($name, $level);
        }
      }
    }
  }
}
