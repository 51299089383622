@import "src/shared/assets/styles/main.scss";

.table {
  margin-top: 32px;
}

.title {
  @include caption;
  cursor: pointer;
  padding: 12px 0 !important;
  border: 1px solid $bw-gray3;
  vertical-align: middle;
  text-align: center;
}

.titleQuantity {
  @extend .title;
  text-align: left;
  padding: 12px 12px 12px 4px !important;
  & p:last-child {
    @include subtitle;
  }
}

.titleColumn {
  color: $bw-black;
}

.factsColumn {
  @include subtitle;
}

.greenTitle {
  background-color: $accent-light-green !important;
}

.blueTitle {
  background-color: $blue-white-blue !important;
}

.beigeTitle {
  background-color: $accent-beige !important;
}

.highlightedTitle {
  background-color: $blue-white-blue !important;
}

.maxPremiumTitleCell {
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
  }
  &:hover {
    background-color: $blue-white-blue !important;
  }
}

.tableBody {
  @include mainText;
  & > tr {
    cursor: pointer;
    vertical-align: middle;
    & > td {
      padding: 12px 12px;
      border: 1px solid $bw-gray3;
      text-align: right;
    }
    & > td p {
      line-height: 22px;
      margin: 0;
    }
    &:hover {
      background-color: $bw-gray1;
    }
  }
}

.highlightedColumn {
  background-color: $bw-gray1 !important;
  position: relative;
  & input {
    background-color: $bw-gray1 !important;
  }
}

.backroundBlack {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05) !important;
  z-index: 2;
}

.fioColumn {
  text-align: center !important;
  color: $blue-lazure;
}

.numberColumn {
  text-align: center !important;
}

.workshiftColumnGreen {
  background-color: $accent-lime !important;
}

.workshiftColumnRed {
  background-color: $accent-pastel-red !important;
  background-blend-mode: hard-light !important;
}

.workshiftColumnYellow {
  background-color: $accent-yellow !important;
  background-blend-mode: hard-light !important;
}

.commentColumn {
  padding: 0 !important;
  & input {
    cursor: text !important;
    height: 60px !important;
    caret-color: $blue-lazure;
    border-radius: 0px;
    padding: 0 12px !important;
    border: none !important;
  }
}

.masterColumn {
  & input {
    text-align: right !important;
  }
}
