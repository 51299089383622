.barchart {
  position: relative;
  max-width: min-content;
  left: -35px;
}

.containerMont {
  cursor: pointer;
}

.containerCell {
  cursor: pointer;
}
