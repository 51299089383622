@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.button {
  padding: 0 12px;
  cursor: pointer;
  @include zero;
  @include listMainText;
  &:hover {
    text-shadow: 0 0 0.1px $bw-gray6, 0 0 0.1px $bw-gray6;
  }
}

.buttonActive {
  color: $blue-lazure;

  &:hover {
    text-shadow: 0 0 0.1px $blue-lazure, 0 0 0.1px $blue-lazure;
  }
}
