@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";

.wrapper {
  width: 100;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 50px;
}

.cardContainer {
  position: relative;
  display: flex;
  margin-bottom: 31px;
  width: fit-content;
}

.cards {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-template-rows: 61px 61px;
  row-gap: 12px;
  column-gap: 12px;
  margin-right: 12px;
}

.itr {
  display: flex;
  gap: 12px;
}

.itrColumn {
  @extend .itr;
  flex-direction: column;
}

.button {
  @include listMainText;
  display: flex;
  align-items: center;
  gap: 4px;
  width: max-content;
  padding: 4px 12px 4px 4px;
  color: $blue-lazure;
  background-color: transparent;
  border: none;
  position: absolute;
  bottom: 23px;
  left: 12px;

  &:hover {
    background-color: $blue-light-blue;
    border-radius: 16px;
  }
}

.buttonActive {
  background-color: $blue-light-blue;
  border-radius: 16px;
}
