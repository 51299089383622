@import "~/src/shared/assets/styles/main.scss";

.button {
  text-decoration: none;
  text-align: left;
  cursor: pointer;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 20px;

  background: none;
  border: none;
  border-radius: 6px;
  transition: all 0.4s ease;

  & > * {
    margin-bottom: 0;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
      0px 2px 8px rgba(0, 140, 255, 0.1);
    transition: all 0.4s ease;
  }
}

.selectedBtn {
  background-color: $bw-gray7;

  .text {
    color: $bw-white;
  }
}

.text {
  @include mainText;
  color: $bw-black;
}

.noText {
  display: none;
}

.label {
  @include caption;
  color: $blue-lazure;
}
