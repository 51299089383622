@import "../staffTablePlaceholders.scss";
@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";

.tfoot {
  position: sticky !important;
  bottom: -2px;
  z-index: 3;

  & tr {
    @include subtitle2;
    background-color: $bw-white !important;

    & > :first-child {
      @include subtitle;
      width: 30px;
      & div {
        width: 18.45px;
      }
    }
    & td {
      padding: 18px 4px !important;
    }
  }

  & tr {
    @extend %numAndStaffCols;
    @extend %totalCols;
  }
}

.shadow {
  box-shadow: 0 -5px 10px -10px $bw-gray6;
  transition: all 0.2s ease;
}
