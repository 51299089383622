@import "/src/shared/assets/styles/base/colors.scss";

.staff {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.candidate {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 24px;
  align-items: flex-start;
  gap: 10px;
}

.link {
  text-decoration: none;
  color: $blue-lazure;
  font-weight: 400;
}

.icon {
  width: 24px;
}

.iconOpenedSelect {
  transition: all 0.4s ease;
  transform: rotate(180deg);
  width: 24px;

  & > path {
    fill: $blue-lazure !important;
    stroke: $blue-lazure !important;
  }
}

.iconCLosedSelect {
  outline: none;
  transition: all 0.2s ease;
  transform: rotate(0deg);
  width: 24px;

  & > path {
    fill: $bw-gray5;
    stroke: $bw-gray5;
  }
}

.fixedIcon {
  position: absolute;
  top: -16px;
  right: 0;
}

.event {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.company {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}
