// переменные ширины липких ячеек для облегчения вычислений
//если вдруг изменится ширина в таблице

$firstCell: 40px;
$secondCell: 88px;
$thirdCell: 120px;
$fourthCell: 137px;
$fifthCell: 137px;
$sixthCell: 137px;

$cellsWidthsList: (
  1: 0,
  2: $firstCell,
  3: $firstCell + $secondCell,
  4: $firstCell + $secondCell + $thirdCell,
  5: $firstCell + $secondCell + $thirdCell + $fourthCell,
  6: $firstCell + $secondCell + $thirdCell + $fourthCell + $fifthCell
);

// функция сложения ширины всех ячеек
@function left($childNum) {
  @return map-get($cellsWidthsList, $childNum);
}

%tableChild {
  position: sticky;
  background-color: $bw-white;
}

%firstChild {
  @extend %tableChild;
  left: left(1);
  z-index: 7;
  min-width: $firstCell;
  border-left: none;
}

%secondChild {
  @extend %tableChild;
  left: left(2);
  z-index: 6;
  min-width: $secondCell;
}
