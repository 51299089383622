@import "shared/assets/styles/base/colors.scss";

.windowContainer {
  padding: 30px;
  position: relative;
  min-width: 100%;
}

::-webkit-scrollbar {
  background-color: transparent;
}

.scrollbars {
  & > :nth-child(2),
  & > :last-child {
    z-index: 10;
  }

  & > :nth-child(2) {
    height: 35px !important;
  }

  & > :last-child {
    width: 22px !important;

    & > div {
      width: 7px !important;
      margin-left: 15px !important;
      &:hover {
        width: 13px !important;
        margin-left: 9px !important;
        transition: all 0.5s ease;
      }
    }
  }
}

.thumbHorizontal {
  cursor: pointer;
  background-color: $blue-azure;
  margin-top: 15px;
}
