@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";

.staff::after,
.total::before {
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  width: 15px;
  color: $bw-gray5;
}

.number {
  position: sticky;
  left: 0;
  z-index: 2;
  min-width: 60px;
  vertical-align: middle;
  cursor: grab !important;
  background-color: $accent-yellow;

  &__white {
    background-color: $bw-white;
    z-index: 3;
    cursor: pointer !important;
  }
  img {
    display: none;
  }
  &:hover {
    img {
      display: block;
    }
  }
}

.staff {
  position: sticky;
  left: 37px;
  z-index: 2;
  height: 40px;
  text-align: start;
  background-color: $accent-yellow;
  &__shadow::after {
    box-shadow: 13px 0 13px -15px inset;
    right: -15px;
  }

  &__tN {
    color: $bw-gray5;
    & > mark {
      background-color: $yellow !important;
    }
  }

  &__fio {
    text-align: start;
    margin-bottom: 6px;
  }

  &__row {
    max-width: 151px;
    width: 151px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
  }

  &__basket {
    border: none;
    background-color: transparent;
    &_img {
      fill: $bw-gray4;
    }
  }
}

.tooltipTitle {
  @include caption;
}

.rowSelected {
  background-color: $accent-peach;
}

.checkbox {
  margin-right: 10px;
}

.workHours {
  position: relative;
  min-width: 300px;
  cursor: pointer;
  text-align: center;
  z-index: 1;

  &__hover:hover {
    box-shadow: inset 0px 0px 0px 1px $blue-azure;
  }

  &__white {
    background-color: $bw-white;
  }

  &__focused {
    box-shadow: inset 0px 0px 0px 1px $blue-lazure;
  }

  &__nonActive {
    background-color: $bw-gray1;
  }
}

.hiddenColumn {
  display: none;
}

.total {
  position: sticky;
  right: 0;
  min-width: 60px;
  text-align: center;
  background-color: $accent-yellow;
  z-index: 2;

  &__shadow::before {
    box-shadow: -13px 0 13px -15px inset;
    left: -15px;
  }

  &__rowSelected {
    background-color: $accent-peach;
  }

  &__num {
    @include mainTextFont;
    width: 44px;
    height: 27px;
    border-radius: 4px;
    padding: 4px;

    &_red {
      color: $bw-white;
      background-color: $accent-red;
    }

    &_green {
      color: $accent-green;
    }
  }
}
