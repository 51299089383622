@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "../stickyMixin.scss";

.container {
  @extend %sticky;
  top: 0px;
  z-index: 2;
  margin: -30px -30px 0 -30px;
  padding: 30px 30px 0 30px;
}

.mainInfo {
  width: 100%;
  height: 100%;
  background-color: $bw-white;
  border-bottom: 1px solid $bw-gray3;
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  padding-bottom: 12px;
  margin-bottom: 20px;
}

.textInfo {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  gap: 12px;
}

.photo {
  width: 53px;
  height: 53px;
  transition: all 0.4s ease;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }
}

.avatar {
  background-color: $bw-gray2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  height: 95%;
}

.TNBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px;
  border: 1px solid $bw-gray4;
  padding: 4px 12px;
  border-radius: 4px;
  gap: 10px;
  width: max-content;
}

.title,
.value,
.fio {
  @include title;
  @include zero;
}

.title {
  color: $bw-gray6;
  font-weight: 400;
  line-height: 23.6px;
}

.value {
  color: $bw-gray7;
}

.fioBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.position {
  @include mainText;
  @include zero;
}
