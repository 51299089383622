@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/base/colors.scss";

.firstCol {
  padding-left: 20px !important;
}

.loading {
  margin: 0;
  @include listMainText;
}
