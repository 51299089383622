@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";

.container {
  width: 50vw;
  @include mainText;
}

.moveItemBlock {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid $bw-gray3;
  margin-bottom: 12px;
}

.optionList {
  height: 450px !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.multiSelectHeader {
  display: flex;
  justify-content: space-between;
}

.selectedCount {
  margin: 0;
}

.clearBtn {
  color: $accent-coral !important;
}

.optionsListBlockTitle {
  @include subtitle;
  font-size: 14px;
  margin-bottom: 12px;
}

.field {
  position: relative;
  cursor: pointer;
  &:hover {
    .iconAdd {
      & rect {
        fill: $blue-lazure;
      }
      & circle {
        fill: $bw-white;
      }
    }
    .labelSmallRequired {
      color: $blue-azure !important;
    }
  }
}

.fieldFocused {
  @extend .field;
  .selectContainer {
    border-color: $blue-lazure;
  }
}

.fieldError {
  margin-bottom: 15px;

  .selectContainer {
    border-color: $accent-coral;
  }
}

.selectContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 19px 12px 15px;
  border: 1px solid $bw-gray3;
  border-radius: 6px;

  &:hover {
    background-color: $blue-white-blue;
    border-color: $blue-azure;
  }
}

.containerModalOpen {
  padding: 21px 19px 12px 20px;
}

.label {
  @include inputPrompt;
  color: $bw-gray6 !important;
  margin: 0;
  width: 100%;
}

.labelRequired {
  position: relative;
  padding-left: 14px;
  @include inputPrompt;
  @include before-dot(2px, 6px);
}

.labelRequiredError {
  @include before-dot(2px, 6px, relative, $accent-coral);
}

.labelSmall {
  position: absolute;
  top: 12px;
  @include caption;
  color: $bw-gray6 !important;
}

.labelSmallRequired {
  padding-left: 14px;
  @include before-dot(2px, 4px, absolute);
}

.blueLabel {
  color: $blue-azure !important;
}

.fieldItems {
  width: 100%;
  padding: 0 10px 0 0;
}

.fieldItemsBox {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.fieldItemsWithLabel {
  margin-top: 10px;
}

.chips {
  &:hover {
    background: $blue-light-blue;
    transition: none;
    & img {
      padding: 0;
      filter: brightness(0) saturate(100%);
    }
  }
}

.buttons {
  min-height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.iconAdd {
  outline: none;
}

.iconAddActive {
  & rect {
    fill: $blue-lazure;
  }
  & circle {
    fill: $bw-gray2;
  }
}

.scrollboard {
  right: 0;
  margin-top: 3px;
  display: block;
  min-width: 300px;
  max-width: 400px;
  z-index: 99999;

  // TODO: вынести стили в класс для ItemsScrollBoard
  & > :first-child {
    border-color: $blue-lazure;
    & > :last-child {
      & > :first-child {
        height: max-content;
        max-height: 300px !important;
      }
    }
  }
}

.errorText {
  position: absolute;
  bottom: -18px;
  left: 9px;
  @include caption;
  color: $accent-coral;
}
