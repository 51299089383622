@import "~/src/shared/assets/styles/main.scss";

.radio {
  position: relative;
  display: grid;
  place-content: center;
  margin: 0;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  width: 18px;
  height: 18px;
  border: 1px solid $bw-gray4;
  border-radius: 50%;
  transform-style: preserve-3d;

  cursor: pointer;

  &:not(:checked):active {
    background: $bw-gray1;

    &::before {
      @include beforeElemStyle;
      background: rgba(197, 198, 198, 0.15);
    }
  }

  &:checked {
    border: 1px solid $blue-light-blue;
    background: $blue-light-blue;

    &::after {
      content: "";
      width: 8px;
      height: 8px;
      background: $blue-lazure;
      border-radius: 50%;
    }

    &:active {
      &::before {
        @include beforeElemStyle;
        background: rgba(206, 233, 255, 0.35);
      }
    }
  }

  &:checked:disabled {
    border: 1px solid $bw-gray2;
    background: $bw-gray2;

    &::after {
      content: "";
      width: 8px;
      height: 8px;
      background: $bw-gray4;
      border-radius: 50%;
    }
  }

  &:not(:checked):disabled {
    border: 1px solid $bw-gray3;
    background: $bw-gray2;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }
}
