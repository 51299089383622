@import "src/shared/assets/styles/main.scss";

.windowContainer {
  @include backgroundModal;
}

.window {
  position: absolute;
  z-index: 2;
  max-height: 70vh;
}

.yearButtonsWrapper {
  display: flex;
  column-gap: 10px;
}

.tableWrapper {
  margin-bottom: 40px;
}
