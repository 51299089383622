@import "src/shared/assets/styles/main.scss";

.flexColumn {
  display: flex;
  flex-direction: column;
}

.contentBlue {
  content: "";
  background: $blue-azure;
  transition: all 0.2s ease-in-out;
}

.contentGray {
  content: "";
  position: absolute;
  right: -41px;
  top: 50%;
  width: 41px;
  height: 1px;
  background: $bw-gray2;
  z-index: 0;
  transition: all 0.2s ease-in-out;
}

.subtitle {
  @include subtitle;
}

.containerForm {
  @extend .flexColumn;
  margin-top: 20px;
}

.buttonAddNumber {
  display: flex;
  max-width: 168px;
  width: 100%;
  margin-left: -15px;
  padding: 10.5px 0 10.5px 16px;
  border: none;
  background-color: transparent;
  border-radius: 20px;
  transition: all 0.4s ease-in-out;

  &__text {
    @include inputPrompt;
    margin: 0;
    padding: 0;
    color: $blue-lazure;
    transition: all 0.4s ease-in-out;
  }

  &__icon {
    width: 24px;
  }

  p {
    transition: all 0.4s ease-in-out;

    &:hover,
    &:focus {
      text-shadow: 0 0 0.65px $blue-lazure, 0 0 0.65px $blue-lazure;
    }
  }

  &:active {
    background-color: $blue-white-blue;
  }
}

.form {
  @extend .flexColumn;
  padding-right: 30px;

  @media screen and (max-width: 1200px) {
    width: 730px !important;
  }

  @media screen and (min-width: 1200px) {
    width: 830px !important;
  }
}

.disabled {
  pointer-events: none;
}

.confirmRowEmail {
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  list-style-type: none;

  & li {
    position: relative;
    transition: all 0.2s ease-in-out;

    & input:disabled {
      background-color: white;
    }
  }

  &__confirm {
    @extend .flexColumn;
    align-items: center;
    height: 100%;
    padding: 12px 16px;
    border: 1px solid $bw-gray2;
    border-radius: 6px;
    margin-right: 40px;

    & label {
      @include caption;
      margin-bottom: 12px;
      color: $bw-gray5;
      line-height: 14.16px;
      white-space: nowrap;
    }

    &::after {
      @extend .contentGray;
    }
  }

  &__order {
    @extend .flexColumn;
    max-width: 100px;
    width: 100%;
    height: inherit;
    border-radius: 6px;
    margin-right: 40px;

    &::after {
      @extend .contentGray;
    }
  }

  &__email {
    width: 100%;
    height: inherit;
  }

  &:hover {
    cursor: pointer;

    .confirmRowEmailBorder {
      border-color: $blue-azure;
    }

    .confirmRowEmail__confirm {
      border: 1px solid $blue-azure;
    }

    & input {
      border: 1px solid $blue-azure;
    }

    & li::after {
      @extend .contentBlue;
    }
  }

  &_selected {
    .confirmRowEmail__confirm {
      border: 1px solid $blue-azure;
    }
    .confirmRowEmailBorder {
      border-color: $blue-azure;
    }

    & input,
    & input:disabled {
      border: 1px solid $blue-azure;
    }

    & li::after {
      @extend .contentBlue;
    }
  }
}

.loading {
  @include listMainText;
}
