@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";

.birthday {
  display: flex;
  align-items: center;
  gap: 20px;
}

.age {
  color: $bw-gray6;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.container {
  width: 100%;
  max-width: 976px;
  @include mainText;
}

.title {
  @include subtitle;
  text-align: left;
  padding-bottom: 12px;
  border-bottom: 1px solid $bw-gray3;
}
