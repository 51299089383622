@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";

.main {
  position: absolute;
  z-index: 3;
  top: 50px;
  border: 1px solid $blue-azure;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);
  border-radius: 8px;
  width: 18.5vw;
  min-height: 400px;
}

.loading {
  position: relative;
  & div[data-loading="true"] {
    position: absolute;
    display: block;
    left: 0;

    & > div {
      margin-top: 180px;
    }
  }
}

.list {
  height: 400px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 20px 16px;
}

.item {
  border-bottom: 1px solid $bw-gray3;
  padding: 4px 0;
  margin-bottom: 4px;
  display: flex;
  gap: 12px;
  cursor: pointer;
  @include zero;
  @include listMainText;
  padding: 8px 16px;
  &:hover {
    color: $blue-lazure;
  }
}

.loadingText {
  margin-left: 26px;
  padding-bottom: 15px;
  @include listMainText;
}
