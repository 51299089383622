@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/base/colors.scss";

.searchWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 240px;
  transition: background 0.2s ease;

  &:hover .search {
    background: $blue-white-blue;
  }
}

.fixWidth {
  width: 400px;
}

.iconSearch {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  & path,
  & circle {
    stroke: $bw-gray6;
    transition: stroke 0.2s ease;
  }
}

.iconSearchActive {
  & path,
  & circle {
    stroke: $bw-black;
    transition: stroke 0.2s ease;
  }
}

.search {
  @include inputPrompt;
  height: 40px;
  width: 100%;
  padding: 5px 35px;
  border-radius: 8px;
  border: 1px solid $bw-gray3;
  color: $bw-black;
  cursor: pointer;
  text-overflow: ellipsis !important;
  caret-color: $blue-lazure;
  box-sizing: border-box;
  transition: border-color 0.2s ease, padding-right 0.2s ease,
    background 0.2s ease;

  &:focus,
  &:visited {
    outline: none;
    border-color: $blue-lazure;
  }

  &.inputError {
    border-color: $accent-red;
  }
}

.searchWithButtons {
  padding-right: 90px;
}

.textAreaNoResize {
  min-height: 40px;
  padding-top: 9px;
  padding-bottom: 9px;
  resize: none;
  overflow: hidden;
}

.btnsGroup {
  position: absolute;
  right: 10px;
  top: 0;
  width: max-content;
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: 4px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.btnsGroupVisible {
  opacity: 1;
  visibility: visible;
}

.findBtn {
  cursor: not-allowed;
  background: none;
  border: none;
  font-size: 16px;
  color: $bw-gray6;
  transition: color 0.2s ease;

  &:not(.disabled) {
    color: $blue-lazure;
    cursor: pointer;
  }
}

.closeBtn {
  cursor: pointer;
  transition: fill 0.2s ease;

  &:hover {
    & path {
      fill: $bw-gray6;
    }
  }
}
