@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";

.form {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  position: relative;
  padding-top: 30px;
  margin-bottom: 80px;
  width: 100%;

  & > div {
    @media screen and (max-width: 1500px) {
      width: 45vw;
    }

    @media screen and (min-width: 1500px) {
      width: 40vw;
    }

    @media screen and (min-width: 1900px) {
      width: 800px;
    }
  }
}

.containerCard {
  display: grid;
  gap: 10px;
  position: absolute;
  overflow-y: auto;
  left: calc(100% + 20px);
  top: 10px;
  padding: 20px;
  margin-left: 9px;
}

.company {
  @media screen and (max-width: 1500px) {
    min-width: 45vw;
  }

  @media screen and (min-width: 1500px) {
    min-width: 40vw;
  }

  @media screen and (min-width: 1900px) {
    min-width: 800px;
  }
  width: fit-content !important;
}

.row {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  column-gap: 20px;
  margin: 0;

  & > div:nth-child(1),
  & > div:nth-child(2) {
    flex: 1 0 calc(50% - 10px);
  }
}

.cancelBtn {
  margin-top: -5px;
}

.switches {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

.switch {
  @include switch;
}

.numberFormatInputs {
  display: flex;
  width: 100%;
  column-gap: 10px;
  & > div:first-child {
    flex: 1 0 auto;
  }
  & > div:nth-child(2) {
    flex: 0 1 auto;
  }
}

.radioBtn {
  @include caption;
  display: flex;
  align-items: center;
  column-gap: 30px;
  padding-left: 12px;
  color: $bw-black;
  margin-bottom: 10px;

  & label {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
  }
}

.convictionItem {
  display: flex;
  column-gap: 10px;
  margin-bottom: 20px;
}

.convictionInputs {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
}

.title {
  margin: 0;
  @include subtitle2;
  color: $bw-black;
}

.error {
  position: relative;
  width: 100%;

  & div[data-loading="true"] {
    position: absolute;
    left: 0;
    & > div {
      & > :first-child {
        display: none;
      }
    }
  }

  &_withLink {
    & > :first-child > :last-child > :first-child {
      padding: 8px 0;
      margin: 0 16px !important;
    }
  }
}

.snils {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.btnsBlock {
  display: flex;
  gap: 10px;
}

.tooltip {
  position: relative;
  @include tooltip("top-start", $accent-coral, $bw-white, $accent-coral);
}
