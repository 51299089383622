@import "~/src/shared/assets/styles/main.scss";

.filterHeaderContainer {
  transform: translate3d(0, 0, 0);
  will-change: transform, width;
  position: fixed;
  top: 49px;
  padding: 12px 30px;
  margin-left: -33px;
  background-color: $bw-white;
  z-index: 5;
  border-bottom: 1px solid white;

  &_withOpenedMenu {
    width: calc(100% - #{$openedAsideMenu});
  }

  &_withClosedMenu {
    width: calc(100% - #{$closedAsideMenu});
  }
}

.filterHeader {
  display: flex;
  justify-content: space-between;
  min-height: 40px;
}

.leftSideRow {
  display: flex;
  align-items: center;
  column-gap: 25px;
  @include mainText;
}

.searchInputBlock {
  display: flex;
  column-gap: 3px;
  align-items: center;

  &_fixedWidth {
    width: 355px;
  }
}

.iconQuestion {
  cursor: pointer;

  & > rect,
  path {
    fill: $blue-lazure;
  }

  & > circle {
    stroke: $blue-lazure;
  }
}

.dropOption {
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 3px;
  position: relative;
  user-select: none;
}

.selectedDropOption {
  color: $blue-lazure;
}

.diffCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: $accent-coral;
  position: absolute;
  top: -3px;
  left: -5px;
}

.defaultPosition {
  transform: rotate(0deg);
  transition: all 0.4s ease;
}

.transformedPosition {
  transform: rotate(180deg);
  transition: all 0.4s ease;
}

.rightSideRow {
  @extend .leftSideRow;
  position: relative;
  column-gap: 8px;
}

.formatForm {
  @include listMainText;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 50px;
  right: 0;
  border: 1px solid $blue-lazure;
  border-radius: 6px;
  background-color: $bw-white;
  padding: 12px;
  width: 190px;
  height: 88px;
  z-index: 10;

  @media screen and (max-width: 1200px) {
    right: 60px;
  }
}

.formatFormBtn {
  border: none;
  border-radius: 16px;
  background-color: transparent;
  width: 163px;
  text-align: left;

  &:hover {
    background-color: $blue-white-blue;
  }
}

.btnColSettings {
  & svg {
    width: 18px;
    height: 18px;
  }
}

.btnActive {
  background-color: $bw-gray7;
}

.iconExport {
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  fill: $blue-lazure;

  &_active {
    fill: $bw-white;
  }
}

.filtersBlock {
  position: relative;
  display: grid;
  grid-template-columns: auto 40px;
  min-height: 40px;
  // 2 стиля ниже нужны, чтобы не размывались кнопки сохр.фильтров
  // из-за применения свойства transform
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;

  &_opened {
    animation: opened 0.2s forwards;
    height: 100%;
    padding-top: 12px;
  }

  &_closed {
    animation: closed 0.2s forwards;
    min-height: 0;
    height: 0;
    padding: 0;
  }

  &__savedFilters {
    display: flex;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
  }
}

.iconFilterEdit {
  margin-right: 4px;
}

.tooltip {
  position: relative;
  @include tooltip("bottom-end", $blue-lazure, $bw-white, $blue-lazure);
}

.tooltipSearchInput {
  position: relative;
  @include tooltip("bottom-end", $blue-lazure, $bw-white, $blue-lazure, 300px);
}

@keyframes opened {
  0% {
    transform: translateY(-50%) translate3d(0, 0, 0);
  }

  100% {
    transform: translateY(0) translate3d(0, 0, 0);
  }
}

@keyframes closed {
  0% {
    transform: translateY(0) translate3d(0, 0, 0);
  }

  50% {
    transform: translateY(-50%) translate3d(0, 0, 0);
  }

  70% {
    display: none;
    opacity: 0;
    visibility: hidden;
  }

  100% {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}
