@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";

.boardWrapper {
  position: absolute;
  right: 0;
  // чтобы легенда была над кнопками и лоадером
  z-index: 102;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  border: 1px solid $blue-azure;
}

.board {
  width: 710px;
  position: relative;
  overflow-x: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid $bw-gray3;
  z-index: 2;
  background: $bw-white;

  & > p {
    @include zero;
    @include title;
  }
}

.items {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 20px 15px 40px 15px;

  & > p {
    @include zero;
    @include mainText;
    border-radius: 6px;
    padding: 5.5px 8px;
    width: 100%;
  }
}

.item {
  @each $name, $color in $colors_list {
    &_#{$name} {
      background-color: tabel-color($name);
    }
  }
}
