@import "~/src/shared/assets/styles/main.scss";

.main {
  display: flex;
  flex-direction: row;
  column-gap: 80px;
  padding-top: 30px;
}

.menu {
  width: 253px;
}

.bright {
  width: 100px;
  height: 100px;

  background-color: color-level("teal", 0);
}

.normal {
  width: 100px;
  height: 100px;

  background-color: color-level("teal", 1);
}

.light {
  width: 100px;
  height: 100px;

  background-color: color-level("teal", 2);
}

.background {
  width: 100px;
  height: 100px;

  background-color: color-level("teal", 3);
}
