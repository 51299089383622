@import "~/src/shared/assets/styles/main.scss";

.multiSelectCell {
  min-width: 250px !important;
  position: relative;
  &:hover {
    background-color: $blue-white-blue !important;
    & .iconAdd {
      & circle {
        fill: $bw-white;
      }
      & rect {
        fill: $blue-lazure;
      }
    }
  }

  &_withBorder {
    @extend .multiSelectCell;
    border: 1px solid $bw-gray3;
    border-radius: 4px;
    padding: 4px;
  }
  &_active {
    border: 1px double $blue-lazure !important;
    &:hover {
      background-color: $bw-white !important;
    }
  }
  &_error {
    background-color: $accent-pastel-red !important;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.valueContainer {
  display: flex;
  flex-wrap: wrap;
  width: 95%;
  margin: 0;
  padding: 0;
  gap: 10px;
}

.error {
  display: flex;
  align-items: center;
  column-gap: 4px;
  & svg {
    width: 22px;
    height: 22px;
  }
  & > p {
    @include zero;
    @include caption;
    color: $accent-red !important;
  }
}

.placeholder {
  @include listMainText;
  color: $bw-gray6;
  padding-left: 4px;
}

.iconsContainer {
  display: flex;
  align-items: center;
  gap: 2px;
}

.iconAdd {
  cursor: pointer;
  outline: none;
  &_active {
    @extend .iconAdd;
    & circle {
      fill: $bw-gray2;
    }
    & rect {
      fill: $blue-lazure;
    }
  }
}

.iconSend {
  cursor: pointer;
  &:hover {
    & path {
      fill: $blue-lazure;
    }
  }
  &_disabled {
    cursor: not-allowed;
    & path {
      fill: $bw-gray4;
    }
  }
}

// TODO: изменить/заменить SelectMultiTable (позиционирование выпадающего списка сделать как в Select)
.scrollboard {
  position: absolute;
  z-index: 5;
  top: 0;
  left: calc(100% + 5px);

  & > :first-child {
    border-color: $blue-lazure;
    & > :last-child {
      & > :first-child {
        height: max-content;
        max-height: 300px !important;
      }
    }
  }
}
