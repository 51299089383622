@import "src/shared/assets/styles/main.scss";

%grayBorder {
  border-top: 1px solid $bw-gray3;
  border-bottom: 1px solid $bw-gray3;
}

.tabsContainer {
  display: flex;
  column-gap: 15px;
  align-items: center;
}

.tabs {
  margin-bottom: 20px;
}

.thead {
  @extend %grayBorder;
}

.title {
  @include caption;
  background-color: white !important;
  white-space: nowrap;
  padding: 8px 4px !important;
}

.tableBody {
  border-top: none !important;

  & > tr {
    @extend %grayBorder;
    vertical-align: middle;
    &:hover {
      background: $blue-white-blue;
    }
    & > td {
      padding: 12px 4px;
    }
    & > td > p {
      @include mainText;
      line-height: 22px;
      margin: 0;
    }
  }
}

.greyRow {
  background-color: $bw-gray1;
}

.tdBuilding {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.objLink {
  display: flex;
  gap: 22px;
}

.link {
  text-decoration: none;
  color: $blue-lazure;
  & p {
    cursor: pointer;
    color: $blue-lazure !important;
    margin: 0;
  }
}

.connect {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $bw-gray2;
}

.btnLink {
  padding-right: 25px;
}

.type {
  @each $name, $color in $colors-list {
    &_#{$name} {
      color: $color !important;
      @if ($name == "default") {
        color: $bw-black !important;
      }
    }
  }
}

.tooltip {
  position: relative;
  @include tooltip("top");
}
