@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

.cardTitle,
.cardText,
.cardNumber {
  @include caption;
  color: $bw-gray7;
  margin-bottom: 8px;
}

.cardSubtitle {
  @include subtitle2;
  color: $blue-lazure;
  padding-top: 4px;
  width: max-content;
  max-width: 450px;
}

.cardNumber {
  color: $bw-gray7;
  font-weight: 500;
  font-size: 20px;
}

.card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 8px 20px 20px 20px;
  border: none;
  border-radius: 8px;
  background-color: $accent-light-green;
}

.smallCard {
  height: 61px;
  min-width: 200px;
}

.bigCard {
  height: 134px;
  min-width: 200px;
}

.green {
  background-color: $accent-light-green;
}

.link {
  text-decoration: none;
}
