@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";

%statusCellBase {
  .statusCellContainer {
    display: flex;
    align-items: center;
    column-gap: 4px;
    position: relative;
    top: 3px;
  }

  .statusCellParagraph {
    @include zero;
    @include caption;
  }

}

.statusCellVerificationRequired {
  @extend %statusCellBase;

  .statusCellIcon path {
    stroke: $accent-orange;
  }

  .statusCellParagraph {
    color: $warning !important;
  }
}

.statusCellIsLoadingDuplicateCheck {
  @extend %statusCellBase;

  .statusCellParagraph {
    color: $bw-gray6 !important;
  }
}

.statusCellIncorrectField {
  @extend %statusCellBase;

  .statusCellIcon {
    width: 22px;
    height: 22px;

    path {
      stroke: $accent-red;

      &:nth-child(2) {
        stroke: transparent;
        fill: $accent-red;
      }
    }
  }

  .statusCellParagraph {
    color: $accent-red !important;
  }
}

.statusCellIncorrectDouble {
  @extend .statusCellIncorrectField;
  padding: 12px 6px !important;

  .statusCellContainer {
    background-color: transparent !important;
  }
}

.statusCellCorrect {
  @extend %statusCellBase;

  .statusCellParagraph {
    color: $success !important;
  }
}
