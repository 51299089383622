@import "src/shared/assets/styles/main.scss";

%box-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06), 0px 2px 8px $blue-white-blue;
}
%border-radius {
  border-radius: 12px;
}

.windowContainer {
  position: fixed;
  width: 100%;
  height: $maxHeight;
  z-index: 1;
  margin-top: 45px;
  margin-left: -7px;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 1;
  transition: opacity 0.15s, visibility 0s;
  user-select: none;
  &_close {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.1s, visibility 0s 0.1s;
  }
}

.headerContainer {
  @extend %box-shadow;
  position: absolute;
  top: 7px;
  left: 7px;
  z-index: 2;
  opacity: 1;
  background-color: $bw-gray1;
  border-radius: 6px;
  padding: 30px 10px;
  max-width: 90vw;
  height: calc(100% - 60px);
}

.header {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 1px solid $bw-gray3;
  &__title {
    @include title;
    padding: 0;
    cursor: default;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;

    & input {
      border-color: $bw-gray4;
    }
  }
}

.closeButton {
  width: 24px;
  height: 24px;
  margin-top: -23.5px;
  margin-right: -20.5px;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }

  &:hover {
    & svg path {
      fill: $bw-gray7;
    }
  }
}

.searchInput {
  & > div {
    width: 400px !important;
  }
}

.modulesContainer {
  width: 100%;
  height: 100%;
  display: grid;
  padding-top: 20px;
  transition: opacity 0.15s, visibility 0s;
  & > :first-child > :first-child {
    overflow-x: hidden !important;
    margin-right: -18px !important;
  }

  &_withSubmodule {
    width: max-content;
    grid-template-columns: 400px auto;
    transition: opacity 0.15s, visibility 0s;

    & > :first-child {
      border-right: 1px solid $bw-gray4;
    }
  }
}

.leftCol {
  padding-right: 50px;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 20px;
}

.moduleContainer {
  width: calc(100% + 30px);
  .module {
    width: calc(100% - 30px);
  }
}

.module {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $bw-gray2;
  padding: 9px 18px;
  border-radius: 20px 0 0 20px;

  &::after {
    display: inline-block;
    border: 19px solid;
    border-color: transparent transparent transparent $bw-gray2;
    height: 0;
    width: 0;
    position: absolute;
    right: -36.5px;
    top: 0;
    content: "";
  }

  &:hover {
    transition: all 0.3s easy;
    background-color: $blue-white-blue;
    box-shadow: none;
    cursor: pointer;

    &::after {
      border-color: transparent transparent transparent $blue-white-blue;
    }
  }

  &_selected {
    background-color: $blue-light-blue;
    &::after {
      border-color: transparent transparent transparent $blue-light-blue;
    }

    &:hover {
      background-color: $blue-light-blue;

      &::after {
        border-color: transparent transparent transparent $blue-light-blue;
      }
    }
  }

  &_disabled {
    @extend .module;
    background-color: $bw-gray3;
    &::after {
      border-color: transparent transparent transparent $bw-gray3;
    }
    &:hover {
      transition: box-shadow 0.6s, background-color 0.6s;
      background-color: $bw-gray3;
      cursor: not-allowed;

      &::after {
        border-color: transparent transparent transparent $bw-gray3;
      }
    }
  }

  &__title {
    @include mainText;
  }

  &__link {
    text-decoration: none;
  }
}

.submodule {
  @extend %box-shadow;
  @extend %border-radius;
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 13px;
  margin-bottom: 12px;
  background-color: $bw-white;
  color: $bw-black;
  text-decoration: none;
  height: 114px;
  width: 300px;

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
    & p {
      @include zero;
      @include subtitle;
    }
  }

  &__text {
    @include mainText;
  }

  &:hover {
    transition: all 0.3s easy;
    background-color: $blue-white-blue;
    box-shadow: none;
    cursor: pointer;
  }
}

.scrollbarForColumn {
  & > :first-child > :first-child {
    overflow-x: hidden !important;
  }
}

.columnContainer {
  display: flex;
  width: max-content;
  padding: 0 30px 30px 30px;

  & > :first-child {
    margin-left: 0;
  }
}

.column {
  margin-left: 12px;
  background-clip: padding-box;
}

.noModules {
  @include mainText;
}
