@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";

.border {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 25px;
  padding: 3px;
  border: 1px solid transparent;
}

.messageAvatarContainer {
  display: flex;
  gap: 8px;
}

.message {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px 20px 12px 8px;
}

.avatar {
  min-width: 36px;
  min-height: 36px;
  max-width: 36px;
  max-height: 36px;
  border-radius: 50%;
  object-fit: cover;
}

.messageData {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.date {
  @include caption;
  color: $bw-gray6;
}

.author {
  @include caption;
  color: $bw-black;
  line-height: 16px;
  margin-right: 10px;
}

.messageContent {
  display: flex;
  justify-content: left;
  gap: 10px;
}

.imageFile {
  display: flex;
  align-items: center;
  width: 160px;
  min-width: 160px;
  max-height: 240px;
  overflow: hidden;
  border-radius: 5px;
  background-color: 0;
  margin-right: 16px;
}

.image {
  width: 160px;
}

.text {
  @include mainText;
  line-height: 22px;
  word-break: break-word;
}

.noText {
  height: 20px;
}

.uploadedFile {
  @include mainText;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  color: $bw-gray7;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $blue-lazure;
    .iconClip > path {
      stroke: $blue-lazure;
    }
  }
}

.iconClip {
  cursor: pointer;
  margin-right: 10px;
}

.btnContainer {
  margin: 10px 0;
}

.basketBtn {
  height: 100% !important;
  padding: 16.5px 3px;
  &:hover .basketBtnImg {
    fill: $blue-lazure;
  }
}

.basketBtnImg {
  fill: $bw-gray5;
}
