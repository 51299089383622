@import "src/shared/assets/styles/base/colors.scss";

.bottomPreviewGallery {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $bw-gray2;
  margin-bottom: 20px;
}

.photoWrapper {
  position: relative;
  height: 72px;
  margin: 20px 15px 20px 5px;
}

.iconAddPhoto {
  width: 72px;
  height: 72px;
  border-radius: 6px;
  object-fit: cover;
  cursor: pointer;

  &:hover {
    border: 1px solid $blue-lazure;
  }
}

.iconAddPhotoDisabled {
  outline: 2px solid $blue-lazure;
  opacity: 0.5;
}

.iconStar {
  position: absolute;
  left: 4px;
  bottom: 4px;
}
