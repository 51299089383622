@import "src/shared/assets/styles/main.scss";

%icon1c {
  &::after {
    content: url("/shared/assets/images/mainIcons/icon1C.svg");
    position: absolute;
    top: 23px;
    right: 16px;
  }
}

%iconEdit {
  &::after {
    content: url("/shared/assets/images/mainIcons/icon1C.svg");
    position: absolute;
    top: 23px;
    right: 32px;
  }
}

%inputWithIcon {
  & input {
    @include clippedText;
    padding-right: 40px !important;
  }
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 80px;
  width: 100%;
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;

  & > div {
    @media screen and (max-width: 1500px) {
      width: 45vw;
    }

    @media screen and (min-width: 1500px) {
      width: 40vw;
    }

    @media screen and (min-width: 1900px) {
      width: 800px;
    }
  }

  &_ppe {
    & > div {
      width: 100% !important;
    }

    @media screen and (max-width: 1900px) {
      width: 450px !important;
    }

    @media screen and (min-width: 1900px) {
      width: 500px !important;
    }
  }

  &_companies {
    @media screen and (max-width: 1500px) {
      min-width: 45vw;
    }

    @media screen and (min-width: 1500px) {
      min-width: 40vw;
    }

    @media screen and (min-width: 1900px) {
      min-width: 800px;
    }

    width: fit-content !important;
  }
}

.row {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  column-gap: 20px;
  margin: 0;

  & > div:nth-child(1),
  & > div:nth-child(2) {
    flex: 1 0 calc(50% - 10px);
  }

  &_three {
    @extend .row;
    column-gap: 20px;
    & > div:first-child,
    & > div:nth-child(2) {
      flex: 1 0 calc(33% - 10px);
    }

    & > div:first-child,
    & > div:nth-child(2) {
      &::after {
        content: "";
        position: absolute;
        right: -20px;
        top: 50%;
        width: 20px;
        height: 1px;
        background: $bw-gray2;
        z-index: 0;
        transition: all 0.4s ease-in-out;
      }
    }
  }
}

.col {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.radioBtns {
  @include caption;
  display: flex;
  align-items: center;
  column-gap: 40px;
  padding-left: 12px;
  color: $bw-black;

  & label {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
  }
}

.grayInput {
  & input {
    &:disabled {
      background-color: white;
      color: $bw-gray5;
    }
  }
}

.snilsContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.switch {
  @include switch;
}

.wrapper {
  position: relative;
}

.inputWithIcon {
  @extend %inputWithIcon;
}

.inputWithDoubleIcon {
  & input {
    padding-right: 70px !important;
  }
}

.icon1C_email {
  @extend %inputWithIcon;
  & > div > div {
    &:nth-child(2) > ul > li:nth-child(2) > div {
      @extend %icon1c;
    }
  }
}

.error {
  position: relative;
  width: 100%;

  & div[data-loading="true"] {
    position: absolute;
    left: 0;
    & > div {
      & > :first-child {
        display: none;
      }
    }
  }

  &_withLink {
    & > :first-child > :last-child > :first-child {
      padding: 8px 0;
      margin: 0 16px !important;
    }
  }
}

.activeBlock {
  background-color: $blue-white-blue !important;
  border: 1px solid $blue-azure !important;
  border-radius: 6px;
}

.iconWrapper {
  position: absolute;
  top: 23px;
  right: 16px;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.editIcon {
  &:hover {
    & path {
      fill: $blue-lazure;
    }
    & mask path {
      fill: inherit;
    }
  }
}
.withTabs {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.ppeContainer {
  display: flex;
  gap: 80px;
  margin: 0 !important;

  & > :last-child svg {
    position: relative;

    @media screen and (max-width: 1500px) {
      width: 300px;
      margin-top: -50px;
    }

    @media screen and (min-width: 1500px) {
      width: 400px;
      margin-top: -80px;
    }
  }
}

.viewFieldWithIcon {
  padding-right: 50px;
}

.viewFieldWithDoubleIcon {
  padding-right: 75px;
}
