@import "src/shared/assets/styles/main.scss";

.tableMain,
.title,
.withoutStatuses {
  margin-top: 30px;
}

.title {
  @include caption;
}

.tableHeader {
  width: max-content;
}

.tableBody {
  border-top: none !important;
}

.trComment {
  > td:first-child {
    @include listMainText;
    margin-right: 30px;
  }
  > td:last-child {
    @include mainText;
    word-break: break-all;
  }
}

tbody.tableBody tr:hover {
  background: $blue-white-blue;
}

tbody.tableBody td {
  @include listMainText;
  padding: 12px 20px 20px 8px;
}
