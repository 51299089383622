@import "src/shared/assets/styles/main.scss";

.buttons {
  display: flex;
  gap: 40px;
  z-index: 1;

  &_group {
    // чтобы выпадающий список выбора года был над лоадером
    z-index: 101;
    & > p {
      @include zero;
      @include caption;
    }

    // контейнер кнопок фильтра и месяцев
    & > div {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      gap: 4px;
    }
  }
}
