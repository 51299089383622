@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";
@import "./staffTablePlaceholders.scss";

.table {
  @include listMainText;
  table-layout: fixed;
  width: max-content;
  border: 1px solid $bw-gray3;

  & th,
  & td {
    text-align: center;
  }
}
