@import "src/shared/assets/styles/main.scss";

%grayBorder {
  border-top: 1px solid $bw-gray2;
  border-bottom: 1px solid $bw-gray2;
}

.title {
  @include caption;
  padding: 12px 4px !important;
  background-color: white !important;
  white-space: nowrap;
  cursor: pointer;
}

.thead {
  @extend %grayBorder;
}

.tableBody {
  border-top: none !important;

  & > tr {
    cursor: pointer;
    @extend %grayBorder;
    vertical-align: middle;
    & > td {
      padding: 12px 5px;
    }
    & > td > p {
      @include mainText;
      font-size: 16px;
      line-height: 22px;
      margin: 0;
    }
  }
}

.link {
  text-decoration: none;
  & p {
    cursor: pointer;
    color: $blue-lazure !important;
    margin: 0;
  }
}

.tdSum {
  display: flex;
  align-items: center;
  gap: 20px;
  & p {
    margin: 0;
  }
}

@media print {
  .addBtn,
  .compensationBtn {
    display: none;
  }
}
