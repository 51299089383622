.modalWrapper {
  position: sticky;
  left: 30px;
  bottom: 20px;
  gap: 35px;
}

.visible {
  display: flex;
}

.hidden {
  display: none;
}

.animation {
  transition: margin 0.8s ease;
}
