@import "src/shared/assets/styles/main.scss";

.table {
  & > tbody > tr > td {
    border: none !important;
  }
  & > tbody > tr:not(:last-child) {
    border-bottom: 1px solid $bw-gray3 !important;
  }
}

.rank {
  @include subtitle;
  margin: 0;
}

.stars {
  margin: 10px 0;
}

.quality {
  position: relative;
  width: 108px;
  height: 100%;
  &_grey {
    background-color: $bw-gray1;
  }

  &__count {
    display: flex;
    min-width: 20px;
    min-height: 20px;
    padding: 0 6px;
    border-radius: 12%;
    background-color: $blue-light-blue;
    color: $bw-black;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0px;
    &_grey {
      background-color: $bw-gray3;
      color: $bw-gray6;
    }
  }
  &__image {
    & > img {
      width: 60px;
      height: 60px;
      margin: 8px 0;
      gap: 5px;
    }
  }
  &__title {
    @include caption;
    color: $bw-black;
    text-align: center;
    width: 100%;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
