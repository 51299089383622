@import "~/src/shared/assets/styles/main.scss";

.section {
  width: 100%;

  &__title {
    padding-left: 20px;
    @include caption;
  }

  &__collapsedTitle {
    @include caption;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}

.list {
  @include list;

  &__item {
    & > a {
      text-decoration: none;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}
