@import "shared/assets/styles/base/sizes.scss";

.app {
  padding-top: 50px;
  font-family: "Noto Sans";
  width: 100%;
  height: 100%;
}

.content-page {
  padding-left: $openedAsideMenu;

  background-color: white;
  &__collapsedMenu {
    padding-left: $closedAsideMenu;
  }
}
