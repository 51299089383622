@import "src/shared/assets/styles/main.scss";

.card {
  height: fit-content;
  width: fit-content;
  width: 350px;
  @include listMainText;
  border: 1px solid $bw-black;
  border-radius: 12px;
  &_alert {
    border-color: $accent-coral;
  }
  animation-name: blinker;
  animation-iteration-count: 3;
  animation-timing-function: cubic-bezier(1, 2, 0, 1);
  animation-duration: 1s;
  animation-delay: 1s;
  -webkit-animation-name: blinker;
  -webkit-animation-iteration-count: 3;
  -webkit-animation-timing-function: cubic-bezier(1, 2, 0, 1);
  -webkit-animation-duration: 1s;
  -webkit-animation-delay: 1s;
}

@keyframes blinker {
  from {
    box-shadow: 0px 4px 20px color-lever($accent-coral, 3);
  }
  to {
    border: 1px solid $accent-coral;
  }
}

.title {
  color: $accent-coral;
  padding: auto;
}

.headerCard {
  display: flex;
  align-items: center;
  gap: 10px;
}

.bodyCard {
  display: flex;
  flex-direction: column;
  align-content: space-around;
  row-gap: 6px;
  margin-top: 19px;
}
