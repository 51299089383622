@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";

.country {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 16px;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    transition: all 0.4s ease;
    background: $blue-white-blue;
    border-radius: 16px;
  }
}

.countryName {
  @include mainText;
}

.countryCallingCode {
  @include mainText;
  color: $bw-gray5;
  margin-left: -5px;
}

.selectContainer {
  display: flex;
  flex-direction: column;
  height: 170px;
  row-gap: 16px;
  padding: 12px 10px 12px 16px;
  border: 1px solid $blue-lazure;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);
  border-radius: 8px;
  position: absolute;
  z-index: 2;
  max-width: 230px;
  top: 74px;
}
