@import "src/shared/assets/styles/main.scss";

%text {
  @include subtitle;
  @include zero;
}

.headerBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 3;
  top: -1px;
  background-color: $bw-white;
  border-bottom: 1px solid $bw-gray3;
  //УДАЛИТЬ ПРИ ПОДКЛЮЧЕНИИ АПИ
  margin-bottom: 40px;
}

.staffInfo {
  display: flex;
  column-gap: 12px;

  & img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
  }
}

.fio {
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: space-around;
  & > p {
    @extend %text;
  }
}

.TNPrintBlock {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.TNBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  height: fit-content;
  border: 1px solid $bw-gray2;
  border-radius: 4px;
  padding: 4px 6px;
}

.TNBlockTitle {
  @extend %text;
  color: $bw-gray6;
}

.TNBlockValue {
  @extend %text;
  color: $bw-gray7;
}

.printBtn {
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 6px;

  & p {
    color: $bw-gray6;
    @include zero;
  }

  &:hover {
    cursor: pointer;
    & p {
      border-bottom: 1px solid $bw-gray6;
      margin-top: 1px;
    }
    & svg {
      path {
        fill: $bw-gray6;
      }
      & > :nth-child(2),
      & > :last-child {
        fill: $bw-white !important;
        stroke: $bw-gray6 !important;
      }
    }
  }
}

.transformed {
  transform: rotate(180deg);
}

.addOperationContainer {
  position: relative;
}

.headerSticky {
  transition-duration: 0.5s;
  padding: 10px 30px;
  margin-left: -30px !important;
  width: calc(100% + 60px) !important;
  box-shadow: 0px 4px 20px 0px #00000014;
}

.headerRelative {
  transition-duration: 0.5s;
  padding-bottom: 12px;
}

.icon {
  & path {
    fill: $blue-lazure;
    stroke: $blue-lazure;
  }
}

@media print {
  .tab,
  .printBtn {
    display: none;
  }

  .staffInfo {
    & > img {
      width: 8vw;
      height: 8vw;
    }
  }
}
