@import "src/shared/assets/styles/main.scss";

%border {
  border-bottom: 1px solid $bw-gray2;
}
%smallTableText {
  @include listMainText;
  color: $bw-black;
  font-size: 12px;
}
%normalTableText {
  @include listMainText;
  color: $bw-black;
}

.table {
  margin: 0;
  padding: 0;
}

.thead {
  background-color: white !important;
}

.title {
  @include caption;
  color: $bw-gray6;
}

.tr {
  border-top: none;
  @extend %border;
}

.trHover:hover {
  background-color: $blue-white-blue;
}

.date {
  @extend %smallTableText;
  word-wrap: break-word;
}
.td {
  @extend %normalTableText;
  word-wrap: break-word;
}

.date,
.td {
  padding: 8px !important;
}

.tfoot {
  border: none;
  border-top: 1px solid $bw-gray2;
  background-color: $bw-gray1 !important;
}

.totalText {
  @extend %smallTableText;
  text-align: left;
}

.totalNum {
  @extend %normalTableText;
  font-weight: 500;
  text-align: center;
}
