@import "src/shared/assets/styles/main.scss";

.topMenu {
  background-color: $bw-gray2;
  border-radius: 24px 0px 0px 24px;
  display: flex;
  align-items: center;
  height: 48.5px;
  padding: 3px 8px;
  margin-top: -3px;

  &__buttons {
    display: flex;
    align-items: center;
    gap: 4px;

    &_opened {
      transition: all 0.1s ease;
    }

    &_closed {
      transition: all 0.4s ease;
    }
  }

  &__oneButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: $bw-white;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
    }
    @include tooltip("bottom-end", $blue-lazure, $bw-white, $blue-lazure);

    &_active {
      background-color: $bw-gray7;
      &:hover {
        background-color: $bw-gray7;
        box-shadow: none;
      }
    }
  }
}

.userBlock {
  @include listMainText;
  padding-right: 10px;
  border-right: 1px solid $bw-gray6;
  height: 40px;
  display: flex;
  align-items: center;
  column-gap: 9.5px;
  cursor: pointer;
}

.avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.iconArrow {
  cursor: pointer;
  & svg path {
    stroke: $bw-gray6;
  }

  &_closed {
    & svg {
      animation: closeTopMenu 0.4s forwards;
      will-change: transform;
    }
  }

  &_opened {
    & svg {
      animation: openTopMenu 0.1s forwards;
      will-change: transform;
    }
  }
}

@keyframes openTopMenu {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}

@keyframes closeTopMenu {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
