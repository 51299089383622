@import "shared/assets/styles/base/colors";
@import "shared/assets/styles/mixins/fontsMixins";

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid $bw-gray3;
  z-index: 2;
  background: $bw-white;

  &.scrolled {
    box-shadow: 0 4px 20px 0 #00000014;
  }

  & .containerTitle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 5px;
  }

  .title {
    @include subtitle;
  }

  .subtitle {
    @include listMainText;
    color: $bw-gray5;
  }
}
