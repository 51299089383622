@import "~/src/shared/assets/styles/base/colors.scss";

.container {
  width: max-content;
  position: absolute;
  z-index: 5;
  top: 55px;
  left: 0;
  border-radius: 8px;
  background-color: $bw-white;
  display: flex;
  padding: 16px 16px 20px 16px;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
  min-width: 215px;
}

.btnBlock {
  display: flex;
  justify-content: flex-end;
}
