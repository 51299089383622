@import "~/src/shared/assets/styles/main.scss";

.wrapper {
  margin-top: 30px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 45px;
}
.commentsList {
  width: 100%;
}
