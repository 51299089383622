@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";

.buildingNumber {
  display: flex;
  justify-content: center;
  padding: 12px 16px;
  border-bottom: 1px solid $bw-gray3;
  width: 40px;
}

.buildingInfo {
  padding: 13px 10px;
  border-bottom: 1px solid $bw-gray3;
}

.buildingTitle {
  @include zero;
  text-decoration: none;
  cursor: pointer;
  color: $blue-lazure;
}

.iconExpand {
  margin-right: 6px;
  margin-top: -5px;
  cursor: pointer;
  transform: rotate(360deg);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  & > circle {
    fill: $bw-gray2;
  }
  & > path {
    stroke: $blue-lazure;
  }

  &_opened {
    transform: rotate(180deg);
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    & > path {
      stroke: $accent-coral;
    }
  }
}

.citiesImg {
  margin-top: -5px;
}

.buildingStatusDate {
  color: $bw-gray6;
  margin-top: 5px;
}

.statusOneBlock {
  padding: 15px 20px 12px 12px;
  border-bottom: 1px solid $bw-gray3;
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  cursor: pointer;

  &:hover {
    background-color: $blue-white-blue;
  }

  &_selected {
    border: 1px solid $blue-lazure !important;
    background-color: $blue-white-blue !important;
  }

  &_invisible {
    display: none;
  }

  @each $name, $color in $colors_list {
    &_#{$name} {
      background-color: color-level($name, 3.1);
    }
  }
}

.mainInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.statusTitleAndDate {
  display: flex;
  align-items: center;
  column-gap: 8px;
  & p {
    margin: 0;
    @include subtitle2;
  }
}

.vacationMark {
  border-radius: 4px;
  @include listMainText;
  color: $bw-white;
  padding: 2px 4px;

  @each $name, $color in $colors_list {
    &_#{$name} {
      background-color: $color;
    }
  }
}

.dateValue {
  margin-left: 4px !important;
}

.inStatus {
  margin: 0;
  @include caption;
  color: $bw-black;
  & span {
    @include subtitle2;
  }
}

.titleAndValue {
  @include caption;
  color: $bw-gray6;
  font-size: 12px;
  padding-right: 8px;
  margin-right: 8px;
  &:not(:last-child) {
    border-right: 1px solid $bw-gray4;
  }
}
