@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";
@import "/src/shared/assets/styles/main.scss";

.relative {
  position: relative;
}

.flag {
  cursor: pointer;
  position: absolute;
  top: 32.5px;
  left: 15px;
  z-index: 1;
  display: flex;
}

.notEditable {
  cursor: not-allowed;
}

.iconGlobe {
  margin-right: -5px;
}

.openedFlag {
  transform: rotate(180deg);
  transition: all 0.4s ease-in-out;

  & path {
    fill: $blue-lazure;
    stroke: $blue-lazure;
  }
}

.closedFlag {
  transform: rotate(0deg);
  transition: all 0.4s ease-in-out;
}

.countryCallingCode {
  @include mainText;
  color: $bw-black;
  position: absolute;
  top: 36px;
  left: 60px;
}

.input {
  @include mainText;
  @include clippedText;
  border: 1px solid $bw-gray3;
  border-radius: 6px;
  height: 72px;
  padding: 36px 16px 19px 60px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &::placeholder {
    @include inputPrompt;
  }

  &:hover {
    cursor: pointer;
    background-color: $blue-white-blue;
    border-color: $blue-azure;
  }

  &:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid $blue-lazure;
    & + .label {
      padding-top: 20.5px;
      opacity: 1;
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      top: 0;
      &::before {
        left: 12px;
        top: 27px;
      }
    }
  }
}

.inputFocused {
  border: 1px solid $blue-lazure;
}

.inputWithoutCaret {
  caret-color: transparent;
}

.inputNotActive {
  background: $bw-white;
}

.inputDisabled {
  background-color: $bw-gray2;
  border-color: $bw-gray2;
  cursor: not-allowed;
  &:hover,
  &:focus {
    cursor: not-allowed;
    background-color: $bw-gray2;
    border-color: $bw-gray2;
  }
}

.longCode {
  padding-left: 93px;
}

.middleCode {
  padding-left: 85px;
}

.miniCode {
  padding-left: 79px;
}

.label {
  @include mainText;
  color: $bw-gray6;
  border: 1px solid #0000;
  height: 100%;
  left: 4px;
  overflow: hidden;
  padding: 1rem 0.75rem;
  pointer-events: none;
  position: absolute;
  text-align: start;
  text-overflow: ellipsis;
  top: 10px;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  white-space: nowrap;
  width: 100%;
}

.labelRequired {
  padding-left: 24px;
  &::before {
    content: "";
    position: absolute;
    left: 11px;
    top: 26px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: $accent-light-orange;
  }
}

.labelRequiredNoValue {
  &::before {
    top: 22px;
  }
}

.labelRequiredInvalid {
  &::before {
    content: "";
    background: $accent-coral;
  }
}

.labelWithCountry {
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
  top: 5px;
}

.labelWithData {
  opacity: 1;
  top: 4px;
  left: 3px;
  transition: none;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);

  &::before {
    content: "";
    position: absolute;
    width: 6.5px;
    height: 6.5px;
    border-radius: 3.25px;
    left: 12px;
    top: 22px;
  }
}

.clearBtn {
  position: absolute;
  right: 15px;
  top: 25px;
}

.clearBtnImg {
  & rect {
    fill: $bw-gray5;
  }

  &:hover {
    & rect {
      fill: $bw-gray7;
    }
  }
}

.clearBtnDisabled {
  & rect {
    fill: $bw-white;
  }
  &:hover {
    & rect {
      fill: $bw-white;
    }
  }
}

.inputInvalid {
  padding-right: 0;
  background-image: none;
  border: 1px solid $accent-coral;
  transition: all 0.4s ease-in-out;
  &:focus {
    border-color: $accent-coral;
    box-shadow: none;
  }
}

.feedback {
  padding-left: 8px;
  padding-top: 4px;
  width: max-content;
  margin: 0;
  padding-left: 8px;
  padding-top: 4px;
  @include caption;
  color: $accent-coral;
  line-height: 14px;
  z-index: 1000;
}
