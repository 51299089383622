@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.thead {
  position: sticky;
  top: -2px;
  z-index: 1;
  border-top: 1px solid $bw-gray2;
  border-bottom: 1px solid $bw-gray2;
  & th {
    padding: 4px 0.5rem 4px 0.5rem;
    padding: 4px 0.5rem 4px 0.5rem;

    @include caption;
    background-color: white !important;
    white-space: nowrap;
    cursor: pointer;

    & span {
      display: flex;
      align-items: center;
      gap: 2px;
    }
  }

  &_sticky {
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    transform: box-shadow 0.4s ease-in-out;
    transition-duration: 0.2s;
    margin-left: -30px !important;
    width: calc(100% + 60px) !important;
  }

  &_normal {
    transition-duration: 0.1s;
  }
}
