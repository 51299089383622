@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";

%statusField {
  @include mainText;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  height: 32px;
  width: max-content;
  margin-top: -3px;
  padding-right: 5px;
  & p {
    margin: 0;
  }
}

.table {
  width: calc(100% + 60px);
  margin-left: -30px;
}

.tableBody {
  border-top: none !important;

  & > tr {
    cursor: pointer;
    border-top: 1px solid $bw-gray2;
    border-bottom: 1px solid $bw-gray2;
    vertical-align: middle;
    & > td {
      padding: 10px 5px;

      @media screen and (min-width: 1900px) {
        padding: 15px 7px;
      }
    }
    & > td > p {
      @include mainText;
      @include zero;
    }

    & > :first-child {
      padding-left: 20px;
    }
  }
}

.greyRow {
  background-color: $bw-gray1;
}

.detailHoverRow {
  &:hover {
    background-color: $blue-light-blue;
    border-top: 1px solid $blue-light-blue;
    border-bottom: 1px solid $blue-light-blue;
  }
}

.withErrorIcon {
  display: flex;
  & > :first-child {
    margin-right: -7px;
  }
  & > :last-child {
    margin-top: -12px;
  }
}

.open {
  @extend %statusField;
  background-color: $accent-light-green;
}

.deleted {
  @extend %statusField;
  background-color: $accent-pastel-red;
  & img {
    margin-left: 5px;
  }
}

.closed {
  @extend %statusField;
  background-color: $bw-gray3;
}

.datePickerCustom {
  height: 32px;
  padding: 6px 15px 7px 5px;
  width: 130px;
}

.error {
  & td {
    border-bottom: none;
    & .emptyAlert {
      padding: 20px !important;
      height: 51px;
      width: max-content;
    }
  }
}
