.loaderContainer {
  & div[data-loading="true"] {
    position: relative;
    display: block;
    left: 0;
    margin-top: 20px;
    height: 50px;
  }
}

.container {
  margin-top: 20px;
  margin-left: 30px;
}

.barchart {
  margin-top: 20px;
  position: relative;
  left: -65px;
}
