@import "src/shared/assets/styles/main.scss";

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 40px;
  padding: 14.5px 16px;
  border-radius: 12px;
  margin-bottom: 20px;

  & > p {
    @include subtitle;
    color: black;
    margin: 0;
  }

  &__state {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;

    & > p {
      @include listMainText;
      margin: 0;
    }
  }
}
