@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

.container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.lastNotes {
  @include mainText;
}

.link {
  @include mainText;
  color: $blue-lazure;
  width: max-content;
  cursor: pointer;
  &:hover {
    text-shadow: 0 0 0.1px $bw-gray6, 0 0 0.1px $bw-gray6;
  }
}
