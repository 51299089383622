@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/base/colors.scss";

.wrapper {
  display: flex;
  align-items: center;
}

.fullWidth {
  width: 100%;
  justify-content: space-between;
}

.btn {
  width: fit-content;
  height: fit-content;
  border: none;
  background: none;
}

.date {
  margin: 0;
  @include listMainText;
  font-weight: 500;
}

.imgRight {
  transform: rotate(180deg);
}
