@import "src/shared/assets/styles/main.scss";

.infoBlock {
  margin-bottom: 25px;
}

.title {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $bw-gray2;
  padding-bottom: 20px;
  gap: 20px;

  & p {
    @include zero;
    @include subtitle;
  }

  & svg {
    cursor: pointer;
    margin-top: -6px;
  }
}

.row {
  @include cardRow;
}

.field {
  @include cardField;

  &_customWidth {
    width: 15%;
  }
}

.changeWorkshift {
  display: flex;
  align-items: center;

  & p {
    @include zero;
  }
}

.lockOpenIcon,
.lockIcon {
  width: 24px;
  height: 24px;
  margin-top: -2px;
}
