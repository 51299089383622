@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

.withPlaceholderContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  border: none;
  width: fit-content;
  min-height: 51px;
  padding-top: 2px;
}

.placeholder {
  @include zero;
  color: $bw-gray6;
}

.iconExpand {
  position: absolute;
  left: 60px;
  top: 0;
}

.valueContainer {
  position: relative;
  height: 72px;
  padding: 22px 16px;
  border: 1px solid $bw-gray3;
  border-radius: 6px;
  @include mainText;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .isFloating {
    padding-top: 17px;
  }

  &:hover {
    background: $blue-white-blue;
    border-color: $blue-azure;
    cursor: pointer;
    transition: all 0.4s ease;
    .buttons__openBtn path {
      fill: $blue-lazure;
      stroke: $blue-lazure;
    }
  }
}

.disabled {
  border-radius: 6px;
  background-color: $bw-gray2;
  pointer-events: none;
}

.list {
  right: 0;
  margin-top: 3px;
  display: block;
  min-width: 300px;
  max-width: 400px;
  z-index: 99999;

  // TODO: вынести стили в класс для ItemsScrollBoard
  & > :first-child {
    border-color: $blue-lazure;
    & > :last-child {
      & > :first-child {
        height: max-content;
        max-height: 300px !important;
      }
    }
  }

  .tableSelect {
    left: 50%;
  }
}

.inputPrompt {
  @include inputPrompt;
}

.openedMenu {
  border-color: $blue-lazure !important;
}

.label {
  width: 100%;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.labelRequired {
  padding-left: 12px !important;
  &::before {
    content: "";
    position: absolute;
    left: 16px;
    top: 31px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: $accent-light-orange;
  }
}

.labelBold {
  @include subtitle;
  margin-bottom: 8px;
}

.labelBoldRequired {
  &::before {
    content: "";
    position: absolute;
    left: -10px;
    top: 7px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: $accent-light-orange;
  }
}

.labelFloating {
  position: absolute;
  top: 10px;
  @include caption;
}

.title {
  @include zero;
  @include caption;
  color: $bw-gray5;
}

.labelFloatingRequired {
  margin-left: 6px;
  padding-left: 5px;
  &::before {
    content: "";
    position: absolute;
    left: -7px;
    top: 3.5px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: $accent-light-orange;
  }
}

.labelWithMark {
  display: flex;
  align-items: center;
  gap: 10px;
}

.labelWithoutValue {
  @include inputPrompt;
  @include zero;
  color: $bw-gray5;
}

.error {
  margin-bottom: 18px;
  .wrapper {
    border-color: $accent-coral !important;
    .labelRequired {
      &::before {
        background: $accent-coral;
      }
    }
  }
}

.errorMessage {
  position: absolute;
  bottom: -18px;
  left: 9px;
  @include caption;
  color: $accent-coral;
}

.buttons {
  width: 24px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.openBtn {
  outline: none;
  transition: all 0.2s ease;
  transform: rotate(0deg);

  & > path {
    fill: $bw-gray5;
    stroke: $bw-gray5;
  }

  .openBtnTransformed {
    transition: all 0.4s ease;
    transform: rotate(180deg);

    & > path {
      fill: $blue-lazure !important;
      stroke: $blue-lazure !important;
    }
  }
}

.clearBtn {
  & rect {
    fill: $bw-gray5;
  }

  &:hover {
    & rect {
      fill: $bw-gray7;
    }
  }
}

.clearBtnDisabled {
  & rect {
    fill: $bw-white;
  }
  &:hover {
    & rect {
      fill: $bw-white;
    }
  }
}
