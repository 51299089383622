@import "src/shared/assets/styles/main.scss";

.container {
  width: 800px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.fieldsGroup {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  width: 100%;

  & > :first-child {
    width: 550px;
    & > ::after {
      content: "";
      position: absolute;
      right: -40px;
      top: 50%;
      width: 40px;
      height: 1px;
      background: $bw-gray2;
      z-index: 0;
      transition: all 0.4s ease-in-out;
    }
  }

  & > :last-child {
    width: 180px;
  }
}

@keyframes animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
