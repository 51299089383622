@import "/src/shared/assets/styles/base/colors.scss";

%firstColWidth {
  white-space: nowrap;
  vertical-align: middle;
  width: 30px;
  margin: 0;
}

%secondColWidth {
  width: 320px;
  white-space: nowrap;
  vertical-align: middle;
  & > div:first-of-type {
    width: 300px;
    margin: 0;
  }
}

%shadowRight {
  // border-left для sticky-ячеек, т.к. обычный border пропадает
  &::after {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -1px;
    background-color: $bw-gray3;
  }

  // тень справа
  &::before {
    content: "";
    width: 11px;
    height: 104%;
    position: absolute;
    top: 0;
    right: 0px;
    box-shadow: 5px 0 5px -5px $bw-gray6 !important;
  }
}

%shadowLeft {
  &::before {
    // border-right для sticky-ячеек, т.к. обычный border пропадает
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background-color: $bw-gray3;
  }

  // тень слева
  &::after {
    content: "";
    width: 11px;
    height: 104%;
    position: absolute;
    top: 0;
    left: 0;

    box-shadow: -5px 0 5px -5px $bw-gray6 !important;
  }
}

%staffStickyCol {
  position: sticky;
  // отступ слева - ширина первого столбца
  left: 29px;
  z-index: 1;
}

%numAndStaffCols {
  // столбец num (№)
  & > :first-child {
    position: sticky;
    left: -1px;
    z-index: 1;

    // нужно обнулить контент у псевдокласса, чтобы не отображалась тень из класса .th_shadowRight
    &::before {
      content: none !important;
    }
  }

  // столбец данных сотрудника staff
  & > :nth-child(2) {
    @extend %staffStickyCol;
  }
}

%totalCols {
  // для последних трёх столбцов устанавливаем position: sticky
  & > :nth-last-child(-n + 3) {
    position: sticky;
    z-index: 1;
    width: 90px;

    // а также фикс.ширину, чтобы можно было закрепить их справа
    & div {
      width: 100%;
    }
  }

  // 3 с конца столбец (часы по компаниям)
  & > :nth-last-child(0n + 3) {
    // отступ - ширина 2-х последних столбцов
    right: 181px;
  }

  // 2 с конца стобец (часы общие)
  & > :nth-last-child(0n + 2) {
    // отступ - ширина последнего столбца
    right: 91px;
    // нужно обнулить контент у псевдокласса, чтобы не отображалась тень из класса .th_shadowLeft
    &::after {
      content: none !important;
    }
  }

  // последний столбец (вклад)
  & > :nth-last-child(0n + 1) {
    right: 1px;
    // нужно обнулить контент у псевдокласса, чтобы не отображалась тень из класса .th_shadowLeft
    &::after {
      content: none !important;
    }
  }
}

// стили колонки при наведении
%highlightedPresave {
  background-color: $blue-white-blue !important;
}
