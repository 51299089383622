@import "src/shared/assets/styles/base/colors.scss";

.workshift {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 8px;
  column-gap: 40px;
  position: relative;
  position: sticky !important;
  top: 0;
  z-index: 2;

  @each $name, $color in $colors-list {
    &_#{$name} {
      background-color: color-level($name, 2.5);
    }
  }

  &_sticky {
    transition-duration: 0.2s;
    padding: 10px 20px;
    padding-right: 80px;
    border-radius: 0px;
    margin-left: -30px !important;
    width: calc(100% + 60px) !important;
  }

  &_normal {
    transition-duration: 0.1s;
    border-radius: 12px;
    padding: 12px;
    padding-right: 80px;
  }

  &__flex {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 20px;
  }

  &__title {
    font-weight: 500;
  }

  &__absoluteButtons {
    position: absolute;
    right: 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    & svg {
      cursor: pointer;
    }
  }
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: $blue-lazure;
  word-wrap: break-word;
}
