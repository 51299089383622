@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

%border {
  border: 1px solid $bw-gray2;
  border-radius: 12px;
}

.collapsibleContainer {
  margin: 0 -30px;
  padding: 0 30px;
}

.widgetHeader {
  display: grid;
  grid-template-columns: auto max-content;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  padding-top: 5px;
  margin-top: -8px;
  background-color: $bw-white;
  z-index: 1;
  top: 207px;
  transform: translate(0, 0px);
  transition: all 0.4s ease;
}

.marginBottom {
  margin-bottom: 30px;
}

.field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 15px;
}

.award {
  @extend %border;
  width: 200px;
  height: 213px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
  }
}

.dataText {
  @include listMainText;
  margin-top: 15px;
}

.img {
  width: 85px;
  height: 85px;
}

.title {
  @include subtitle;
  width: 168px;
  hyphens: auto;
  word-wrap: break-word;
  text-align: center;
}
