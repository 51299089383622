@import "src/shared/assets/styles/main.scss";

.commentTitle {
  @include subtitle;
  margin-bottom: 20px;
}

.textarea {
  position: relative;
  & > div > textarea {
    padding-right: 50px;
  }
}

.sendButton,
.editButton {
  background-color: transparent;
  border: none;
  width: 24px;
  cursor: pointer;
  position: absolute;
  right: 20px;
  bottom: 45%;
}

.withoutButtons {
  display: none;
}
