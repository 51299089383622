@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.wrapperRequired {
  &::before {
    content: "";
    position: absolute;
    z-index: 10;
    top: 34px;
    width: 6px;
    left: 18px;
    height: 6px;
    border-radius: 3px;
    background: $accent-light-orange;
  }
}

.label {
  @include caption;
  @include clippedText;
  @include zero;
  color: $bw-gray6;
  position: absolute;
  z-index: 10;
  top: 10px;
  padding-left: 16px;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.labelRequired {
  padding-left: 31px;

  &::before {
    content: "";
    position: absolute;
    top: 4px;
    width: 6px;
    left: 18px;
    height: 6px;
    border-radius: 3px;
    background: $accent-light-orange;
  }
}

.labelInvalid {
  &::before {
    content: "";
    background: $accent-coral;
  }
}

.textarea {
  @include mainText;
  resize: none;
  overflow-y: hidden;
  border: 1px solid $bw-gray3;
  border-radius: 6px;
  min-height: 72px;
  max-height: 400px;
  padding: 27px 16px 19px;
  max-width: 100%;
  caret-color: $blue-lazure;

  &::placeholder {
    @include inputPrompt;
    @include clippedText;
    color: $bw-gray6;
  }

  &:hover {
    cursor: text;
    background-color: $blue-white-blue;
    border-color: $blue-azure;
  }

  &:focus {
    outline: none;
    border: 1px solid $blue-lazure !important;
    cursor: text;
    &::placeholder {
      opacity: 0;
      transition: opacity 0.3s ease;
    }
  }

  &:disabled {
    background: $bw-gray2;
    border: 1px solid $bw-gray2;
    cursor: not-allowed;
  }
}

.textIndent {
  padding-right: 70px;
  &:focus {
    padding-right: 70px;
  }
}

.forCommentInputFieldTextarea {
  overflow: hidden;
  border: none;
  border-radius: 8px;
  min-height: 78px;
  margin: 16px 16px 50px 16px;
  width: calc(100% - 34px);

  &:hover {
    background-color: $bw-white;
    border-color: none;
  }

  &:focus {
    border: none !important;
  }
}

.withoutLabel {
  padding-top: 19px;
  &:focus {
    padding-top: 19px;
  }
}

.textareaError {
  border: 1px solid $accent-coral !important;
}

.required {
  padding-left: 32px;
}

.initialCounterValue {
  @include caption;
  color: $bw-gray7;
  text-align: end;
  padding: 3px 3px 0 0;
}

.forCommentInputField {
  padding: 0 20px 5px 0;
}

.green {
  color: $accent-green;
}

.red {
  color: $accent-coral;
}

.error {
  width: max-content;
  padding: 0 0 5px 9px;
  margin-top: -14px;
  @include caption;
  color: $accent-coral;
}

.errorWithoutCounter {
  margin-top: 3px;
}

.clearBtn {
  position: absolute;
  right: 18px;
  top: 30%;
}

.clearBtnActive {
  @extend .clearBtn;
  right: 9px;
  top: 28%;
}
