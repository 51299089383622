@import "/src/shared/assets/styles/base/colors.scss";

.icon {
  border: none !important;
  margin-right: 20px;
}

.error {
  background-color: $accent-pastel-red;
  cursor: pointer;
}

.ok {
  background-color: $accent-light-green;
  cursor: pointer;
}
