@import "~/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "~/src/shared/assets/styles/base/colors.scss";

.firstCol {
  @include mainText;
  padding-left: 20px !important;
}

.loading {
  @include listMainText;
}
