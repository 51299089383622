@import "src/shared/assets/styles/main.scss";

.container {
  width: 40vw;
}

.tableContainer {
  margin-top: 30px;
}

.position {
  @include subtitle2;
  margin-bottom: 20px;
}
