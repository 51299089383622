/*
   В проекте есть 2 справочника цветов:
    - Цвета из UI Kit: $ui-colors
    - Цвета с бэка (приходят с бэка текстовым значением - именем цвета): $colors


    UI Kit взяты из Figma, в дизайн-проекте отображаются в правом меню как "Акцентные/Коралловый", "ЧБ/Серый 7", "Синие/Лазурный" и т.д.
    Цвета из UI Kit никогда не изменяют ни насыщенность, ни прозрачность. Использовать их нужно как они есть.

    Имя цвета из UI Kit: $группа цвета-сам цвет

    А для цветов с бэка возможны настройки насыщенности с помощью миксина color-level (от 0 до 3, чем больше цифра, тем меньше насыщенность)
*/

$color-interval: 30%;

$bw-black: #000000;
$bw-gray7: #3a3a39;
$bw-gray6: #6e6e6e;
$bw-gray5: #9c9c9d;
$bw-gray4: #c5c6c6;
$bw-gray3: #e7e7e7;
$bw-gray2: #f3f3f3;
$bw-gray1: #fafafa;
$bw-white: #ffffff;

$blue-dark-blue: #005ba9;
$blue-sky: #0087cc;
$blue-blue: #0077d8;
$blue-lazure: #008cff;
$blue-azure: #80c5ff;
$blue-light-blue: #cee9ff;
$blue-white-blue: #e5f3ff;

$accent-red: #d41d3c;
$accent-coral: #ff584e;
$accent-pastel-red: #ffeeed;
$accent-light-red: #ffd5d3;
$accent-orange: #ec6500;
$accent-light-orange: #ffad00;
$accent-yellow: #fffeec;
$accent-gold: #c19f33;
$accent-beige: #fffaea;
$accent-green: #17a854;
$accent-light-green: #eff9e9;
$accent-lime: #f8fbe3;
$accent-peach: #fff3e0;

$default: #ffffff;
$success: #17a854;
$info: #008cff;
$primary: #337ab7;
$warning: #ec6500;
$danger: #ff584e;
$pink: #e91e63;
$purple: #9c27b0;
$deep-purple: #673ab7;
$indigo: #3f51b5;
$blue: #2196f3;
$light-blue: #03a9f4;
$cyan: #00bcd4;
$teal: #009688;
$green: #4caf50;
$light-green: #8bc34a;
$lime: #cddc39;
$yellow: #ffeb3b;
$amber: #ffc107;
$orange: #ff9800;
$deep-orange: #ff5722;
$red: #f44336;
$brown: #4e342e;
$grey: #9fa6b2;
$blue-grey: #78909c;
$empty: #9c9c9d;

$colors-list: (
  "bw-black": $bw-black,
  "bw-gray7": $bw-gray7,
  "bw-gray6": $bw-gray6,
  "bw-gray5": $bw-gray5,
  "bw-gray4": $bw-gray4,
  "bw-gray3": $bw-gray3,
  "bw-gray2": $bw-gray2,
  "bw-gray1": $bw-gray1,
  "bw-white": $bw-white,
  "blue-dark-blue": $blue-dark-blue,
  "blue-sky": $blue-sky,
  "blue-blue": $blue-blue,
  "blue-lazure": $blue-lazure,
  "blue-azure": $blue-azure,
  "blue-light-blue": $blue-light-blue,
  "blue-white-blue": $blue-white-blue,
  "accent-red": $accent-red,
  "accent-coral": $accent-coral,
  "accent-pastel-red": $accent-pastel-red,
  "accent-light-red": $accent-light-red,
  "accent-orange": $accent-orange,
  "accent-light-orange": $accent-light-orange,
  "accent-yellow": $accent-yellow,
  "accent-gold": $accent-gold,
  "accent-beige": $accent-beige,
  "accent-green": $accent-green,
  "accent-light-green": $accent-light-green,
  "accent-lime": $accent-lime,
  "accent-peach": $accent-peach,
  "default": $default,
  "success": $success,
  "info": $info,
  "primary": $primary,
  "warning": $warning,
  "danger": $danger,
  "pink": $pink,
  "purple": $purple,
  "deep-purple": $deep-purple,
  "indigo": $indigo,
  "blue": $blue,
  "light-blue": $light-blue,
  "cyan": $cyan,
  "teal": $teal,
  "green": $green,
  "light-green": $light-green,
  "lime": $lime,
  "yellow": $yellow,
  "amber": $amber,
  "orange": $orange,
  "deep-orange": $deep-orange,
  "red": $red,
  "brown": $brown,
  "grey": $grey,
  "blue-grey": $blue-grey,
  "empty": $empty
);

/*
  @param $color-name - имя цвета
  @param $level - уровень насыщенности цвета от 0 до 3. 
                  чем больше цифра, тем меньше насыщенность
                  можно использовать десятичные числа
*/

@function color-level($color-name, $level: 0) {
  $color: map-get($colors-list, $color-name);
  $color-base: if($level < 0, black, white);

  $return-color: #fff;

  @if $color {
    $return-color: mix($color-base, $color, $level * $color-interval);
  }

  @return $return-color;
}

@function company-color($color-name) {
  @if ($color-name == "teal") {
    @return color-level($color-name, 1);
  } @else {
    @return color-level($color-name);
  }
}

@function tabel-color($color-name) {
  @if (
    $color-name == "success" or $color-name == "info" or $color-name == "lime"
  ) {
    @return color-level($color-name, 3);
  } @else if ($color-name == "blue-grey") {
    @return color-level($color-name);
  } @else {
    @return color-level($color-name, 2);
  }
}
