@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

.tabsContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
