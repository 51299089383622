@import "src/shared/assets/styles/main.scss";

.storyWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.storyData {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.date {
  @include caption;
  margin-right: 10px;
  color: $bw-gray6;
}

.name {
  @include caption;
  color: $bw-black;
}

.actions {
  @include mainText;
  word-break: break-word;
  margin-bottom: 10px;
}

.history {
  display: flex;
  align-items: baseline;
  gap: 8px;
}
