@import "src/shared/assets/styles/base/colors.scss";

.button {
  height: 94px;
  width: 30px;
  background-color: transparent;
  border: none;
  border-radius: 0 20px 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover,
  &:active {
    background-color: $blue-light-blue;
  }
  &:active {
    box-shadow: -4px 4px 4px rgba(255, 255, 255, 0.5);
  }
}

.buttonPrev {
  transform: rotate(-180deg);
}

.buttonDisabled {
  &:hover {
    background-color: $bw-gray2;
  }
}

.icon {
  & path {
    stroke: $bw-black;
  }
  &:hover {
    & path {
      stroke: $blue-lazure;
    }
  }
}

.iconDisabled {
  & path {
    stroke: $bw-gray4;
  }
  &:hover {
    & path {
      stroke: $bw-gray4;
    }
  }
}
