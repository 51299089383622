@import "~/src/shared/assets/styles/main.scss";

$space35: 35.5px;

.inputsContainerTop,
.inputsContainerBottom {
  display: grid;
  column-gap: $space35;

  & > div:not(:last-child)::after {
    content: "";
    width: calc(#{$space35 + 1px});
    height: 1px;
    top: 30px;
    right: calc(-#{$space35 - 1px});
    position: absolute;
    background-color: $bw-gray2;
  }
}

.inputsContainerTop {
  grid-template-columns: 160px 400px 236px;
}

.inputsContainerBottom {
  grid-template-columns: 532px 146px 118px;
}
