@import "../staffTablePlaceholders.scss";
@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";

%th {
  position: relative;
  padding: 0 !important;
  @include caption;
  background-color: $bw-white !important;
  vertical-align: middle;
  border: 1px solid $bw-gray3;
}

%centeredText {
  text-align: center;
}

.thead {
  position: sticky !important;
  top: -2px;
  z-index: 3;
}

.theadShadow {
  box-shadow: 0 5px 5px -5px $bw-gray5;
  transition: all 0.2s ease;
}

.firstRow {
  @extend %numAndStaffCols;
}

.firstRowTh {
  @extend %th;
  word-wrap: break-word;
}

.firstRowTotal {
  @extend %centeredText;
  position: sticky;
  right: 0;
  z-index: 1;
  width: 270px !important;
}

.presaveDate {
  @include mainText;
  text-align: center;
  color: $bw-black;
}

.shadowRight {
  @extend %shadowRight;
}

.shadowLeft {
  @extend %shadowLeft;
}

.shadowLeftTop {
  @extend .shadowLeft;
  &::after {
    top: -6px;
    height: 130%;
  }
}

.highlightedPresave {
  @extend %highlightedPresave;
}

.secondRow {
  @extend %totalCols;

  // граница справа для последней ячейки каждой почасовки
  & > :nth-child(4n) {
    border-right: 1px solid $bw-gray3 !important;
  }
}

.secondRowTh {
  @extend %th;
  @extend %centeredText;
  border: none;
  border-bottom: 1px solid $bw-gray3;
  min-width: 55px;
}

.secondRowThTotal {
  @extend .secondRowTh;
  min-width: 77px;
  padding-right: 12px;
  border: none;
  border-bottom: 1px solid $bw-gray3;
}

.presavesCounter {
  position: absolute;
  left: 4px;
  color: $bw-gray5;
}

.num {
  @extend %firstColWidth;
}

.main {
  @extend %secondColWidth;
}
