@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";

.contentItem {
  border-bottom: 1px solid $bw-gray2;

  & .fieldWrapper {
    margin-bottom: 20px;
  }

  & .fieldLabel {
    @include caption;
    color: $bw-gray5;
    margin-right: 10px;
  }
}

.rowContainer {
  display: flex;
  gap: 20px;
}

.fileContainer {
  display: flex;
  margin-top: 4px;
  margin-bottom: 20px;
  width: 150px;
  & a {
    @include listMainText;
    @include clippedText;
    color: $bw-gray6;
    text-decoration: none;
    &:hover {
      color: $blue-lazure;
    }
  }
}

.attachment {
  stroke: $bw-gray6;
  margin-right: 9px;
}
