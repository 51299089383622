@import "src/shared/assets/styles/main.scss";

%border {
  border: 1px solid $bw-gray3;
  &:first-child {
    border-left: none;
  }
  &:last-child {
    border-right: none;
  }
}

.tableBody {
  @include mainText;
  & > tr {
    vertical-align: middle;
    text-align: center;
    & > td {
      padding: 12px 8px;
      @extend %border;
    }
  }
}

.firstCol {
  @include caption;
  color: $bw-gray6;
}

.selected {
  background-color: $blue-white-blue !important;
  box-shadow: inset 0px 0px 0px 1px $blue-light-blue !important;
}
