@import "src/shared/assets/styles/main.scss";

.event {
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;

  &__currentTour {
    display: flex;
    align-items: center !important;
    gap: 10px;
    width: max-content;
  }

  &__currentTourNumber {
    padding: 4px;
    border-radius: 4px;

    @each $name, $color in $colors-list {
      &_#{$name} {
        background-color: color-level($name, 1);
      }
    }
  }

  &__currentTourTitle {
    @include caption;
    color: $bw-gray6;

    &_light {
      color: $bw-gray2;
    }
  }
}

.status {
  position: relative;
  width: max-content;

  &__field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    & > :last-child {
      & svg {
        stroke: $bw-gray5;
      }
    }

    & > :first-child {
      width: max-content;
    }

    &_withoutIcons {
      margin-right: 15px;
    }
  }

  &__button {
    cursor: pointer;
    &_active {
      transform: rotate(180deg);
      transition: all 0.4s ease-in-out;
    }
    &_nonctive {
      transform: rotate(0deg);
      transition: all 0.4s ease-in-out;
    }
  }
}

.formatForm {
  @include listMainText;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 35px;
  right: 0;
  border: 1px solid $blue-azure;
  border-radius: 6px;
  background-color: $bw-white;
  padding: 15px;
  min-width: 9vw;
  width: max-content;
  height: max-content;
  z-index: 10;

  &__btn {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    border: none;
    border-radius: 16px;
    background-color: transparent;
    padding: 10px;
    width: 100%;
    text-align: left;

    &:hover {
      background-color: $blue-white-blue;
    }
  }
}
