@import "shared/assets/styles/base/colors.scss";


.openedAllWidgets {
  padding: 12px;
  margin-bottom: -31px;
  cursor: pointer;
  color: $bw-gray6;
  width: 100%;
  position: sticky !important;
  background-color: $bw-white;
  z-index: 4;
  transform: translate(0, 0px);
  transition: all 0.4s ease;

  &::after {
    content: "";
    background-color: $bw-white;
    display: block;
    width: calc(100% + 12px);
    height: 7px;
    position: absolute;
    top: -5px;
    left: -12px;
    z-index: 3;
  }
}

.openedAllWidgetsMiniTop {
  @extend .openedAllWidgets;
  top: 85px;
  transform: translate(0, 0px);
  transition: all 0.4s ease;
}
