@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";

%disabled {
  background-color: $bw-white !important;
}

.container {
  @media screen and (max-width: 1200px) {
    width: 550px;
  }

  @media screen and (min-width: 1200px) {
    width: 600px;
  }

  @media screen and (min-width: 1200px) {
    width: 800px;
  }

  @media screen and (min-width: 1600px) {
    width: 900px;
  }

  @media screen and (min-width: 1900px) {
    width: 1100px;
  }
}

.company {
  position: relative;
  width: 100%;
  background-color: $bw-gray2;
  border: 1px solid $bw-gray3;
  border-radius: 6px;
  height: 72px;
  padding: 30px 16px 10px 16px;
  margin-top: 20px;

  &:hover {
    cursor: not-allowed;
  }
}

.companyTitle {
  @include caption;
  position: absolute;
  top: 10px;
}
