@import "src/shared/assets/styles/main.scss";

.container {
  border-top: 1px solid $bw-gray3;
}

.rowWithBtns {
  border-bottom: 1px solid $bw-gray3 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 4px;
  width: 100%;
}

.row {
  border-bottom: 1px solid $bw-gray3 !important;
}

.btn {
  & div {
    width: max-content;
  }
}
