@import "~/src/shared/assets/styles/main.scss";

.twoFields {
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > div {
    width: 47.5%;
  }
  & > div:first-child > div:first-child {
    &::after {
      content: "";
      position: absolute;
      right: -11%;
      top: 35px;
      width: 11%;
      height: 1px;
      background: $bw-gray2;
      z-index: 0;
      transition: all 0.4s ease-in-out;
    }
  }

  &_withDateRangePicker {
    & > :first-child {
      width: 31.5%;
    }
    & > :last-child {
      width: 65%;
    }
  }
}

.threeFields {
  @extend .twoFields;
  & > div {
    width: 30%;
  }
  & > div:first-child > div:first-child {
    &::after {
      right: -17%;
      width: 17%;
    }
  }
  & > div:nth-child(2) > div:first-child {
    &::after {
      content: "";
      position: absolute;
      right: -17%;
      top: 35px;
      width: 17%;
      height: 1px;
      background: $bw-gray2;
      z-index: 0;
      transition: all 0.4s ease-in-out;
    }
  }
}
