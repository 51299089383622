@import "src/shared/assets/styles/main.scss";

%icon {
  & svg {
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    fill: $blue-lazure;
  }

  &:disabled {
    & svg {
      -webkit-transition-duration: 0.4s;
      transition-duration: 0.4s;
      fill: $bw-gray4;
    }
  }
}

.btnGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  height: 40px;
}

.addBtn {
  @extend %icon;
}

.cancelBtn,
.deleteBtn {
  @extend %icon;

  & svg {
    fill: $accent-coral;
  }
}
