@import "src/shared/assets/styles/main.scss";

.shiftsTotal {
  position: absolute;
  bottom: 8px;
  right: 0;
  top: 0;
  @include caption;
  color: $bw-black;

  .shiftsTotalNum {
    @include subtitle2;
    margin-left: 20px;
  }
}

.totalSum {
  @include subtitle2;
  text-align: right;
}

.tabs {
  width: 75%;
}
