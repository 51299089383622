@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/base/colors.scss";


.calendarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 90px;

  &_withoutData {
    margin-top: 96px;
  }
}

.children {
  display: flex;
  width: 500px;
  padding: 0 20px;
  justify-content: center;
  & > div {
    width: 270px;
  }
  & > div:nth-child(2) {
    margin-left: 30px;
  }
}

.btnSaveWrapper {
  width: 100%;
  padding: 0 50px;
  margin-top: 20px;
}

.btnSave {
  width: 100%;
}
