@import "src/shared/assets/styles/main.scss";

.container {
  max-width: 1000px;
}

.storyContainer {
  border-bottom: 1px solid $bw-gray2;
  padding: 20px 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    background-color: $bw-gray1;
  }
}

.storyHeader {
  display: flex;
  justify-content: flex-start;
  color: $bw-gray5;
  margin-bottom: 11px;

  &__date {
    @include caption;
    margin-right: 10px;
  }

  &__name {
    @include caption;
    color: $bw-black;
  }
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.item {
  @include mainText;
  word-break: break-word;
}
