@import "~/src/shared/assets/styles/main.scss";

.container {
  position: sticky;
  top: 0;
  width: 100%;
  max-width: 874px;
}

.commentInputField {
  border-radius: 8px;
  border: 1px solid $bw-gray2;

  &:hover {
    border: 1px solid $blue-azure;
    box-shadow: 0px 2px 8px rgba(0, 140, 255, 0.1);
  }
}

.buttonsContainer {
  border-top: 1px solid $bw-gray2;
  margin: 0 16px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0;
}

.addFileButton {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  width: 24px;
  cursor: pointer;
  white-space: pre-line;
}

.attachment {
  stroke: $bw-gray6;
  cursor: pointer;

  &:hover {
    stroke: $blue-lazure;
  }
}

.tooltip {
  position: relative;
  @include tooltip("top-start", $blue-lazure, $bw-white, $blue-lazure, 300px);
}

.displayFileName {
  @include listMainText;
  color: $bw-gray7;
  background-color: $bw-gray2;
  border-radius: 16px;
  padding: 5px 20px 4px;
  width: fit-content;
  text-align: center;
  white-space: nowrap;
  margin-left: 9px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.displayFileDelete {
  margin-left: auto;
}

.sendButton {
  stroke: $blue-lazure;
  background-color: transparent;
  border: none;
  width: 24px;
  cursor: pointer;

  &:hover {
    stroke: $blue-lazure;
  }

  &:disabled {
    stroke: $bw-gray3;
  }
}

.errors {
  width: max-content;
  padding: 4px 8px;
  color: $accent-coral;
  font-size: 12px;
  line-height: 14px;
  z-index: 1000;
}
