@import "src/shared/assets/styles/main.scss";

.loading {
  width: 100%;
  height: calc(100vh - 228px);
  & div[data-loading="true"] {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.chart {
  @include mainText;

  &__row {
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding-bottom: 30px;

    & > div {
      width: 24px;
      height: 24px;
      border-radius: 50%;

      &:first-child {
        background: #707ad3;
      }
      &:nth-child(3) {
        background: #f77990;
      }
    }

    & > p {
      margin: 0;
      color: $bw-gray7;
      @include listMainText;

      &:nth-child(2) {
        padding-right: 32px;
      }
    }
  }

  & > div:nth-child(2) {
    width: 1225px;
    height: 300px;
  }
}

.tooltip {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 10px;
  width: fit-content;
  height: max-content;
  background: $bw-white;
  border: 1px solid $bw-gray3;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
  border-radius: 12px;

  & p {
    margin: 0;
    color: $bw-gray6;
    @include caption;
  }

  & > div {
    display: flex;
    align-items: center;
    column-gap: 8px;
    & > div {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}
