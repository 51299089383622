@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";

.cols {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.leftCol {
  min-width: 460px;
}

.rightCol {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutAward {
  margin-right: 40px;
}

.addAwardData:not(:last-child) {
  margin-bottom: 22px;
}

.awardData {
  @include subtitle;
  padding: 12px 0;
  border-bottom: 1px solid $bw-gray3;
  margin-bottom: 20px;
}

.title {
  @include caption;
  margin-bottom: 8px;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 240px;
}

.image {
  max-width: 240px;
  height: 160px;
  margin-bottom: 13px;
}

.invisible {
  opacity: 0;
}

.awardTitle {
  @include subtitle;
  max-width: 240px;
  width: 240px;
}

.awardAddTitle {
  @include inputPrompt;
  color: $bw-gray6;
}
