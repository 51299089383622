@import "src/shared/assets/styles/main.scss";

.wrapper {
  border-bottom: 1px solid $bw-gray3;
}

.tabsWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 0;
  gap: 20px;
}
