@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";

.scrollboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  row-gap: 16px;
  padding: 20px 16px;
  border: 1px solid $blue-azure;
  background-color: $bw-white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);
  border-radius: 8px;
  position: relative;
  & div[data-loading="true"] {
    position: absolute;
    display: block;
    left: 0;

    & > div {
      margin-top: 40px;
    }
  }
}

.scrollboardWithoutPadding {
  padding: 10px;
}

.emptyAlert {
  width: 100%;
}

.foundBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include mainText;
}

.textBlock {
  display: flex;
  align-items: center;
  gap: 6px;
}

.foundText {
  margin: 0;
}

.addAllIcon {
    width: 18px;
    height: 18px;
}

.addAllIconDisabled {
  & rect {
    fill: $bw-gray5;
  }
}
