@import "src/shared/assets/styles/main.scss";

.table {
  margin: 0;
  padding: 0;
}

.thead {
  background-color: $bw-white !important;
  border-top: none;
  border-bottom: 1px solid $bw-gray2;
}

.title {
  @include caption;
  color: $bw-gray6;
}

.tr {
  border-top: none;
  border-bottom: 1px solid $bw-gray2;
  cursor: pointer;
  &:hover {
    background-color: $blue-white-blue !important;
  }
  & > :first-child,
  & > :nth-child(2) {
    width: 15vw;
  }
  & > :nth-child(3),
  & > :nth-child(4) {
    text-align: center;
    font-size: 12px;
  }
}

.warning {
  background-color: $accent-yellow;
  &:hover {
    background-color: $accent-yellow !important;
  }
  & > td {
    & > div {
      & > :first-child {
        color: $accent-orange;
      }
    }
  }
}

.red {
  background-color: $accent-pastel-red;
  &:hover {
    background-color: $accent-pastel-red !important;
  }
  & > td {
    & > div {
      & > :first-child {
        color: $accent-red;
      }
    }
  }
}

.activeRow {
  position: relative;
  background-color: $blue-white-blue !important;
  border-top: 1px solid $blue-azure !important;
  border-bottom: 1px solid $blue-azure !important;
  &_sibling {
    border-bottom: none;
  }
  &::after {
    content: "";
    position: absolute;
    right: -60px;
    top: 50%;
    width: 60px;
    height: 1px;
    background: $blue-azure;
    z-index: 0;
    transition: all 0.4s ease-in-out;
  }
}

.td {
  @include listMainText;
  color: $bw-black;
  word-wrap: break-word;
  padding: 8px !important;
}
