@import "~/src/shared/assets/styles/main.scss";

.inputsRow {
  display: flex;
  justify-content: center;
  gap: 35px;
  padding: 17px;
  background-color: $bw-white;

  border-radius: 8px;
  position: absolute;
  z-index: 9999;
  width: 500px;

  & > div:first-child,
  & > div:nth-child(2) {
    position: relative;
    width: 240px;
  }

  & > div:first-child {
    &::after {
      content: "";
      position: absolute;
      right: -40px;
      top: 50%;
      width: 40px;
      height: 1px;
      background: $bw-gray2;
      z-index: 0;
      transition: all 0.4s ease-in-out;
    }
  }
}

.input {
  height: 72px;
  & input:disabled {
    background-color: white;
  }
  &__active {
    outline: 1px solid $blue-azure;
    border-radius: 6px;
  }
}

.inputImg {
  position: absolute;
  top: 25px;
  right: 15px;
}
