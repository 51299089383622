@import "src/shared/assets/styles/main.scss";

.loading {
  width: 100%;
  height: calc(100vh - 228px);
  & > div[data-loading="true"] {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.timesheet {
  @include mainText;
}

.table {
  border: 1px solid $bw-gray2;
  margin-bottom: 70px;

  & thead {
    position: sticky;
    top: -2px;
    z-index: 2;
    background-color: $bw-white;
    border: 1px solid $bw-gray2;

    & > tr > th {
      padding: 0;
    }
  }

  & thead > tr > th,
  & tbody > tr > td {
    display: table-cell;
    vertical-align: middle;
  }

  & tr td > :first-child {
    height: 100%;
  }
}

.hide {
  display: none;
}

.fio {
  width: max-content !important;

  & > div {
    min-width: 180px;
    word-wrap: break-word;
  }
  & a {
    @include mainText;
    cursor: pointer;
    text-decoration: none;
    color: $blue-lazure;
  }
}

.tn {
  @include mainText;
  padding: 8px 4px !important;
  width: max-content;
  border: 1px solid $bw-gray2;
  text-align: center;
  width: 70px !important;
}

.hours {
  @include listMainText;
  text-align: center;
}
