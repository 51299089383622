@import "~/src/shared/assets/styles/base/colors.scss";
@import "~/src/shared/assets/styles/mixins/mainMixins.scss";
@import "~/src/shared/assets/styles/mixins/fontsMixins.scss";

.panel {
  display: flex;
  justify-content: flex-start;
  margin: 40px 0 20px 20px;
  height: 87px;
}

.leftSide {
  padding-right: 30px;
  border-right: 1px solid $bw-gray3;
}

.subtitle {
  @include subtitle2;
  line-height: 10px;
}

.panelBtn {
  color: $bw-black;
  margin: 0 4px 4px 0;
  padding: 8px 20px 8px 12px;
  width: fit-content;
  height: 40px;
}

.panelBtnActive {
  color: $bw-white;
  background-color: $bw-gray7;
  & > svg > path {
    fill: $bw-white;
  }
}

.panelBtnBlack {
  @extend .panelBtn;
  background-color: $bw-gray7;
  color: $bw-white;
}

.caption {
  @include caption;
}

.middleSide {
  padding: 8px 30px 0 30px;
  border-right: 1px solid $bw-gray3;
  display: flex;
  align-items: center;
}

.rightSide {
  @extend .middleSide;
  border-right: none;
  position: relative;
  & > p {
    @include caption;
    color: $bw-gray7;
    position: absolute;
    top: 10px;
    & > b {
      font-size: 16px;
    }
  }

  & > :last-child > :first-child {
    width: max-content;
  }
}

.error {
  display: flex;
  align-items: center;
  padding: 24px;
  gap: 10px;
  background-color: $accent-pastel-red;
  border-radius: 12px;
  width: 20vw;
  & > p {
    @include zero;
    @include listMainText;
    color: $bw-gray7;
  }

  & svg {
    & path {
      stroke: $accent-coral;
    }
    & > :last-child {
      fill: $accent-coral;
    }
  }
}
