@import "~/src/shared/assets/styles/main.scss";

%flexColumn {
  display: flex;
  flex-direction: column;
}

.formContainer {
  padding-left: 5px;
  width: 100%;
}

.form {
  @extend %flexColumn;
  row-gap: 30px;

  & section {
    @extend %flexColumn;
    row-gap: 20px;

    & > p {
      margin: 0;
      @include subtitle;
    }

    & ul {
      @extend %flexColumn;
      flex-wrap: wrap;
      row-gap: 10px;
      column-gap: 80px;

      max-height: 224px;
      max-width: 50%;
      margin: 0;
      padding-left: 20px;

      list-style-type: none;
    }
  }
}
