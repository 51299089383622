@import "src/shared/assets/styles/main.scss";

.leftBtn,
.rightBtn {
  cursor: pointer;
  width: 60px;
  height: $maxHeight;
  position: fixed;
  top: 0;
  z-index: 3;
  background-color: $bw-white;
  opacity: 0;
  border: none;
  border-right: 1px solid $blue-lazure;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    display: none;
  }
}

.leftBtn {
  margin-left: -30px;
}

.rightBtn {
  right: 0;
  border-right: none;
  border-left: 1px solid $blue-lazure;
}
