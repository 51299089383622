@import "src/shared/assets/styles/main.scss";

.container {
  & table:not(table[data-internaltable="true"]) {
    margin-top: 20px;
    width: calc(100% + 59px);
    margin-left: -30px;
  }

  & thead:not(thead[data-internaltable="true"]) {
    position: sticky;
    z-index: 4;
    top: 65px;
  }

  &_shadow {
    & thead:not(thead[data-internaltable="true"]) {
      box-shadow: 0 8px 5px -3px rgba(0, 0, 0, 0.12);
      transform: box-shadow 0.4s ease-in-out;
    }
  }
}

.filter {
  width: 100%;
  position: relative;
}

.infoBlock {
  position: sticky;
  z-index: 4;

  & > :first-child {
    margin-top: 20px;
    margin-bottom: -22px;
    @-moz-document url-prefix() {
      margin-top: 0;
    }
  }

  &_fixed {
    width: 100%;
    position: fixed;
  }

  &_withOpenedMenu {
    & > :first-child {
      width: calc(#{$maxWidth} - #{$openedAsideMenu});
    }
  }

  &_withClosedMenu {
    & > :first-child {
      width: calc(#{$maxWidth} - #{$closedAsideMenu});
    }
  }
}

.firefox {
  @-moz-document url-prefix() {
    padding-top: 1px;

    & > :first-child {
      @-moz-document url-prefix() {
        margin-top: 4px;
      }
    }
  }

  &_withMargin {
    @-moz-document url-prefix() {
      margin-top: -1px;
    }
  }
}
