@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";

.main {
  border-radius: 12px;
  padding: 20px 20px 32px;
  background-color: $accent-yellow;
  width: 360px;
  height: min-content;
  margin-bottom: 4px;
}

.mainTitle {
  @include subtitle;
  margin: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid $bw-gray3;
}

.mainField {
  padding-left: 0;
}

.ranges {
  display: flex;
  border-top: 1px solid color-level("blue-blue", 2);
}

.rangesTitle {
  @include subtitle2;
  padding: 12px 0 8px 0;
  margin: 0;
}

.rangesCount {
  width: 60px;
  text-align: center;
  padding-top: 8px;
}

.rangesFields {
  border-left: 1px solid $bw-gray3;
  width: 259px;
}

.noCubes {
  display: flex;
  align-items: center;
  column-gap: 10px;
  border-radius: 12px;
  padding: 24px 16px;
  background-color: $accent-beige;

  @include subtitle2;
  margin: 0;
}
