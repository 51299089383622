@import "~/src/shared/assets/styles/main.scss";

.container {
  width: 160px;
  @include mainText;
}

.buildingTitle {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid $bw-gray3;
  margin-bottom: 5px;
  & p {
    margin: 0;
  }
}

.cross {
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  cursor: pointer;
  fill: $bw-gray4;

  &:hover {
    fill: black;
  }
  margin-top: -2px;
}

.form {
  display: flex;
  flex-direction: column;
  row-gap: 6.5px;
}

.select {
  border-bottom: 1px solid $bw-gray3;
  padding-bottom: 5px;
  & > div > div {
    border: none;
    height: 30px;
    padding: 0;
    height: max-content;
    &:hover {
      background-color: transparent;
    }
    & > p {
      white-space: normal;
    }
  }
  &_withoutValue {
    @extend .select;
    & p {
      @include caption;
    }
  }
}

.datepicker {
  & > div > div {
    background-color: transparent;
    border: none;
    border-radius: none;
    outline: none;
    height: auto;
    padding: 0;

    & > div {
      padding: 0 !important;
      & > p {
        left: auto;
        top: 50%;
        transform: translate(0, -50%);
        @include caption;
      }
    }

    & input {
      background-color: transparent;
      padding: 0 0 0 10px;
    }

    & label {
      display: none;
    }

    &:hover {
      background-color: transparent;
      & input {
        background-color: transparent;
      }
    }
  }
}
