@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";
@import "shared/assets/styles/base/colors.scss";

.page {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: -20px;

  & .headerTitle {
    @include zero;
    @include subtitle;
  }
}

.imgLegend {
  width: max-content;
  margin-right: 5px;
}
