@import "src/shared/assets/styles/main.scss";

.table {
  & td {
    border-top: 1px solid $bw-gray2;
  }
}

.title {
  @include caption;
  background-color: white !important;
  cursor: pointer;
}

.firstCol {
  padding-left: 20px !important;
}

.thead {
  position: sticky;
  z-index: 3;
  top: -2px;
}

.tableBody {
  border-top: none !important;

  & > :first-child {
    border-top: 1px solid $bw-gray2;
  }

  & > tr {
    cursor: pointer;
    & td {
      & p {
        @include mainText;
      }
    }
  }
}

.row {
  @each $name, $color in $colors_list {
    &_#{$name} {
      background-color: $color;
      @include deleteExtraBorder($color, $blue-white-blue);
    }
  }
}

.error {
  & td {
    border-bottom: none;
  }
}

.loading {
  @include listMainText;
}

.description {
  & p {
    min-width: 300px;
  }
}

.wrapCell {
  max-width: 200px;
  table-layout: fixed;
  & span {
    word-wrap: wrap;
    width: 100%;
  }

  &_center {
    & span {
      text-align: center;
    }
  }
}

.chips {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}
