@import "~/src/shared/assets/styles/main.scss";

.switch {
  @include switch;
}

.container {
  position: sticky !important;
  bottom: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: $bw-white;
  width: 49%;
  animation: 0.7s show ease;
  & > label {
    margin: 0 !important;
  }
  & > .buttons {
    padding: 16px;
  }

  &_static {
    margin-top: 20px;
    & > label {
      margin-bottom: 20px;
    }
    & > .buttons {
      align-items: flex-start;
      flex-direction: column;
      gap: 20px;
      padding: 16px;
      padding-left: 0;
    }
  }

  &_error {
    padding: 16px;
    & > .buttons {
      padding: 16px 0;
    }
  }

  &_errorStatic {
    padding: 0;
    & > :first-child {
      width: 48%;
    }
  }

  &_mini {
    width: max-content !important;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > :last-child > :last-child {
    margin: 0;
  }
}

.error {
  position: relative;
  width: 100%;

  & div[data-loading="true"] {
    position: absolute;
    left: 0;
    & > div {
      & > :first-child {
        display: none;
      }
    }
  }

  &_withLink {
    & > :first-child > :last-child > :first-child {
      padding: 8px 0;
      margin: 0 16px !important;
    }
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
