.container {
  @media screen and (max-width: 1200px) {
    width: 550px;
  }

  @media screen and (min-width: 1200px) {
    width: 600px;
  }

  @media screen and (min-width: 1200px) {
    width: 800px;
  }

  @media screen and (min-width: 1600px) {
    width: 900px;
  }

  @media screen and (min-width: 1900px) {
    width: 1100px;
  }
}

.selectCompany {
  margin-top: 20px;
}

.objectAndCompanyBlock {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
