@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";

.relative {
  position: relative;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input {
  @include mainText;
  @include clippedText;
  width: 100%;
  border: 1px solid $bw-gray3;
  border-radius: 6px;
  padding: 34px 45px 19px 16px;
  caret-color: $blue-lazure;
  &::placeholder {
    @include inputPrompt;
  }
  &:focus {
    box-shadow: none;
    border: 1px solid $blue-lazure;
    & + .label {
      padding-top: 20.5px;
      @include mainText($bw-gray6);
      opacity: 1;
      transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
      top: 0;
      &::before {
        left: 18px;
        top: 25px;
      }
    }
  }
}

.inputWithClearBtn {
  @include clippedText;
  padding-right: 45px;
}

.label {
  @include mainText($bw-gray6);
  top: -4px;

  border: 1px solid #0000;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 1rem 0.75rem;
  pointer-events: none;
  position: absolute;
  text-align: start;
  text-overflow: ellipsis;
  top: 0;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  white-space: nowrap;
  width: 100%;
}

.labelNoData {
  @include mainText($bw-gray6);
  padding-top: 26px;
  padding-left: 16px;
}

.labelWithData {
  padding-top: 21px;
  padding-left: 16px;
  @include mainText($bw-gray6);
  opacity: 1;
  top: 0px;
  transition: none;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.labelRequired {
  padding-left: 32px;
  &::before {
    content: "";
    position: absolute;
    left: 18px;
    top: 32px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: $accent-light-orange;
  }
}

.labelRequiredError {
  &::before {
    content: "";
    background: $accent-coral;
  }
}

.labelWithDataRequired {
  &::before {
    top: 25px;
  }
}

.clearBtn {
  position: absolute;
  right: 18px;
  top: 30%;
}

.clearBtnActive {
  @extend .clearBtn;
  right: 9px;
  top: 28%;
}

.inputDisabled {
  background: $bw-gray2;
  border-color: $bw-gray2;
  cursor: not-allowed;
}

.inputError {
  padding-right: 0;
  background-image: none;
  border-color: $accent-coral;
  transition: all 0.4s ease-in-out;
}

.inputHovered {
  background-color: color-level("blue-white-blue", 2);
  border-color: color-level("blue-lazure", 2);
}

.feedback {
  padding-left: 8px;
  padding-top: 4px;
  width: max-content;
  margin: 0;
  @include caption;
  color: $accent-coral;
  line-height: 14px;
  z-index: 1000;
}
