@import "~/src/shared/assets/styles/main.scss";

.valueContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 15px;
  padding-top: 9px;
  @include mainText;
}

.list {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  width: 160%;
  @include list;
  padding: 12px 20px 30px 16px;
  background: $bw-white;
  border: 1px solid $blue-azure;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);
  z-index: 2;
  transition: all 0.4s ease-in-out;

  & li {
    width: 100%;
    transition: all 0.4s ease;

    & p {
      padding: 5px 10px;
      width: 100%;
      min-height: 24.5px;
      @include mainText;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:hover {
        background: $blue-white-blue;
        border-radius: 18px;
        cursor: pointer;
        transition: all 0.4s ease;
      }
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }
  }

  &_top {
    @extend .list;
    top: -160%;
  }
}

.selectedOption {
  border: 1px solid $blue-azure;
  border-radius: 18px;
}

.transformed {
  transform: rotate(180deg);
}

.label {
  width: 100%;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.saveBtn {
  margin-top: 23px;
}
