@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";

.tabs {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  & > :first-child {
    width: 100%;
  }

  & button[data-notclickable="true"] {
    min-width: 180px;
    margin-left: 10px;
  }

  & button:not([data-notclickable="true"]) {
    padding-bottom: 4px;
  }
}

.window {
  @include backgroundModal;
  & > :first-child {
    position: absolute;
    z-index: 20;
  }
}

.wrapper {
  padding: 0px 8px 12px 8px;
}

.wrapperCurrent {
  background-color: $bw-gray1;
  border-top: 1px solid $bw-gray3;
  border-bottom: 1px solid $bw-gray3;
  margin-bottom: 12px;
  padding: 12px 8px;

  .statusTitle {
    & div:last-of-type {
      color: $bw-white;
    }
  }

  .bage {
    background-color: $bw-gray3;
    color: $bw-gray7;
  }

  .timeCreate {
    flex-basis: 100%;
  }
}

.wrapperFuture {
  padding-bottom: 12px;
}

.typeTitle {
  @include subtitle;
}

.building {
  @include mainText;
  color: $bw-gray6;
  margin-bottom: 10px;
  margin-top: 12px;
}

.buildingTitle {
  @include zero;
  @include subtitle;
  text-decoration: none;
  cursor: pointer;
  color: $blue-lazure;
  margin-left: 10px;

  &:hover {
    color: $blue-dark-blue;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.status {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding-left: 8px;

  & p {
    @include zero;
  }

  & > div:first-child {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
}

.statusTitle {
  display: flex;
  align-items: center;
  column-gap: 4px;
  border-radius: 12px;
  background-color: $bw-gray2;
  height: 32px;
  padding: 0 8px 0 4px;
  font-weight: 500;

  & div:last-of-type {
    @include subtitle;
    display: flex;
    align-items: center;
    color: $bw-gray6;
  }

  @each $name, $color in $colors_list {
    &_#{$name} {
      @extend .statusTitle;
      background-color: $color;
    }
  }
}

.bage {
  @include subtitle;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: $bw-gray2;
  height: 32px;
  width: 40px;
  color: $bw-gray6;
  font-weight: 500;
}

.date {
  @include subtitle2;
  font-weight: 400;
  width: max-content;
}

.line {
  border-top: 2px solid $bw-gray3;
  width: 60px;

  @each $name, $color in $colors_list {
    &_#{$name} {
      border-color: $color;
    }
  }
}

.inStatus {
  @include caption;
  color: $bw-gray6;
}

.days {
  @include subtitle;
  margin-top: -2px;
}

.timeCreate {
  @include caption;
  color: $bw-gray6;
  margin-top: 1px;
}

.timeCreateSub {
  padding-left: 96px;
}

.subStatus {
  @extend .status;
  padding-left: 110px;
  margin-top: 12px;
}

.link {
  cursor: pointer;
  color: $blue-lazure;
  margin: 20px 0 0 8px;
}

.futureTransferOut {
  padding-left: 0;
}

.currentTransferOut {
  padding-left: 8px;
  padding-bottom: 12px;
}

.iconDisabled {
  & rect {
    fill: $bw-gray4;
  }
}
