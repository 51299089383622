@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";

.leftButton,
.rightButton {
  margin: 50px 0 0 0;
}

.tableBody {
  border-top: none !important;
  border-bottom: none !important;
  & td {
    padding: 8px;
    vertical-align: top;
    border: solid 1px $bw-gray3;
  }
}

.buildingTitle {
  @include subtitle;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  color: $blue-lazure;
  margin-left: 10px;
  text-decoration: none;

  &:hover {
    color: $blue-dark-blue;
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}

.companyName {
  @include listMainText;
  writing-mode: vertical-lr;
  background-color: $bw-white !important;
  position: sticky;
  left: 0;
  z-index: 1;
  position: relative;
  min-width: 50px;

  &::after {
    content: " ";
    height: 100%;
    position: absolute;
    top: 0;
    width: 15px;
    color: $bw-gray3;
  }
}

.companyNameShadow::after {
  box-shadow: -13px 0 13px -15px inset;
  left: -15px;
}

.companyNameText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  margin-left: 3px;
}

.buildingRow {
  background-color: $accent-lime;
}

.building {
  display: flex;
  align-items: center;
}

.citiesImg {
  margin: 0 23px 0 7px;
}

.buildingStatusDate {
  @include mainText;
  color: $bw-gray6;
  margin: 2px !important;
}

.buildingRowStatusesCell {
  padding: 0 !important;
  vertical-align: top;
  border: solid 1px $bw-gray3;
}

.diff {
  border: 1px solid $bw-gray3;
  background-color: $bw-gray1 !important;
  text-align: center;
}

.emptyAlert {
  margin-left: 30px;
}
