@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

%fieldBorder {
  border-bottom: 1px solid $bw-gray2 !important;
}

.block {
  padding: 20px 0;
  @extend %fieldBorder;
}

.title {
  @include subtitle2;
  @include zero;
  @extend %fieldBorder;
  padding-bottom: 12px;
  width: 100%;
}
