@import "~/src/shared/assets/styles/main.scss";

.switch {
  @include switch;
  gap: 12px;
}

.container {
  position: sticky !important;
  bottom: 20px;
  left: 20px;
  z-index: 1002;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  background-color: $bw-white;
  width: max-content;
  animation: 0.7s show ease;
  & > .buttons {
    gap: 40px;
    padding: 16px;
    padding-left: 10px;
    justify-content: flex-start;
    & > :first-child {
      cursor: pointer;
    }
  }

  &_static {
    margin-top: 20px;
    & > label {
      margin-bottom: 20px;
    }
  }

  &_error {
    padding: 16px;
    & > .buttons {
      padding: 16px 0;
    }
  }

  &_errorStatic {
    padding: 0;
  }

  &_mini {
    position: sticky;
    left: 20px;
    width: max-content !important;
  }
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  padding: 16px;
  padding-left: 10px;
  justify-content: flex-start;
  & > :last-child > :last-child {
    margin: 0;
  }
}

.error {
  position: relative;
  width: 100%;

  & div[data-loading="true"] {
    position: absolute;
    left: 0;
    & > div {
      & > :first-child {
        display: none;
      }
    }
  }

  &_withLink {
    & > :first-child > :last-child > :first-child {
      padding: 8px 0;
      margin: 0 16px !important;
    }
  }

  &_withMargin {
    margin-bottom: 12px;
  }
}

@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.tooltipButton {
  cursor: pointer;
  @include tooltip("top-start");
  &:hover::before {
    bottom: 90%;
  }

  &_bottom {
    cursor: pointer;
    @include tooltip("bottom-start");
    &:hover::before {
      top: 85%;
    }
  }
}
