@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";

.tooltipRight {
  cursor: pointer;
  position: relative;
  @include tooltip("right", $blue-lazure, $bw-white, $blue-lazure, 300px);
}

.addBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -15px;
}

.addBtnText {
  &:hover,
  &:active {
    background-color: transparent !important;
    transition: none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    font-weight: 400;
  }
}

.addBtnIconDisabled {
  & rect {
    fill: $bw-gray4;
  }
}

.deleteBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: -25px;
}

.deleteBtnText {
  color: $accent-orange;
}

.deleteBtnIcon {
  & path {
    fill: $accent-orange;
  }
}
