@import "/src/shared/assets/styles/base/colors.scss";

.nav-tabs {
  column-gap: 2px;
  row-gap: 4px;
}

.nav-tabs .nav-link {
  background-color: $bw-gray2;
  color: $bw-gray6;
}

.nav-tabs .nav-link.active {
  color: $blue-lazure;
  border-color: $bw-gray4;
  border-bottom: 1px solid $bw-white;
}
