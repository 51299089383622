@import "shared/assets/styles/base/colors.scss";

.search {
  display: grid;
  align-items: center;
  column-gap: 10px;
  width: 100%;
  height: max-content;

  & > :first-child,
  & input {
    width: 100%;
  }

  & > :first-child > :nth-child(2) {
    left: calc(100% - 35px);
  }
}

.textAreaAlignTop {
  align-items: start;
}

.withSort {
  grid-template-columns: 1fr 40px;
}
