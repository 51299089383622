@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.hoursContainer {
  position: relative;
  max-width: 100px;
}

.redHours {
  border-radius: 8px;
  background: $accent-pastel-red;
  padding: 3px 10px;
  width: 30px;
  position: relative;
  right: 6px;
}

.numberInput {
  padding: 7px 4px;
  min-width: 82px;
  width: 100%;
  @include mainText;
  border: 1px solid $bw-gray2;
  border-radius: 6px;
  background-color: $bw-white;
  &:hover {
    cursor: text;
    border-color: $blue-azure;
  }

  &:focus {
    box-shadow: none;
    border: 1px solid $blue-lazure !important;
  }

  & > :first-child > :first-child > :first-child > :first-child > :last-child {
    top: 8px;
  }

  &_error {
    padding-right: 0 !important;
    background-image: none !important;
    border: 1px solid $accent-coral !important;
    transition: all 0.4s ease-in-out;

    &:hover,
    &:focus {
      border: 1px solid $accent-coral !important;
    }
  }
}

.hoursComplete {
  position: absolute;
  right: 4px;
  top: 6px;
  cursor: pointer;
}

.errorText {
  @include caption;
  color: $accent-coral;
}
