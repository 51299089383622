@import "src/shared/assets/styles/main.scss";

.storyContainer {
  max-width: 1000px;
  border-bottom: 1px solid $bw-gray2;
  padding: 20px 4px 18px;

  &:hover {
    background-color: $bw-gray1;
  }
}

.storyWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.storyData {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: $bw-gray5;
  margin-bottom: 11px;
}

.date {
  @include caption;
  margin-right: 10px;
}

.name {
  @include caption;
  color: $bw-black;
}

.storyContent {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 5px;
  margin-bottom: 10px;
}

.text {
  @include mainText;
  word-break: break-word;
}
