@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

.main {
  @include mainText;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
