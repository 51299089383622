@import "shared/assets/styles/main.scss";

%tableIconColumn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  border: 1px solid $bw-gray3;
  cursor: pointer;
  background-color: $bw-white;

  &:hover {
    box-shadow: inset 0px 0px 0px 1px $blue-light-blue;

    & svg {
      & rect {
        fill: $blue-lazure;
      }
    }
  }
}

%stickyHeaderCell {
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: $bw-white;
}

.icons {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @extend %stickyHeaderCell;

  &_row {
    display: grid;
    grid-template-columns: repeat(3, 72px);
    align-items: center;
    padding: 0 !important;
    height: 60px;
    background-color: $bw-white;
    font-weight: 500;
    height: max-content;

    .iconAdd,
    .iconMultiEdit {
      height: 24px;
      border: none !important;
    }

    .iconMultiEdit_disabled {
      border: none !important;
    }

    & > div {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & > :first-child,
    & > :nth-child(3) {
      border-right: 1px solid $bw-gray3 !important;
      padding: 17px 0 !important;

      &:hover {
        box-shadow: inset 0px 0px 0px 1px $blue-light-blue;
      }
    }

    & > :nth-child(2) {
      padding: 17px 0;
    }

    & > :nth-child(3) {
      border-left: 1px solid $bw-gray3 !important;
    }

    &__original {
      width: max-content !important;
      height: 100% !important;
      padding: 0 20px;
    }

    & > :nth-child(2) {
      border: none;
      &:hover {
        border: none !important;
      }
    }

    & span > div {
      box-shadow: none !important;
    }

    .inputNumber {
      & > :first-child {
        width: 80%;
        padding: 0;

        & input {
          @include zero;
        }

        & > :first-child > :first-child > div {
          left: -8px;
        }
      }
    }
  }

  &_activeMultiEdit {
    & > :nth-child(3) {
      box-shadow: inset 0px 0px 0px 1px $blue-lazure !important;

      .iconMultiEdit {
        & rect {
          fill: $blue-lazure;
        }
        & path {
          stroke: $blue-lazure;
        }
      }
    }

    &:hover {
      box-shadow: none !important;
      & > :nth-child(3) {
        box-shadow: inset 0px 0px 0px 1px $blue-lazure !important;
      }
    }

    &_column {
      @extend .icons_activeMultiEdit;
      & > :nth-child(3) {
        & div[data-svg="true"] {
          box-shadow: inset 0px 0px 0px 1px $blue-lazure !important;
        }
        &:hover {
          & div[data-svg="true"] {
            box-shadow: inset 0px 0px 0px 1px $blue-lazure !important;
          }
        }
      }
    }
  }
}

.iconAdd {
  @extend %tableIconColumn;

  &_disabled {
    @extend %tableIconColumn;
    cursor: not-allowed;
    & rect {
      fill: $bw-gray3 !important;
    }

    &:hover {
      border: 1px solid $bw-gray3;
      & rect {
        fill: $bw-gray3 !important;
      }
    }
  }
}

.iconMultiEdit {
  @extend %tableIconColumn;
  border-left: none;

  & rect {
    fill: $bw-gray5;
  }
  & path {
    stroke: $bw-gray5;
  }
  &:hover {
    & rect {
      fill: $blue-lazure;
    }
    & path {
      stroke: $blue-lazure;
    }
  }

  &_disabled {
    @extend %tableIconColumn;
    border-left: none;
    cursor: not-allowed;

    &:hover {
      box-shadow: none !important;
      border: 1px solid $bw-gray3;
      border-left: none;

      & svg {
        & rect {
          fill: $bw-gray3;
        }
      }
    }
  }
}

.inputNumber {
  border: 1px solid $bw-gray3;
  border-left: none;
  display: flex;
  max-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  & input {
    caret-color: $blue-lazure;
  }

  & > :first-child {
    width: 70%;
  }

  & > :first-child > :first-child > :first-child > div {
    top: 23px;
    left: 0px;
  }

  &_disabled {
    cursor: not-allowed;
    background-color: $bw-gray2;

    & input,
    & button,
    & svg {
      cursor: not-allowed;
    }
  }
}
