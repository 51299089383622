@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/base/sizes.scss";

.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0 14px 0;
  gap: 20;
}

.modalBodyRow {
  @extend .modalBody;
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 0;
  & > div {
    padding: 0;
  }
}

.modalBodyWithoutIcon {
  flex-direction: row;
  justify-content: flex-start;
}

.errorTitle {
  @include title;
  padding: 4px 0 20px 0;
}

.title {
  @include subtitle;
  color: $bw-black;
  padding: 4px 4px 20px 4px;
}

.subTitle {
  @include inputPrompt;
  color: $bw-black;
  padding: 0 4px 10px 4px;
}

.btnGroup {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 10px;
  width: 100%;
  padding: 0 4px 14px 4px;

  & button {
    width: 100%;
  }
}

.iconClose {
  fill: $accent-red;
}

.iconCloseDisabled {
  fill: $bw-gray4;
}

.closeBtn {
  color: $accent-red;
  white-space: nowrap;
}

.form {
  margin-bottom: 20px;
}

:global {
  .modal-backdrop {
    width: $maxWidth;
    height: $maxHeight;
  }
}
