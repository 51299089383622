@import "src/shared/assets/styles/main.scss";

@mixin flexColumn {
  display: flex;
  flex-direction: column;
}

@mixin contentBlue {
  content: "";
  background: $blue-azure;
  transition: all 0.2s ease-in-out;
}

@mixin contentGray {
  content: "";
  position: absolute;
  right: -40px;
  top: 50%;
  width: 40px;
  height: 1px;
  background: $bw-gray2;
  z-index: 0;
  transition: all 0.2s ease-in-out;
}

.containerForm {
  @include flexColumn;
  row-gap: 20px;
}

.phoneContainer {
  @include flexColumn;
  gap: 20px;
  border-top: 1px solid $bw-gray3;
  width: 100%;
}

.phoneContainerContent {
  & div:nth-child(1) {
    @include caption;
    color: $accent-coral;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  & div:nth-child(2) {
    @include listMainText;
    color: $bw-black;
  }
}

.confirmLabel {
  @include caption;
  margin-bottom: 12px;
  color: $bw-gray5;
  line-height: 14.16px;
}

.confirmRowPhone {
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0;
  list-style-type: none;

  &:first-child {
    margin-top: 0;
  }

  &:nth-child(3) {
    margin-top: 0;
  }

  & li {
    position: relative;
    transition: all 0.2s ease-in-out;

    &:nth-child(1) {
      margin-right: 40px;
      margin-left: 10px;
    }

    &:nth-child(2) {
      @include flexColumn;
      align-items: center;
      max-width: 100px;
      width: 100%;
      height: 100%;
      padding: 12px 16px;
      border: 1px solid $bw-gray2;
      border-radius: 6px;
      margin-right: 40px;
    }

    &:nth-child(3) {
      max-width: 240px;
      width: 100%;
      height: inherit;
      margin-right: 40px;

      & > div > div > input {
        background-color: white;
      }
    }

    &:nth-child(4) {
      max-width: 603px;
      width: 100%;
      height: inherit;

      & > div > div > input {
        background-color: white;
      }
    }

    &:nth-child(n + 5) {
      width: 100%;
      max-width: max-content;
      height: 100%;

      & > button {
        margin: 0 0 0 8px;
        height: 100%;
      }
    }
  }

  & li::after {
    @include contentGray;
  }

  & li:nth-child(1)::after {
    content: none;
  }

  & li:nth-child(n + 4)::after {
    content: none;
  }

  &:hover {
    cursor: pointer;

    &::after {
      background: $blue-azure;
    }
    & input {
      border: 1px solid $blue-azure;
    }
    & li:nth-child(2) {
      border: 1px solid $blue-azure;
      transition: all 0.2s ease-in-out;
    }

    & li::after {
      @include contentBlue;
    }

    & li:nth-child(1)::after {
      content: none;
    }

    & li:nth-child(n + 4)::after {
      content: none;
    }
  }

  &_selected {
    &::after {
      background: $blue-azure;
    }

    & input {
      border: 1px solid $blue-azure;
    }

    & li:nth-child(2) {
      border: 1px solid $blue-azure;
      transition: all 0.2s ease-in-out;
    }

    & li::after {
      @include contentBlue;
    }
  }
}

.confirmRowAddPhone {
  display: flex;
  align-items: center;
  height: 72px;
  width: 100%;
  padding: 0;
  list-style-type: none;

  & li {
    position: relative;
    margin-right: 40px;
    margin-bottom: 5px;
    transition: all 0.2s ease-in-out;

    &:nth-child(1) {
      max-width: 240px;
      width: 100%;
      height: inherit;

      & > div > div > input {
        border-color: $blue-azure;
        background-color: white;
      }
    }

    &:nth-child(2) {
      max-width: 743px;
      width: 100%;
      height: inherit;
      margin-right: 0;

      & > div > div > input {
        border-color: $blue-azure;
        background-color: white;
      }
    }

    &:nth-child(n + 3) {
      width: 100%;
      max-width: max-content;
      height: 100%;
      margin-right: 0;

      & > button {
        margin: 0 0 0 8px;
        height: 100%;
      }
    }
  }

  & li::after {
    @include contentGray;
  }

  & li:nth-child(n + 2)::after {
    content: none;
  }

  &:hover {
    cursor: pointer;

    &::after {
      background: $blue-azure;
    }
    & input {
      border: 1px solid $blue-azure;
    }

    & li::after {
      @include contentBlue;
    }

    & li:nth-child(n + 2)::after {
      content: none;
    }
  }

  &_selected {
    &::after {
      background: $blue-azure;
    }
    & input {
      border: 1px solid $blue-azure;
    }

    & li::after {
      @include contentBlue;
    }

    & li:nth-child(n + 3)::after {
      content: none;
    }
  }
}

.buttonAddNumber {
  display: flex;
  align-items: center;
  max-width: 168px;
  width: 100%;
  margin-left: -15px;
  padding: 10.5px 0 10.5px 16px;
  border: none;
  background-color: transparent;
  border-radius: 20px;
  transition: all 0.4s ease-in-out;

  &__text {
    @include mainText;
    margin: 0;
    padding: 0;
    color: $blue-lazure;
    transition: all 0.4s ease-in-out;
  }

  &__icon {
    width: 24px;
  }

  p {
    transition: all 0.4s ease-in-out;
  }

  p:hover {
    text-shadow: 0 0 0.65px $blue-lazure, 0 0 0.65px $blue-lazure;
  }

  p:focus {
    text-shadow: 0 0 0.65px $blue-lazure, 0 0 0.65px $blue-lazure;
  }

  &:active {
    background-color: $blue-white-blue;
  }
}

.loading {
  @include listMainText;
}

.buttonsWrapper {
  display: flex;
  gap: 10px;
}
