@import "src/shared/assets/styles/main.scss";

%fieldBorder {
  border-bottom: 1px solid $bw-gray2 !important;
}

%displayFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin-top: 31px;
  margin-bottom: 15px;

  &__firstColumn {
    border-top: 1px solid $bw-gray2;
  }

  &__secondColumn {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &__buttons {
      display: flex;
      align-items: center;
      gap: 12px;
      align-self: flex-end;
    }
  }
}

.row {
  @extend %fieldBorder;
  @extend %displayFlex;

  & > div {
    border-left: 1px solid $bw-gray2;
  }

  & > div:first-child {
    border-left: none;
  }

  &_withBtns {
    @extend %fieldBorder;
    @extend %displayFlex;
    padding-right: 4px;
    width: 100%;
  }
}

.field {
  position: relative;
  padding: 12px 0 12px 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__title {
    @include zero;
    @include caption;
  }

  &__value {
    @include zero;
    display: flex;
    align-items: center;
    @include mainText;
  }

  &__buttons {
    display: flex;
    column-gap: 12px;
    position: relative;
  }
}

.button {
  @each $name, $color in $colors_list {
    &_#{$name} {
      color: $color;
    }
  }
}
