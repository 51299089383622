@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";

.statusOneBlock {
  display: flex;
  flex-direction: column;
  height: 64px;
  padding: 12px;
  border-bottom: 1px solid $bw-gray3;
  cursor: pointer;
  &:hover {
    cursor: pointer;
    background: $blue-white-blue !important;
  }

  @each $name, $color in $colors_list {
    &_#{$name} {
      background-color: color-level($name, 2.5);
    }
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.statusBlock {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 8px;

  .statusTitle {
    margin: 0;
    @include subtitle2;
  }
}

.vacationMark {
  border-radius: 4px;
  @include listMainText;
  color: $bw-white;
  padding: 2px 4px;
  margin-left: 4px;

  @each $name, $color in $colors_list {
    &_#{$name} {
      background-color: $color;
    }
  }
}

.dateValue {
  margin-left: 4px !important;
}

.inStatus {
  margin: 3px 0 0 0;
  @include caption;
  color: $bw-black;

  .inStatusDate {
    @extend .inStatus;
    @include subtitle2;
    margin-left: 4px;
  }
}
