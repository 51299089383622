@import "~/src/shared/assets/styles/base/colors.scss";
@import "~/src/shared/assets/styles/mixins/fontsMixins.scss";

.updateWindow {
  @include mainText;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: $bw-white;
  height: 115px;
  width: 500px;
  padding: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);

  @media screen and (min-width: 1900px) {
    width: 570px;
  }
}

.updateWindowText {
  margin-left: 12px;
}
