@import "~/src/shared/assets/styles/main.scss";

.error {
  display: flex;
  align-items: center;
  column-gap: 6px;
  margin-top: 6px;
  & > p {
    @include zero;
    @include caption;
    color: $accent-red !important;
  }
}
