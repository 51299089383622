@import "shared/assets/styles/base/colors.scss";
@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/mixins/mainMixins.scss";

%right-position {
  left: calc(110vw - 1px);

  .icon {
    transform: rotate(180deg);
  }
}

.wrapper {
  position: absolute;
  top: 2px;
  left: 35px;
  right: 0;
  height: 48px;
  z-index: 2;
  pointer-events: none;
}

.button {
  position: sticky;
  left: 48px;
  top: 10px;
  height: 100%;
  border: none;
  border-left: 1px solid $bw-gray3;
  border-right: 1px solid $bw-gray3;
  padding: 5px;
  background-color: $bw-white !important;
  pointer-events: all;
}

.hoveredContainer:hover {
  background-color: $blue-white-blue;
}

.containerToRight {
  @extend %right-position;
}

.icon {
  margin-top: 4px;
}

.contentBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentBlockRight {
  @extend %right-position;
}

.buttonText {
  @include zero;
  @include mainText;
}

.buttonTextActive {
  color: $blue-lazure !important;
}
