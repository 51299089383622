@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/base/colors.scss";

.text {
  @include subtitle;
}

.legendWrapper {
  display: flex;
  gap: 30px;
  margin-top: 25px;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 12px;
  @include listMainText;
}

.legendItemBox {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  @each $name, $color in $colors-list {
    &_#{$name} {
      background-color: $color;
    }
  }
}