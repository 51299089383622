@import "src/shared/assets/styles/mixins/fontsMixins";

.workerWrapper {
  width: 60%;
}

.worker {
  position: absolute;
  width: 30%;
  height: 100%;
  margin-left: 2%;

  & > text {
    @include mainTextFont;
  }
}
