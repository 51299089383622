@import "src/shared/assets/styles/main.scss";

.table {
  border-top: 1px solid $bw-gray3;
}

.title {
  @include caption;
  background-color: white !important;
  white-space: nowrap;
  cursor: pointer;
  & div {
    height: 20px;
  }
}

.activeSortIcon {
  filter: brightness(0) saturate(100%) invert(36%) sepia(46%) saturate(3152%)
    hue-rotate(190deg) brightness(103%) contrast(106%);
}

.tableBody {
  border-top: none !important;
  @include mainText;
  line-height: 22px;
  & > tr {
    cursor: pointer;
    &:hover {
      background: $blue-white-blue;
    }
    & > td {
      padding: 12px 6px;
      width: 300px;
      & p {
        line-height: 22px;
        margin: 0;
      }
    }
  }
}

.greyRow {
  background-color: $bw-gray1;
}

.selectedRow {
  background: $blue-white-blue;
}

.breakCell {
  width: 250px !important;
}

.attention {
  @include caption;
}

.orange {
  color: $accent-orange;
}

.red {
  color: $accent-red;
}

.buildingCell {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.statusRow {
  display: flex;
  column-gap: 12px;
  margin-bottom: 5px;
}

.status {
  display: flex;
  align-items: center;
  column-gap: 7px;
  & p {
    @include zero;
    @include subtitle2;
  }
}

.link {
  text-decoration: none;
  color: $blue-lazure;

  &:hover {
    color: $blue-lazure;
  }
}

.statusCert {
  display: flex;
  align-items: center;
  gap: 5px;

  &__attention {
    @extend .statusCert;
    color: $accent-red;
  }
}

.checkIcon,
.attentionIcon,
.hourglassIcon {
  width: 22px;
  height: 22px;
}

.checkIcon {
  filter: brightness(0) saturate(100%) invert(44%) sepia(63%) saturate(2192%)
    hue-rotate(117deg) brightness(94%) contrast(82%);
}

.attentionIcon {
  filter: brightness(0) saturate(100%) invert(22%) sepia(91%) saturate(2482%)
    hue-rotate(334deg) brightness(83%) contrast(102%);
}

.hourglassIcon {
  filter: brightness(0) saturate(100%) invert(57%) sepia(40%) saturate(7216%)
    hue-rotate(3deg) brightness(94%) contrast(102%);
}

.error {
  & td {
    border-bottom: none;
  }
}

.firstCol {
  padding-left: 20px !important;
}

.loading {
  @include listMainText;
}
