@import "~/src/shared/assets/styles/main.scss";

.label {
  @include switch;
}

.title {
  margin: 0;
  @include subtitle;
}

.valuesContainer {
  position: relative;
  width: 40%;
  padding: 21.5px 20px 21.5px 16px;
  border: 1px solid $bw-gray2;
  border-radius: 6px;

  &_focused {
    @extend .valuesContainer;
    border-color: $blue-azure;

    &::after {
      content: "";
      width: 50px;
      height: 1px;
      background: $blue-azure;
      position: absolute;
      right: -50px;
      top: 50%;
    }
  }
}

.noValues {
  padding-bottom: 32px;
}

.chip {
  width: 60px;
  display: flex;
  justify-content: center;
  &:hover {
    cursor: pointer;
    & button {
      display: block;
      padding-left: 5px;
    }
  }
}

.iconShowContainer {
  width: fit-content;
  position: absolute;
  right: 21px;
  top: 14px;
  &:hover {
    cursor: pointer;
  }
}

.boardContainer {
  position: absolute;
  padding-bottom: 30px;
  right: -710px;
  top: -10px;
}

.boardWrapper {
  display: flex;
  flex-direction: column;
  width: 660px;
  row-gap: 20px;
  padding: 20px 16px;
  border: 1px solid $blue-azure;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);
  border-radius: 8px;
}

.list {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.line {
  display: flex;
  & > div:first-child {
    padding-top: 4px;
    margin-right: 16px;
  }
  & > div:nth-child(2) {
    flex-basis: 341px;
    margin-right: 30px;
  }
  & > div:nth-child(3) {
    flex-basis: 134px;
    margin-right: 10px;
  }

  &:hover {
    & input:not(:disabled) {
      border-color: $blue-azure;
    }
    & input:not(:disabled)[value="false"] {
      border-color: $bw-gray4;
    }
    & input:not(:disabled)[value="true"] {
      border-color: $blue-light-blue;
    }
    transition: all 0.4s ease;
  }

  &_new {
    display: flex;
    & > div:first-child {
      flex-basis: 419px;
      margin-right: 30px;
    }
    & > div:nth-child(2) {
      flex-basis: 129px;
      margin-right: 10px;
    }
    &:hover {
      & input:not(:disabled) {
        border-color: $blue-azure;
      }
      & .state::after {
        background: $blue-azure;
      }
      transition: all 0.4s ease;
    }
  }

  &_focused {
    & input:not(:disabled) {
      border-color: $blue-azure;
    }
    & input[value="false"] {
      border-color: $bw-gray4;
    }
    & input[value="true"] {
      border-color: $blue-light-blue;
    }
    & .state::after {
      background: $blue-azure;
    }
    transition: all 0.4s ease;
  }
}

.state {
  position: relative;
  transition: all 0.4s ease;

  &::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 1px;
    right: -30px;
    top: 30px;
    background: $bw-gray2;
    transition: all 0.4s ease;
  }
}

.btnsGroup {
  display: flex;
  column-gap: 10px;
  height: 58px;
}
