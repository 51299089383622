@import "~/src/shared/assets/styles/main.scss";

.form,
.boolCols {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.date {
  display: flex;
  width: 100%;
  gap: 40px;
  margin: 20px 0 0 0;
  justify-content: space-between;

  & > div:first-child,
  & > div:nth-child(2) {
    width: 25vw;
    position: relative;
  }

  & > div:first-child {
    &::after {
      content: "";
      position: absolute;
      right: -40px;
      top: 50%;
      width: 40px;
      height: 1px;
      background: $bw-gray2;
      z-index: 0;
      transition: all 0.4s ease-in-out;
    }
  }
}

.dateFullWidth {
  & > div:first-child,
  & > div:nth-child(2) {
    width: 100%;
    &:first-child {
      &::after {
        display: none !important;
        top: 0;
        width: 0;
        height: 0;
      }
    }
  }
}

.subdate {
  @extend .date;
  margin: 0;
}

.subdateTitle {
  @include subtitle;
  font-weight: 400;
  margin: 20px 0 8px 3px;
}

.subdateSubtitle {
  @include caption;
  margin: 0 0 8px 3px;
}

.switch {
  @include switch;
}

.calendar {
  & > div > div > div > div {
    inset: calc(100% + -130px) calc(100% - 295px) !important;
  }
}

.comment {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  & > :first-child {
    width: 100%;
  }
}

.commentTooltip {
  & p {
    @include zero;
    text-indent: 5px;
  }
}

.commentIcon {
  margin-top: 20px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  & > rect,
  path {
    fill: $blue-lazure;
  }
  & > circle {
    stroke: $blue-lazure;
  }
}

.emptyAlert {
  margin-bottom: -15px !important;
}
