@import "shared/assets/styles/base/colors.scss";

.container {
  width: 38.55vw;
}

.backIcon {
  fill: none !important;
  stroke: $blue-lazure;
}
