@import "src/shared/assets/styles/main.scss";

.background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9000;
  top: 0;
  left: 0;
  height: 100%;
  width: 105%;
  background-color: rgba(0, 0, 0, 0.2);
}

.errorWindow {
  width: 500px;
  min-height: 250px;
  border-radius: 8px;
  padding: 20px 20px 30px 20px;
  background-color: white;
  position: relative;
  bottom: 40px;
}

.errorHeader {
  @include title;
}

.closeIcon {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 32px;
  height: 32px;
  transition: all 0.4s ease;
  cursor: pointer;
  &:hover {
    & svg path {
      fill: $bw-gray7;
    }
    transition: all 0.4s ease;
  }
}

.errorContainer {
  @include mainText;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0 40px 0;

  & > :first-child {
    width: 60px;
    height: 60px;
  }

  & svg {
    width: 60px;
    height: 60px;
  }
}

.btnGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.btnToBack {
  margin: 5px 10px;
  width: 44%;
}

.btnAccept {
  width: 100%;
}
