@import "shared/assets/styles/base/colors.scss";

.react-datepicker__day {
  &:hover {
    background-color: $blue-light-blue;
    border-radius: 4px;
  }

  & > div {
    pointer-events: none;
  }

  &--in-range {
    background-color: $bw-gray7;
    color: $bw-white;
    border-radius: 0;

    &:hover {
      background-color: $bw-gray7;
      border-radius: 0;
    }
  }

  &--selected {
    background-color: $bw-gray7;
    border-radius: 0;
    &:hover {
      background-color: $bw-gray7;
      border-radius: 0;
    }
  }

  &--in-selecting-range:not(&--selected) {
    background-color: $blue-light-blue;
    border-radius: 0;
  }

  &--outside-month {
    visibility: hidden;
  }
}
