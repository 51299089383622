@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  background-color: $bw-gray3;
  z-index: 1000;
  user-select: none;
  padding: 4px 0 0 8px;
}

.mainHeaderBtn {
  cursor: pointer;
  border: none;
  border-radius: 6px;
  transition: all 0.2s ease;
  width: 40px;
  height: 40px;
  background-color: $bw-gray3;
  padding: 3px 0;

  & svg {
    width: 40px;

    & rect {
      fill: $bw-black;
    }
  }

  &:hover {
    transition: all 0.2s ease;
    background-color: $bw-white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);

    & svg rect {
      fill: $blue-lazure;
    }
  }

  &_selected {
    @extend .mainHeaderBtn;
    background-color: $bw-gray7 !important;

    & svg rect {
      fill: $bw-white;
    }

    &:hover {
      & svg rect {
        fill: $bw-white;
      }
    }
  }
}

.headerBtn {
  @include clippedText;
  cursor: pointer;
  border: none;
  border-radius: 6px 6px 0 0;
  padding: 0 3px 0 12px;
  display: flex;
  align-items: center;
  max-width: max-content;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    background-color: $bw-gray4;
    height: 40px;
    width: 1px;
    z-index: 3;
    right: 0;
    top: 5px;
  }

  & a {
    @include mainText;
    @include clippedText;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    height: 46px;

    &:hover {
      color: black;
      overflow: initial;
    }
  }

  &:hover {
    background-color: $bw-gray2;
    overflow: initial;

    &::after {
      display: none;
    }

    .iconClose {
      background: none;
    }
  }

  &_selected {
    @extend .headerBtn;
    background-color: $bw-white;
    overflow: initial;

    &::after {
      display: none;
    }

    &:hover {
      background-color: $bw-white;

      &::after {
        display: none;
      }

      .iconClose {
        background: none;
      }
    }

    .iconClose {
      background: none;

      & svg path {
        fill: $bw-black;
      }
    }
  }

  &_withoutAfter {
    &::after {
      display: none;
    }

    &:hover {
      &::after {
        display: none;
      }
    }
  }

  &_module {
    margin-left: 8px;
    width: max-content;
    border: none !important;
    overflow: visible;
    gap: 4px;

    & a {
      gap: 4px;
    }
  }

  &_main {
    @include tooltip("bottom-start", $blue-lazure, $bw-white, $blue-lazure);
  }
}

.iconClose {
  position: relative;
  width: 30px;
  background: linear-gradient
    (90deg, rgba(231, 231, 231, 0) -2.08%, $bw-gray3 23.83%);

  & svg {
    margin-top: -2px;
  }

  &:hover {
    background: none;

    & svg path {
      fill: $bw-gray7;
    }
  }
}

.headerTabs {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.closeAllTabsBtn {
  margin-left: 6px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  & button {
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;
    background: none;
    margin-top: -2px;

    @include tooltip("bottom-start", $accent-coral, $bw-white, $accent-coral);

    &:hover {
      & svg rect {
        fill: $accent-coral;
      }
    }
  }

  & svg {
    transform: rotate(135deg);
    background-color: $bw-gray2;
    border-radius: 50%;
    width: 20px;
    height: 20px;

    & rect {
      fill: $bw-gray5;
    }
  }
}

.topMenu {
  margin-left: auto;
  padding-left: 8px;
}
