@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

.badge {
  @include subtitle2;
  color: $bw-white;
  white-space: nowrap;
  width: max-content;
  padding: 4px 6px;
  border-radius: 8px;

  @each $name, $color in $colors-list {
    &_#{$name} {
      @if ($name) {
        background-color: company-color($name);
      } @else {
        background-color: company-color("primary");
      }
    }
  }
}

.badgeText {
  @each $name, $color in $colors-list {
    &_#{$name} {
      @if ($name) {
        color: $color;
      } @else {
        color: $bw-white;
      }
    }
  }
}

.badgeWithIcon {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.mediumTextSize {
  font-size: 16px;
}

.noPadding {
  padding: 0;
}
