@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/mixins/fontsMixins.scss";

.topBlock {
  display: flex;
  margin-bottom: 40px;
}

.inputGroup {
  min-width: 30vw;
  width: fit-content;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.imgBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 26px;
  margin-left: 90px;
}

.imgSubtitle {
  @include inputPrompt;
  color: $bw-gray6;
  max-width: 240px;
}

.img {
  max-width: 240px;
  height: 160px;
}
