@import "~/src/shared/assets/styles/main.scss";

.menuWrapper {
  width: inherit;
}

.menuHeader,
.menuHeaderIcon {
  @include caption;
  display: flex;
  flex-direction: row;
  align-items: center;

  & p {
    @include zero;
  }
}

.menuHeader {
  padding: 0px 0px 22px 21px;
}

.menuHeaderIcon {
  column-gap: 10px;
  padding: 0px 0px 12px 8px;
}

.iconSort {
  cursor: pointer;
}

.addBtn {
  @include mainText;
  color: $blue-lazure;
}

.placeholder {
  position: absolute;
  background: transparent;
  border: 1px dashed $blue-azure;
  border-right: none;
  z-index: 1;
  border-top-left-radius: 15.5px;
  border-bottom-left-radius: 14.5px;
  height: 30px;
  cursor: move;

  @include dashedArrow($blue-azure);
}

.menu,
.grayMenu {
  position: relative;
}

.item,
.notHoverItem,
.grayItem,
.movItem,
.addBtn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 14px;
  height: 30px;
  border-top-left-radius: 15.5px;
  border-bottom-left-radius: 14.5px;
  margin: 0 0 12px 0;
}

.item,
.addBtn {
  &:hover {
    position: relative;
    background: $blue-light-blue;
    z-index: 1;

    @include arrow($blue-light-blue);
  }
}

.grayItem {
  &:hover {
    position: relative;
    background: $bw-gray2;
    z-index: 1;

    @include arrow($bw-gray2);
  }
}

.movItem {
  &:hover {
    position: relative;
    z-index: 1;
    box-shadow: 0px 4px 10px rgba(121, 73, 73, 0.1);
    background: url("/shared/assets/images/mainIcons/iconDragSelected.svg") no-repeat
        220px,
      $bw-gray1;

    @include arrow($bw-gray1);
  }
  &:active {
    cursor: move;
    background: url("/shared/assets/images/mainIcons/iconDragSelected.svg") no-repeat
        220px,
      $blue-light-blue;
    position: relative;
    z-index: 1;

    @include arrow($blue-light-blue);
  }
}

.selected {
  position: relative;
  background: $blue-light-blue;
  z-index: 1;

  @include arrow($blue-light-blue);
}

.graySelected {
  position: relative;
  background: $bw-gray2;
  z-index: 1;

  @include arrow($bw-gray2);
}

.number,
.movNumber {
  @include zero;
  @include listMainText;
  color: $bw-gray5;
}

.movNumber {
  color: $blue-lazure;
}

.title,
.movingTitle {
  @include zero;
  @include mainText;
  max-width: 230px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 7px;
}