@import "src/shared/assets/styles/main.scss";

.link {
  text-decoration: none;

  & p {
    cursor: pointer;
    color: $blue-lazure !important;
    width: max-content;
    max-width: 300px;
  }
}
