@import "src/shared/assets/styles/main.scss";

.table {
  min-width: 600px;

  @include listMainText;

  & p {
    @include zero;
  }

  & td {
    padding: 4px;
  }
}

.document {
  @each $name, $color in $colors-list {
    &_#{$name} {
      @if ($name == "danger") {
        color: $accent-red;
      } @else if ($name == "warning") {
        color: $accent-orange;
      } @else {
        color: $color;
      }
    }
  }
}

.status {
  display: flex;
  align-items: center;
  gap: 5px;
  width: max-content;
}
