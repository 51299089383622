@import "src/shared/assets/styles/main.scss";

.header {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 13px 16px;
  gap: 44px;
  & > div:first-child {
    @include subtitle;
  }
}

.statusTitle {
  @include mainText;
  display: flex;
  align-items: center;
  gap: 6px;
}

.body {
  margin-top: 31px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
}

.infoBlock {
  width: 50%;
  border-top: 1px solid $bw-gray2;
}

.multipleRow {
  display: flex;
  border-bottom: 1px solid $bw-gray2;
  column-gap: 21%;
  & > div:not(:first-child) {
    border-left: 1px solid $bw-gray2;
  }
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 12px 0;
  border-bottom: 1px solid $bw-gray2;
  padding-left: 8px;
  min-height: 65px;
}

.fieldTitle {
  @include zero;
  @include caption;
  height: 14px;
  white-space: nowrap;
}

.fieldValue {
  @include zero;
  @include mainText;
  word-break: break-word;
  color: $bw-black;
  padding-bottom: 2.5px;
}

.buidlingLink {
  text-decoration: none;
  & > p {
    color: $blue-lazure !important;
  }
}

.statusField {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $bw-gray2;
}
