@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/base/sizes.scss";

%sticky {
  position: -webkit-sticky;
  position: sticky;
  margin-top: -30px;
  padding-top: 30px;
  z-index: 2;
  background-color: $bw-white;
}

.menuOpened {
  width: calc(#{$maxWidth} - #{$openedAsideMenu} - 60px);
}

.menuClosed {
  width: calc(#{$maxWidth} - #{$closedAsideMenu} - 60px);
}
