@import "~/src/shared/assets/styles/main.scss";

.checkbox {
  position: relative;
  display: grid;
  place-content: center;
  margin: 0;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: $bw-white;
  width: 18px;
  height: 18px;
  border: 1px solid $bw-gray4;
  border-radius: 2px;
  transform-style: preserve-3d;

  &:not(:checked):active {
    background: $bw-gray1;

    &::before {
      @include beforeElemStyle;
      background: rgba(197, 198, 198, 0.15);
    }
  }

  &:checked {
    border: 1px solid $blue-light-blue;
    border-radius: 2px;
    background: $blue-light-blue;
    transition: 0.4s all ease-in-out;

    &::before {
      content: url("./icons/checked.svg");
      position: absolute;
      left: 2px;
      bottom: -1px;
    }

    &:active {
      &::after {
        content: url("./icons/checked.svg");
        position: absolute;
        left: 2px;
        bottom: -1px;
      }

      &::before {
        @include beforeElemStyle;
        background: rgba(206, 233, 255, 0.35);
      }
    }
  }

  &:not(:checked):disabled {
    border: 1px solid $bw-gray3;
    background: $bw-gray2;
  }

  &:checked:disabled {
    background: $bw-gray2;
    border: 1px solid $bw-gray2;

    &::before {
      content: url("./icons/unchecked.svg");
      position: absolute;
      left: 2px;
      bottom: -1px;
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }
}
