@import "/src/shared/assets/styles/base/colors.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";
@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "../staffTablePlaceholders.scss";

.num {
  @extend %firstColWidth;
}

.main {
  @extend %secondColWidth;
}

.firstCompanyRow {
  @extend %numAndStaffCols;
  @extend %totalCols;

  // граница справа для последней ячейки каждой почасовки
  & > :nth-child(4n + 2) {
    border-right: 1px solid $bw-gray3 !important;
  }

  &:hover > :nth-child(n + 2) {
    background-color: $blue-white-blue;
    cursor: pointer;
  }

  & > :nth-child(-n + 2) {
    border-right: 1px solid $bw-gray3;
  }
}

.anotherCompanyRow {
  height: 50px;
  // колонка staff
  & > :first-child {
    @extend %staffStickyCol;
  }
  // колонка total
  @extend %totalCols;

  // граница справа для последней ячейки каждой почасовки
  & > :nth-child(3n + 1) {
    border-right: 1px solid $bw-gray3 !important;
  }

  & td {
    position: relative;
    vertical-align: middle !important;
    padding: 8px 4px !important;
  }

  &:hover {
    cursor: pointer;
    & > td {
      background-color: $blue-white-blue;
    }
  }
}

// четные строки
.oddRow {
  background-color: $bw-gray1;
  & td {
    background-color: $bw-gray1;
  }
  position: relative;
  // вторая ячейка строки - ФИО сотрудника
  & > :nth-child(2),
    // последние три ячейки строки - Итого
    & > :nth-last-child(-n + 3) {
    background-color: $accent-beige;
  }
}

.oddRowWithoutNum {
  background-color: $bw-gray1;
  & td {
    background-color: $bw-gray1;
  }
  & > :first-child {
    background-color: $accent-beige;
  }

  & > :nth-child(2) {
    background-color: $bw-gray1;
  }

  & > :nth-last-child(-n + 2) {
    background-color: $accent-beige;
  }
}
// нечетные строки
.evenRow {
  & td {
    background-color: $bw-white;
  }
  // вторая ячейка строки - ФИО сотрудника
  & > :nth-child(2),
    // последние три ячейки строки - Итого
    & > :nth-last-child(-n + 3) {
    background-color: $accent-yellow;
  }
}

.evenRowWithoutNum {
  & td {
    background-color: $bw-white;
  }
  & > :first-child {
    background-color: $accent-yellow;
  }

  & > :nth-last-child(-n + 2) {
    background-color: $accent-yellow;
  }
}

.tdShadowRight {
  @extend %shadowRight;
}

// ячейка с данными сотрудника
.staff {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.staffFioContainer {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.staffTn {
  color: $bw-gray5;
  width: max-content !important;
}

.link {
  text-decoration: none !important;
}

// стили строки при наведении
.highlightedCompanyStaff {
  background-color: $blue-white-blue;
  cursor: pointer;

  & > :nth-child(n + 1) {
    background-color: $blue-white-blue !important;
    cursor: pointer;
  }
}
