@import "src/shared/assets/styles/main.scss";

.table {
  margin: 0;
  padding: 0;
  margin-top: 20px;
}

.thead {
  background-color: white !important;
  border-top: none;
  border-bottom: 1px solid $bw-gray2;
}

.title {
  @include caption;
}

.tr {
  border-top: none;
  border-bottom: 1px solid $bw-gray2;
  & > :first-child {
    width: 6vw;
  }
}

.td {
  @include listMainText;
  color: $bw-black;
  word-wrap: break-word;
  padding: 8px !important;
}
