@import "~/src/shared/assets/styles/main.scss";

.container {
  position: absolute;
  z-index: 10;
  top: 55px;
  border-radius: 8px;
  background-color: $bw-white;
  display: flex;
  padding: 16px 16px 20px 16px;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.16);
}

.btnBlock {
  display: flex;
  justify-content: flex-end;
}
