@import "src/shared/assets/styles/main.scss";

%cell-padding {
  padding: 6px 2px 6px 0.5rem !important;
}

.shadow {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08) !important;
}

.fio {
  @include caption;
  @extend %cell-padding;
  width: 310px !important;
}

.tn {
  @include caption;
  @extend %cell-padding;
}

.day {
  @include listMainText;
  position: relative;
  border: 1px solid $bw-gray2;
  text-align: center;
  cursor: pointer;
  user-select: none;
  min-width: 40px !important;

  @-moz-document url-prefix() {
    border: 2px solid $bw-gray2;
  }

  & > :first-child {
    position: absolute;
    bottom: 0;
    z-index: 4;
    display: flex;
    width: 100%;
    height: 100%;

    & > p {
      @include zero;
      margin: auto;
    }
  }

  &:hover,
  &_selected {
    & > :first-child {
      will-change: bottom;
      bottom: 3px;
      transition: all 0.1s;
      border: 1.1px solid $blue-light-blue;
      color: $blue-lazure !important;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
    }
  }

  &__value {
    @each $name, $color in $colors_list {
      &_#{$name} {
        @if ($name == "grey") {
          background-color: color-level($name, 1.5);
        } @else {
          background-color: color-level($name, 2.5);
        }
      }
    }

    &_text {
      @each $name, $color in $colors_list {
        &_#{$name} {
          color: $color;
        }
      }
    }
  }
}

.list {
  position: absolute;
  z-index: 4;
  right: 0;
  top: 62px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
    0px 2px 8px rgba(0, 140, 255, 0.1);
  border-radius: 6px;
  background: white;
  border: 1px solid $blue-azure;
  padding: 12px 16px;
  width: max-content;
  height: max-content;

  & > p {
    @include zero;
    @include listMainText;

    border-radius: 16px;
    padding: 8px 12px;

    &:hover {
      background: $blue-white-blue;
    }
  }

  &_small {
    top: auto;
    bottom: -59px;
  }
}

.hours {
  @include listMainText;
  text-align: center;
  width: 40px;
}
