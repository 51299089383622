@import "src/shared/assets/styles/base/colors.scss";
@import "src/shared/assets/styles/base/sizes.scss";

%grayBorder {
  border-top: 1px solid $bw-gray2;
  border-bottom: 1px solid $bw-gray2;
}

.table {
  width: calc(100% + 60px);
  margin-left: -30px;
  table-layout: auto;
  border-top: none !important;

  & tbody tr,
  & thead tr {
    & > :first-child {
      padding-left: 30px;
    }
    & > :last-child {
      padding-right: 30px;
    }
  }

  & tbody {
    & > tr {
      @extend %grayBorder;
      vertical-align: middle;
      & > td {
        vertical-align: middle;
        padding: 8px 0.5rem 8px 0.5rem;
      }
    }
    & > tr:hover {
      background: $blue-light-blue;
      border-top: 1px solid $blue-light-blue;
      border-bottom: 1px solid $blue-light-blue;
    }
    & td {
      width: max-content;
    }
    & p {
      margin: 0;
    }
  }

  &_minWidth {
    max-width: calc(#{$maxWidth} - #{$openedAsideMenu}) !important;
  }

  &_maxWidth {
    max-width: calc(#{$maxWidth} - #{$closedAsideMenu}) !important;
  }
}

.noDisplay {
  display: none;
}

.greyRow {
  background-color: $bw-gray1;
}

.blueRow {
  background-color: $blue-white-blue;
}

.link {
  text-decoration: none;

  & p {
    cursor: pointer;
    color: $blue-lazure !important;
    width: max-content;
    max-width: 300px;
  }
}

.textarea {
  & textarea {
    padding: 6px 4px 6px 4px !important;
    min-height: 32px;
    &:hover {
      cursor: text;
      background-color: $bw-white;
      border-color: $blue-azure;
    }

    &:focus {
      box-shadow: none;
      border: 1px solid $blue-lazure !important;
    }
  }
}
