@import "src/shared/assets/styles/base/colors.scss";

.row {
  border-bottom: 1px solid $bw-gray3 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.borderLeft {
  border-left: 1px solid $bw-gray3;
}
