@import "src/shared/assets/styles/main.scss";

.row {
  @include cardRow;
}

.field {
  @include cardField;
}

.titleWithBtn {
  display: flex;
  align-items: center;
  gap: 20px;
}

.chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.openedBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: $blue-lazure;

  & svg {
    outline: none;
    transition: all 0.2s ease;
    transform: rotate(0deg);
    path {
      fill: $blue-lazure;
      stroke: $blue-lazure;
    }
  }

  &:hover {
    font-weight: 500;
  }

  &_open svg {
    transition: all 0.4s ease;
    transform: rotate(180deg);
  }
}
