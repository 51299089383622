@import "shared/assets/styles/main.scss";

.mainInfoContainer {
  position: sticky !important;
  top: -8px;
  z-index: 102;
}

.mainInfo {
  width: 100%;
  background-color: $bw-white;
  transition: height 0.4s ease;

  & > :first-child {
    border-bottom: 1px solid $bw-gray3;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    padding: 0px 20px 12px 20px;
  }

  &_mini {
    @extend .mainInfo;
    height: 91px;
    transition: height 0.4s ease;
  }

  &__info {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    gap: 40px;

    &__fio {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      gap: 12px;
      background-color: $bw-white;
      position: inherit;
      z-index: 3;
      padding-top: 20px;
    }
  }
}

.photo {
  width: 220px;
  height: 220px;
  transition: all 0.4s ease;
  margin-top: 20px;

  & img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
  }

  &_mini {
    width: 53px;
    height: 53px;
    transition: all 0.4s ease;
  }

  &_avatar {
    background-color: $bw-gray2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 8px;

    & img {
      width: auto;
    }
  }
}

.TNBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 53px;
  border: 1px solid $bw-gray4;
  padding: 4px 12px;
  border-radius: 4px;
  gap: 10px;
  width: max-content;

  &__title {
    @include title;
    font-weight: 400;
    @include zero;
    color: $bw-gray6;
    line-height: 23.6px;
  }

  &__value {
    @include title;
    @include zero;
    color: $bw-gray7;
  }
}

.fioBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__fio,
  &__position {
    @include zero;
  }

  &__fio {
    @include title;
  }

  &__position {
    @include mainText;
  }
}

.widgetBlock {
  position: relative;

  & div[data-loading="true"] {
    position: absolute;
    display: block;
    left: 0;

    & > div {
      margin-top: 50px;
    }
  }

  &__classicMetrics {
    margin-top: 20px;

    position: relative;

    & div[data-loading="true"] {
      position: absolute;
      display: block;
      padding-left: 50px;

      & > div {
        margin-top: 50px;
      }
    }
  }
}

.buildingMetrics {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__oneStatus {
    padding: 12px 20px;
    width: max-content;
    background: $blue-white-blue;
    border-radius: 12px;
  }
}

.status {
  background-color: $bw-white;
  @each $name, $color in $colors_list {
    &_#{$name} {
      padding: 12px 20px;
      width: max-content;
      background-color: rgba($color, 0.1);
      border-radius: 12px;
      display: flex;
    }
  }

  &__leftCol {
    padding-right: 12px;
    border-right: 1px solid $bw-gray4;
  }

  &__heading {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }


  &__building {
    @include zero;
    @include subtitle;
    text-decoration: none;
    cursor: pointer;
    color: $blue-lazure;

    &:hover {
      color: $blue-dark-blue;
      text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    }
  }

  &__rightCol {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 2px 0 2px 12px;

    &__title {
      @include caption;
      color: $bw-gray6;
    }

    &__value {
      @include subtitle;
      color: $bw-black;
      font-size: 20px;
    }
  }
}

.classicMetrics {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  width: 365px;
}

.block {
  display: flex;
  justify-content: center;
  padding: 12px;

  border: none;
  border-radius: 20px;
  background-color: $bw-gray1;

  &_mini {
    padding: 12px 20px;
    width: 100%;

    border: none;
    border-radius: 20px;
    background-color: $bw-gray1;
  }

  &_green {
    background-color: $accent-light-green;
  }

  &_red {
    background-color: $accent-pastel-red;
    .block__value {
      color: $accent-red !important;
    }
  }

  &_link {
    cursor: pointer;
    &:hover {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12),
        0px 4px 16px rgba(243, 243, 243, 0);
    }
  }

  &__divider {
    border-left: 1px solid $bw-gray4;
  }

  &__data {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
  }

  &__title {
    @include caption;
    color: $bw-gray6;
  }

  &__value {
    @include subtitle;
    color: $bw-black;
    font-size: 20px;
  }
}
