@mixin mainTextFont {
  font-family: "Noto Sans";
  font-style: normal;
}

@mixin title {
  @include mainTextFont;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: $bw-black;
}

@mixin subtitle {
  @include mainTextFont;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: $bw-black;
}

@mixin subtitle2 {
  @include mainTextFont;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: $bw-black;
}

@mixin mainText($color: $bw-black) {
  @include mainTextFont;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.8px;
  color: $color;
}

@mixin inputPrompt {
  @include mainTextFont;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: $bw-gray5;
}

@mixin listMainText {
  @include mainTextFont;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.8px;
  color: $bw-black;
}

@mixin caption {
  @include mainTextFont;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: $bw-gray5;
}
