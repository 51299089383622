@import "/src/shared/assets/styles/mixins/fontsMixins.scss";
@import "/src/shared/assets/styles/mixins/mainMixins.scss";
@import "/src/shared/assets/styles/base/colors.scss";

.container-default {
  @include mainText;
  @include clippedText;
  width: 100%;
  border: 1px solid $bw-gray3;
  border-radius: 6px;
  height: 72px;
  position: relative;
  background-color: $bw-white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 27px 16px 19px;
}

.container-default-withData {
  padding: 10px 10px 18px 15px;
}

.required-default {
  padding-left: 15px;
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 6px;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: $accent-light-orange;
  }
}

.title-default {
  @include mainText;
  @include clippedText;
  position: relative;
  color: $bw-gray6;
  width: -webkit-fill-available;
}

.title-default-withData {
  opacity: 1;
  font-size: 13.5px;

  &::before {
    left: 0px;
    top: 5px;
  }
}

.value-default {
  @include clippedText;
}

.disabled {
  background-color: $bw-gray2;
  border-color: $bw-gray2;
  cursor: not-allowed;
}
