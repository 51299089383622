@import "~/src/shared/assets/styles/main.scss";

%activeIconCalendar {
  & svg > :first-child,
  & svg > :last-child {
    stroke: $blue-lazure !important;
  }

  & svg > :last-child,
  & rect {
    fill: $blue-lazure !important;
  }
}

%required {
  content: "";
  position: absolute;
  left: 15px;
  top: 32px;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: $accent-light-orange;
}

.popper {
  width: max-content;
  padding: 14px 0;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 17px 0 14px;
  height: 72px;
  border: 1px solid $bw-gray3;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: $blue-white-blue;
    border-color: $blue-azure;
  }
}

.requiredBlock {
  padding-left: 27px;
}

.disabled {
  background-color: $bw-gray2;
  border: none;
  cursor: not-allowed;
  & input {
    background-color: $bw-gray2;
    cursor: not-allowed;
  }

  &:hover {
    background-color: $bw-gray2;
    border: none;
    & input {
      background-color: $bw-gray2;
    }
  }
}

.active {
  border-color: $blue-lazure;
}

.valueContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.title {
  @include zero;
  @include inputPrompt;
  color: $bw-gray5;
}

.titleWithValue {
  @include caption;
  margin-bottom: 6px;
  color: $bw-gray5;
}

.requiredWithoutValue {
  &::before {
    @extend %required;
  }
}

.required {
  &::before {
    @extend %required;
    top: 17px;
  }
}

.error {
  position: absolute;
  inset: calc(100% + 5px) 0 0 8px;
  @include caption;
  color: $accent-coral;
}

.iconBtns {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.iconCalendar_active {
  & svg > :first-child,
  & svg > :last-child {
    stroke: $blue-lazure !important;
  }

  & svg > :last-child,
  & rect {
    fill: $blue-lazure !important;
  }
}

.invalid {
  border-color: $accent-coral !important;
  margin-bottom: 18px;

  & .required::before {
    content: "";
    background-color: $accent-coral;
  }
}

.calendarWrapper {
  position: relative;
  top: 30px;
  left: 60px;
}
