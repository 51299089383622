@import "/src/shared/assets/styles/base/colors.scss";

.container {
  width: calc(100% + 30px);
}

.emptyAlert {
  margin: 20px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  width: 96%;
}

.comments {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  padding: 0.5rem 1rem;
  background-color: $bw-gray2;
  border: 1px solid $bw-gray2;
  color: $bw-gray6;
  cursor: pointer;

  &:hover {
    border: 1px solid $bw-gray2;
    border-bottom: 1px solid $bw-gray4;
  }
}

.commentsActive {
  background-color: $bw-white;
  color: $blue-lazure;
  border: 1px solid $bw-gray4;
  border-bottom: none;

  &:hover {
    border-bottom: none;
  }
}
