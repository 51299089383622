@import "src/shared/assets/styles/base/colors.scss";

.checkBlock {
  padding: 4px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $bw-white;
  border: 1px solid $bw-gray3;
  & > :first-child {
    margin-top: -2px;
  }
  &_active {
    border: 1px solid $blue-lazure;
  }
}
