@import "shared/assets/styles/main.scss";

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 12px;
  @each $name, $color in $colors_list {
    &_#{$name} {
      background: linear-gradient(
        123deg,
        color-level($name, 2.3) 0%,
        color-level($name, 0.5) 100%
      );
    }
  }
}

.icon {
  width: 84px;
  height: 84px;
}

.oneBlock {
  padding: 18.5px 17px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  &:not(:last-child) {
    border-right: 1px solid $bw-white;
  }
  & p {
    @include zero;
    &:first-child {
      @include caption;
      color: $bw-black;
      margin-bottom: 4px;
    }
    &:last-child {
      @include title;
      line-height: 27px;
    }
  }
}
