@import "/src/shared/assets/styles/base/colors.scss";

.icon {
  width: 24px;
  cursor: pointer;
}

.iconOpenedSelect {
  transition: all 0.4s ease;
  transform: rotate(180deg);
  width: 24px;

  & > path {
    fill: $blue-lazure !important;
    stroke: $blue-lazure !important;
  }
}

.iconCLosedSelect {
  outline: none;
  transition: all 0.2s ease;
  transform: rotate(0deg);
  width: 24px;

  & > path {
    fill: $bw-gray5;
    stroke: $bw-gray5;
  }
}
