@import "src/shared/assets/styles/main.scss";

.wrapper {
  width: 50%;
}

.field {
  @include mainText;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  padding: 12px 0;
  border-bottom: 1px solid $bw-gray2;
  padding-left: 4px;

  & p {
    @include zero;
  }

  &__title {
    @include zero;
    @include caption;
    height: 14px;
    white-space: nowrap;
  }

  &__values {
    @include zero;
    @include mainText;
    word-break: break-word;
    color: $bw-black;
    padding-bottom: 2.5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;

    & > img,
    & > svg {
      margin-right: -8px;
    }

    &__color {
      color: $bw-gray6;

      @each $name, $color in $colors_list {
        &_#{$name} {
          color: $color;
        }
      }
    }

    &__link {
      text-decoration: none;
      cursor: pointer;
      color: $blue-lazure !important;
      margin: 0;
    }
  }
}

.cancelled > path {
  fill: $accent-orange;
}
