@import "~/src/shared/assets/styles/base/colors.scss";
@import "~/src/shared/assets/styles/mixins/mainMixins.scss";
@import "~/src/shared/assets/styles/mixins/fontsMixins.scss";

.editButton {
  cursor: pointer;
  height: 40px;
  position: fixed;
  top: 62px;
  right: 30px;
  // Вернуть значение при разработке
  // right: 125px;
  z-index: 6;
  display: flex;
  align-items: center;
  gap: 5px;

  width: max-content;
  padding: 7px 16px;
  margin: 0;
  border-radius: 12px;
  color: $bw-gray7;
  background: $bw-gray2;

  &>p {
    @include zero;
    @include mainTextFont;
  }

  &:hover {
    background: $bw-white;
    box-shadow: 0px 4px 10px rgba(121, 73, 73, 0.1);
    transition: all 0.4s ease;

  }

  &:focus,
  &:active {
    color: $bw-gray7;
    background: $bw-gray2;
    box-shadow: 0px 4px 10px rgba(121, 73, 73, 0.1);
    transition: all 0.4s ease;
  }

  @media screen and (max-width: 1200px) {
    right: 95px;
  }
}

.iconClose {
  transform: rotate(45deg);
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
}