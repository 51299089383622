@import "shared/assets/styles/mixins/fontsMixins.scss";
@import "shared/assets/styles/base/colors.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btnPrev,
.btnNext {
  width: fit-content;
  height: fit-content;
  border: none;
  background: none;
}

.btnNext img {
  transform: rotate(180deg);
}

.hiddenBtn {
  visibility: hidden;
}

.header {
  @include subtitle2;
}
