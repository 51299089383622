@import "src/shared/assets/styles/main.scss";

.loaderContainer {
  position: relative;
  min-height: 100px;
  & div[data-loading="true"] {
    position: absolute;
    display: block;
    left: 0;
    z-index: 1;
  }
}

.cards {
  display: flex;
  gap: 12px;
  align-items: center;
  margin-bottom: 40px;
}
