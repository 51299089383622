@import "~/src/shared/assets/styles/main.scss";

.rightSide {
  border-radius: 8px;
  background-color: $bw-white;
  height: 115px;
  width: max-content;
  padding: 16px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.title {
  @include subtitle2;
  margin-top: -3px;
}

.text {
  @include caption;
  color: $bw-gray7;
  position: absolute;
  top: 35px;
  & > b {
    font-size: 16px;
  }
}
