@import "~/src/shared/assets/styles/main.scss";

.wrapper {
  margin-left: 5px;
  display: flex;
  gap: 5px;
  height: 100%;
}

.button {
  height: 100%;
  padding: 18px 3px;
}
