@import "/src/shared/assets/styles/base/colors.scss";
@import "../../staffTablePlaceholders.scss";

.shadowLeft {
  @extend %shadowLeft;

  &::after {
    top: -6px;
    height: 130%;
  }
}

.rowspan {
  border-right: 1px solid $bw-gray3;
  border-left: 1px solid $bw-gray3;
}
