@import "src/shared/assets/styles/main.scss";

%text {
  @include subtitle;
  @include zero;
}

.headerBlock {
  display: flex;
  align-items: center;
  column-gap: 30px;
  margin-top: -15px;
  padding-top: 12px;
  padding-bottom: 21px;
  border-bottom: 1px solid $bw-gray3;
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: $bw-white;
  margin-bottom: 20px;
  @media screen and (max-width: 1100px) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
  }
}

.staffInfo {
  display: flex;
  column-gap: 12px;

  & img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
  }

  &_text {
    display: flex;
    gap: 4px;
    flex-direction: column;
    justify-content: space-around;
    & > p {
      @extend %text;
    }
  }
}

.TNBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  height: fit-content;
  width: 100px;
  border: 1px solid $bw-gray3;
  border-radius: 4px;
  padding: 4px 8px;
}

.TNBlockTitle {
  @extend %text;
  color: $bw-gray6;
}

.TNBlockValue {
  @extend %text;
  color: $bw-gray7;
}

.premiumLevelInfo {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.oneBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 12px;
  padding: 12px 20px;
  background-color: $bw-gray2;
  height: 68px;
  & p {
    @include zero;
    &:first-child {
      @include caption;
      color: $bw-gray6;
      margin-bottom: 4px;
    }
    &:last-child {
      @include subtitle;
      line-height: 22px;
    }
  }
}

.rowWithIcon {
  position: relative;
  left: 5px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  & > img {
    width: 24px;
    height: 24px;
  }
}
