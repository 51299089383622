@import "shared/assets/styles/main.scss";

.table {
  @include mainText;
  width: 70%;
  border-color: $bw-gray3;

  & > thead {
    position: sticky;
    top: -2px;
    z-index: 1;

    & tr {
      & th {
        padding: 8px 4px 5px 4px;
        @include caption;
        background-color: $bw-white !important;
        white-space: nowrap;
        color: $bw-gray6;
      }

      & div {
        display: flex;
        align-items: center;
        height: 20px;

        & p {
          @include zero;
        }
      }
    }
  }

  & > tbody {
    @include mainText;

    & td {
      padding-right: 20px;
    }

    & tr > :last-child {
      padding-right: 4px;
    }
  }
}
