@import "~/src/shared/assets/styles/main.scss";

.pageTitle {
  margin-top: -5px;
  padding-bottom: 30px;
  border-bottom: 1px solid $bw-gray2;
}

.stickyTitle {
  position: sticky;
  z-index: 3;
  top: 0;
  background-color: $bw-white;
  margin-top: -25px;
  padding-top: 30px;
}

.title {
  @include subtitle;
  @include zero;
}

.rightCol {
  text-align: right;
  column-gap: 6px;
}

.leftCol {
  text-align: left;
  column-gap: 6px;
}
