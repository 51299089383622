@import "src/shared/assets/styles/main.scss";

.filterButtons {
  position: fixed;
  z-index: 5;
  top: 41px;
  left: calc(400px + #{$openedAsideMenu});

  display: flex;
  align-items: center;
  gap: 40px;

  &_withClosedMenu {
    left: calc(400px + #{$closedAsideMenu});
  }
}

.tabs {
  margin: 20px 0;
}
