@import "src/shared/assets/styles/base/colors.scss";

.errorBtn {
  padding: 8px 13px;
  background-color: $bw-white;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06),
      0px 2px 8px rgba(0, 140, 255, 0.1);
  }

  & > :first-child {
    margin-top: -1px;
  }
}
