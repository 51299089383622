@import "src/shared/assets/styles/mixins/fontsMixins.scss";
@import "src/shared/assets/styles/mixins/mainMixins.scss";
@import "src/shared/assets/styles/base/colors.scss";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  margin-bottom: 30px;
  & > :first-child {
    @include zero;
    @include subtitle;
    display: flex;
    gap: 20px;
    align-items: center;
  }
}
