@import "~/src/shared/assets/styles/base/colors.scss";

.showBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  min-width: 220px;
  background-color: $bw-white;
  border-radius: 6px;
  border: none;
  border: 1px solid $bw-gray3;
  width: 100%;
  height: 31.99px !important;
  padding: 2px 8px 7px 4px !important;

  &:hover {
    border: 1px solid $blue-azure !important;
    cursor: pointer;

    & > svg path {
      fill: $blue-lazure;
      stroke: $blue-lazure;
    }
  }

  & > svg {
    width: 24px;
    cursor: pointer;

    transition: all 0.2s ease;
    transform: rotate(0deg);

    & path {
      fill: $bw-gray5;
      stroke: $bw-gray5;
    }
  }

  &_error {
    border-color: $accent-coral !important;
  }
}
