.aregister {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.main {
  display: grid;
  grid-template-columns: 800px 360px;
  column-gap: 60px;
}
