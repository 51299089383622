@import "~/src/shared/assets/styles/main.scss";

@mixin formulaColor($bgcolor, $color) {
  background-color: $bgcolor;
  color: $color;
  & > div > svg > g > :last-child {
    stroke: $color;
  }
  & > div > svg > g > :nth-child(4) {
    fill: $color;
  }
  & > div > svg {
    stroke: $color;
  }
}

.sizes {
  &__heading {
    @include mainText;
    margin-bottom: 20px;
    position: relative;
    margin-left: 10px;
    padding-left: 5px;
    &::before {
      content: "";
      position: absolute;
      left: -7px;
      top: 6px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background: $accent-light-orange;
    }
  }

  &__group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
    & > div {
      width: 22%;
    }
    & > div:first-child,
    & > div:nth-child(2),
    & > div:nth-child(3) {
      &::after {
        content: "";
        position: absolute;
        right: -17%;
        top: 50%;
        width: 17%;
        height: 1px;
        background: $bw-gray2;
        z-index: 0;
        transition: all 0.4s ease-in-out;
      }
    }
  }
}

.formula {
  @include mainText;
  border: 1px solid $bw-gray2;
  border-radius: 6px;
  background-color: $bw-gray1;
  color: $bw-gray5;
  padding: 25px 16px;
  display: flex;
  align-items: center;
  gap: 12px;

  &_yellow {
    @include formulaColor($accent-beige, $accent-orange);
  }

  &_green {
    @include formulaColor($accent-light-green, $accent-green);
  }

  &_red {
    @include formulaColor($accent-pastel-red, $accent-red);
  }

  &__heading {
    display: flex;
    align-items: center;
    gap: 12px;
  }
}

.field {
  margin-bottom: 20px;
}
