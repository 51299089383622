@import "src/shared/assets/styles/main.scss";

%displayFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  background-color: $bw-white;
  position: sticky;
  z-index: 1;
  top: -2px;

  & > :first-child {
    @extend %displayFlex;
    justify-content: flex-start;
    gap: 12px;
    & > :nth-child(2) {
      margin-top: -2px;
    }
  }

  @each $name, $color in $colors_list {
    &_#{$name} {
      background-color: color-level($name, 3);
    }
  }

  &_sticky {
    & > :first-child {
      transition-duration: 0.2s;
      padding: 10px 16px;
      border-radius: 0px;
      margin-left: -30px !important;
      width: calc(100% + 60px) !important;
    }
  }

  &_normal {
    & > :first-child {
      transition-duration: 0.1s;
      border-radius: 12px;
      padding: 13px 16px;
    }
  }

  &__title {
    @include subtitle;
  }

  &__link {
    @include mainText;
    text-decoration: none;
    cursor: pointer;
    color: $blue-lazure !important;
    margin: 0;
    padding-right: 30px;
  }

  &__status {
    @extend %displayFlex;
    @include mainText;
    gap: 8px;

    @each $name, $color in $colors_list {
      &_#{$name} {
        color: $color;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 6px;

    &_block {
      opacity: 1;
      transition: opacity 0.1s, visibility 0s;
      & div {
        transition-duration: 0.1s;
      }
    }

    &_none {
      visibility: hidden;
      opacity: 0;
      transition-duration: 0.1s;
    }

    &__title {
      @include listMainText;
      color: $bw-gray6;
    }

    &__value {
      @include mainText;
    }
  }
}
